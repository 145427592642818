export const SESSIONS_CHANGED = 'SESSIONS_CHANGED';
export const SESSION_CHANGED = 'SESSION_CHANGED';

export const SESSION_CREATE_REQUEST = 'SESSION_CREATE_REQUEST';
export const SESSION_CREATE_SUCCESS = 'SESSION_CREATE_SUCCESS';
export const SESSION_CREATE_ERROR = 'SESSION_CREATE_ERROR';

export const SESSION_SWITCH_REQUEST = 'SESSION_SWITCH_REQUEST';
export const SESSION_SWITCH_SUCCESS = 'SESSION_SWITCH_SUCCESS';
export const SESSION_SWITCH_ERROR = 'SESSION_SWITCH_ERROR';

export const SESSION_LEAVE_REQUEST = 'SESSION_LEAVE_REQUEST';
export const SESSION_LEAVE_SUCCESS = 'SESSION_LEAVE_SUCCESS';
export const SESSION_LEAVE_ERROR = 'SESSION_LEAVE_ERROR';

export const SESSION_JOIN_REQUEST = 'SESSION_JOIN_REQUEST';
export const SESSION_JOIN_SUCCESS = 'SESSION_JOIN_SUCCESS';
export const SESSION_JOIN_ERROR = 'SESSION_JOIN_ERROR';

export const SESSION_SETTINGS_UPDATE_REQUEST = 'SESSION_SETTINGS_UPDATE_REQUEST';
export const SESSION_SETTINGS_UPDATE_SUCCESS = 'SESSION_SETTINGS_UPDATE_SUCCESS';
export const SESSION_SETTINGS_UPDATE_ERROR = 'SESSION_SETTINGS_UPDATE_ERROR';
