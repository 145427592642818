import React from 'react';
import { Typography, List, ListItem, withTheme, Theme } from '@material-ui/core';
import styles from './AlbumList.module.css';
import { Album } from '../../../models/Album';

export interface AlbumListProps {
  albums?: Album[];
  onClick?(album: Album): void;
  theme?: Theme;
}

export interface AlbumListState {}

class AlbumList extends React.Component<AlbumListProps, AlbumListState> {
  constructor(props: AlbumListProps) {
    super(props);
  }

  onAlbumClick(album: Album) {
    if (this.props.onClick) {
      this.props.onClick(album);
    }
  }

  render() {
    const flexContainer = {
      display: 'flex',
      flexDirection: 'row',
    };
    if (this.props.albums) {
      return (
        <>
          <div className={styles.listWrapper}>
            <List style={flexContainer as any}>
              {this.props.albums.map(album => (
                <ListItem
                  key={album.id}
                  onClick={evt => this.onAlbumClick(album)}
                  style={{
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    height: 'fit-content',
                  }}
                >
                  <div
                    className={styles.horizontalItem}
                    style={{
                      backgroundColor: this.props.theme!.palette.background.default,
                      color: this.props.theme!.palette.text.primary,
                    }}
                  >
                    <div className={styles.thumbnailWrapper}>
                      <img className={styles.thumbnail} src={album.thumbnail} />
                    </div>
                    <Typography variant="subtitle2" color="inherit" noWrap={true}>
                      {album.name}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary" noWrap={true}>
                      {album.artist}
                    </Typography>
                  </div>
                </ListItem>
              ))}
            </List>
          </div>
        </>
      );
    }
  }
}

export default withTheme(AlbumList);
