import React from 'react';
import './PlaylistPage.module.css';
import styles from './PlaylistPage.module.css';
import UserTrackList from '../components/UserTrackList';
import { store } from '../../../redux/store';
import {
  fetchUserPlaylistRequest,
  userPlaylistRemoveTrackRequest,
  userPlaylistSongMovedOne,
  userPlaylistSongMovedTopOrBottom,
  userPlaylistShuffle,
  userPlaylistEmptyPlaylist
} from '../../../redux/actions/userPlaylist.action';
import { connect } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import { UserPlaylist } from '../../../models/UserPlaylist';
import { Button, Theme, withTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Search from '../components/Search';
import ShuffleUserPlaylistDialog from '../components/ShuffleUserPlaylistDialog';
import EmptyUserPlaylistDialog from '../components/EmptyUserPlaylistDialog';
import autobind from 'autobind-decorator';
import { Track, FullTrack } from '../../../models/Track';
import { Session } from '../../../models/Session';
import { currentSessionSelector } from '../../../redux/selectors/session/currentSession.selector';
import FullPageLoading from '../../loading/components/FullpageLoading';
import { localisation } from '../../../i18n/LanguageService';
import EmptyPlaylistPlaceholder from '../components/EmptyPlaylistPlaceholder';
import { LoadingState } from '../../../models/LoadingState';
import Fab from '@material-ui/core/Fab';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import DeleteSweep from "@material-ui/icons/DeleteSweep"

export interface PlaylistPageProps {
  playlist?: UserPlaylist;
  loading: LoadingState;
  appInitialized: boolean;
  currentSession?: Session;
  currentSessionId?: string;
  theme?: Theme;
}

export interface PlaylistPageState {
  isSearchDialogOpen: boolean;
  isSpeedDialOpen: boolean;
  isEmptyUserPlaylistDialogOpen: boolean;
  isShufflePlaylistDialogOpen: boolean;
  queryId: number;
}

const mapStateToProps = (state: AppState) => {
  return {
    playlist: state.userPlaylist.playlist || undefined,
    loading: state.userPlaylist.loading,
    appInitialized: state.general.initialized,
    currentSession: currentSessionSelector(state),
    currentSessionId: state.session.currentSession || undefined,
  };
};

class PlaylistPage extends React.Component<PlaylistPageProps, PlaylistPageState> {
  constructor(props: PlaylistPageProps) {
    super(props);
    this.state = { 
      isSearchDialogOpen: false, 
      queryId: Math.random(), 
      isSpeedDialOpen: false, 
      isShufflePlaylistDialogOpen: false, 
      isEmptyUserPlaylistDialogOpen: false
    };
  }

  @autobind
  openSearchDialog() {
    this.setState({ isSearchDialogOpen: true, queryId: Math.random(), isSpeedDialOpen: false });
  }

  @autobind
  onSearchClose() {
    this.setState({ isSearchDialogOpen: false, queryId: Math.random() });
  }

  @autobind
  onSpeedDialOpen() {
    this.setState({ isSpeedDialOpen: true });
  }

  @autobind
  onSpeedDialClose() {
    this.setState({ isSpeedDialOpen: false });
  }

  @autobind
  removeTrack(track: FullTrack) {
    store.dispatch(userPlaylistRemoveTrackRequest(track));
  }

  @autobind
  moveOneUp(trackId: string) {
    store.dispatch(userPlaylistSongMovedOne(trackId, true));
  }

  @autobind
  moveOneDown(trackId: string) {
    store.dispatch(userPlaylistSongMovedOne(trackId, false));
  }

  @autobind
  moveOneTop(trackId: string) {
    store.dispatch(userPlaylistSongMovedTopOrBottom(trackId, true));
  }

  @autobind
  moveOneBottom(trackId: string) {
    store.dispatch(userPlaylistSongMovedTopOrBottom(trackId, false));
  }

  @autobind
  onShufflePlaylistDialogOpen() {
    this.setState({ isShufflePlaylistDialogOpen: true, isSpeedDialOpen: false })
  }

  @autobind
  onShufflePlaylistDialogClose(shuffle: boolean) {
    if (shuffle) {
      store.dispatch(userPlaylistShuffle());
    }

    this.setState({ isSpeedDialOpen: false, isShufflePlaylistDialogOpen: false });
  }

  @autobind
  onEmptyPlaylistDialogOpen() {
    this.setState({ isEmptyUserPlaylistDialogOpen: true, isSpeedDialOpen: false });
  }

  @autobind
  onEmptyPlaylistDialogClose(emptyPlaylist: boolean) {
    if (emptyPlaylist) {
      store.dispatch(userPlaylistEmptyPlaylist());
    }

    this.setState({ isEmptyUserPlaylistDialogOpen: false, isSpeedDialOpen: false });
  }

  render() {
    if (this.props.loading === LoadingState.NOT_STARTED) {
      store.dispatch(fetchUserPlaylistRequest());
    }

    if (this.props.playlist && this.props.playlist.tracks.length <= 0) {
      return (
        <>
          <FullPageLoading
            message={localisation.strings.userPlaylistLoadingMessage}
            zIndex={100}
            visible={
              this.props.loading === LoadingState.LOADING ||
              this.props.loading === LoadingState.NOT_STARTED
            }
          />
          <EmptyPlaylistPlaceholder onAddTracks={this.openSearchDialog} />
          <Search open={this.state.isSearchDialogOpen} onClose={this.onSearchClose} />
        </>
      );
    }

    return (
      <>
        <FullPageLoading
          message={localisation.strings.userPlaylistLoadingMessage}
          zIndex={100}
          visible={
            // Disabled for now. This request is send when there are playlist updated which leads to short
            // but annoying spinner icons. Since the loading is fast, the spinner is disabled for now.
            // this.props.loading === LoadingState.LOADING ||
            this.props.loading === LoadingState.NOT_STARTED
          }
        />
        <div className={styles.playlist}>
          <UserTrackList
            removeable
            onRemove={this.removeTrack}
            onMoveOneTop={this.moveOneTop}
            onMoveOneBottom={this.moveOneBottom}
            onMoveOneUp={this.moveOneUp}
            onMoveOneDown={this.moveOneDown}
            playlist={this.props.playlist}
          />
          <SpeedDial
            ariaLabel="Playlist speeddial actions"
            open={this.state.isSpeedDialOpen}
            onClose={this.onSpeedDialClose}
            onOpen={this.onSpeedDialOpen}
            className={styles.fab}
            icon={<SpeedDialIcon />}
          >
            <SpeedDialAction
              key="addTrack"
              onClick={this.openSearchDialog}
              icon={<AddCircleIcon />}
              tooltipTitle={localisation.strings.speedDialActionAdd}
            />
            <SpeedDialAction
              key="shufflePlaylist"
              onClick={this.onShufflePlaylistDialogOpen}
              icon={<ShuffleIcon />}
              tooltipTitle={localisation.strings.speedDialActionShuffle}
            />
            <SpeedDialAction
              key="emptyPlaylist"
              onClick={this.onEmptyPlaylistDialogOpen}
              icon={<DeleteSweep />}
              tooltipTitle={localisation.strings.speedDialActionEmptyPlaylist}
            />
          </SpeedDial>
          <Search
            open={this.state.isSearchDialogOpen}
            onClose={this.onSearchClose}
            key={this.state.queryId}
          />
          <ShuffleUserPlaylistDialog
            open={this.state.isShufflePlaylistDialogOpen}
            onClose={this.onShufflePlaylistDialogClose}
          />
          <EmptyUserPlaylistDialog
            open={this.state.isEmptyUserPlaylistDialogOpen}
            onClose={this.onEmptyPlaylistDialogClose}
          />
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps)(withTheme(PlaylistPage));
