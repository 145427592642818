export const SPOTIFY_PLAY_REQUEST = 'SPOTIFY_PLAY_REQUEST';
export const SPOTIFY_PLAY_SUCCESS = 'SPOTIFY_PLAY_SUCCESS';
export const SPOTIFY_PLAY_ERROR = 'SPOTIFY_PLAY_ERROR';

export const SPOTIFY_PAUSE_REQUEST = 'SPOTIFY_PAUSE_REQUEST';
export const SPOTIFY_PAUSE_SUCCESS = 'SPOTIFY_PAUSE_SUCCESS';
export const SPOTIFY_PAUSE_ERROR = 'SPOTIFY_PAUSE_ERROR';

export const SPOTIFY_TOGGLE_PAUSED_REQUEST = 'SPOTIFY_TOGGLE_PAUSED_REQUEST';

export const SPOTIFY_FETCH_DEVICES_REQUEST = 'SPOTIFY_FETCH_DEVICES_REQUEST';
export const SPOTIFY_FETCH_DEVICES_SUCCESS = 'SPOTIFY_FETCH_DEVICES_SUCCESS';
export const SPOTIFY_FETCH_DEVICES_ERROR = 'SPOTIFY_FETCH_DEVICES_ERROR';

export const SPOTIFY_DEVICES_UPDATE_ASYNC = 'SPOTIFY_DEVICES_UPDATE_ASYNC';

export const SPOTIFY_SWITCH_DEVICE_REQUEST = 'SPOTIFY_SWITCH_DEVICE_REQUEST';
export const SPOTIFY_SWITCH_DEVICE_SUCCESS = 'SPOTIFY_SWITCH_DEVICE_SUCCESS';
export const SPOTIFY_SWITCH_DEVICE_ERROR = 'SPOTIFY_SWITCH_DEVICE_ERROR';
