import React from 'react';
import * as queryString from 'query-string';
import { AppState } from '../../../redux/reducer';
import { store } from '../../../redux/store';
import { userSignedIn } from '../../../redux/actions/authentication.action';
import { TokenDto } from '../../../interfaces/Token.dto';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

export interface CallbackProps {
  token: TokenDto;
}

export interface CallbackState {}

const mapStateToProps = (state: AppState, ownProps: CallbackProps) => {
  return {
    token: queryString.parse(state.router.location.search),
  };
};

class Callback extends React.Component<CallbackProps, CallbackState> {
  constructor(props: CallbackProps) {
    super(props);

    store.dispatch(userSignedIn(this.props.token));
    store.dispatch(push('/joinSession'));
  }
  render() {
    return <p>Callback...</p>;
  }
}

export default connect(mapStateToProps)(Callback as any);
