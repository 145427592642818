import { action } from 'typesafe-actions';

import { Session, SessionSettings } from '../../models/Session';
import {
  SESSIONS_CHANGED,
  SESSION_CHANGED,
  SESSION_CREATE_REQUEST,
  SESSION_CREATE_SUCCESS,
  SESSION_LEAVE_REQUEST,
  SESSION_SWITCH_REQUEST,
  SESSION_SWITCH_SUCCESS,
  SESSION_LEAVE_SUCCESS,
  SESSION_JOIN_REQUEST,
  SESSION_JOIN_SUCCESS,
  SESSION_CREATE_ERROR,
  SESSION_LEAVE_ERROR,
  SESSION_SWITCH_ERROR,
  SESSION_JOIN_ERROR,
  SESSION_SETTINGS_UPDATE_REQUEST,
  SESSION_SETTINGS_UPDATE_SUCCESS,
  SESSION_SETTINGS_UPDATE_ERROR,
} from '../constants/session.constants';
import { HubError } from '../../models/HubError';

export const sessionsChange = (sessions: Session[]) => action(SESSIONS_CHANGED, sessions);

export const sessionChange = (session: Session) => action(SESSION_CHANGED, session);

export const createSessionRequest = (name: string) => action(SESSION_CREATE_REQUEST, name);
export const createSessionSuccess = (session: Session) => action(SESSION_CREATE_SUCCESS, session);
export const createSessionError = (err: HubError) => action(SESSION_CREATE_ERROR, err);

export const leaveSessionRequest = (sessionId: string) => action(SESSION_LEAVE_REQUEST, sessionId);
export const leaveSessionSuccess = (sessionId: string) => action(SESSION_LEAVE_SUCCESS, sessionId);
export const leaveSessionError = (err: HubError) => action(SESSION_LEAVE_ERROR, err);

export const switchSessionRequest = (sessionId: string) =>
  action(SESSION_SWITCH_REQUEST, sessionId);
export const switchSessionSuccess = (sessionId: string) =>
  action(SESSION_SWITCH_SUCCESS, sessionId);
export const switchSessionError = (err: HubError) => action(SESSION_SWITCH_ERROR, err);

export const joinSessionRequest = (sessionId: string) => action(SESSION_JOIN_REQUEST, sessionId);
export const joinSessionSuccess = (session: Session) => action(SESSION_JOIN_SUCCESS, session);
export const joinSessionError = (err: HubError) => action(SESSION_JOIN_ERROR, err);

export const updateSessionSettingsRequest = (sessionId: string, settings: SessionSettings) =>
  action(SESSION_SETTINGS_UPDATE_REQUEST, { sessionId, settings });
export const updateSessionSettingsSuccess = (session: Session) =>
  action(SESSION_SETTINGS_UPDATE_SUCCESS, session);
export const updateSessionSettingsError = (err: HubError) =>
  action(SESSION_SETTINGS_UPDATE_ERROR, err);
