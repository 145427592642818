import { action } from 'typesafe-actions';
import { FullPlaylist } from '../../models/Playlist';
import {
  PLAYLIST_FETCH_REQUEST,
  PLAYLIST_FETCH_SUCCESS,
  PLAYLIST_FETCH_ERROR,
} from '../constants/general.constants';
import { HubError } from '../../models/HubError';

export const fetchPlaylistRequest = (playlistId: string) =>
  action(PLAYLIST_FETCH_REQUEST, playlistId);
export const fetchPlaylistSuccess = (playlist: FullPlaylist) =>
  action(PLAYLIST_FETCH_SUCCESS, playlist);
export const fetchPlaylistError = (playlistId: string, err: HubError) =>
  action(PLAYLIST_FETCH_ERROR, { err, id: playlistId });
