import { singalRService } from '../../services/singalR.service';
import { Session, SessionSettings } from '../../../models/Session';
import { put } from 'redux-saga/effects';
import {
  createSessionSuccess,
  leaveSessionSuccess,
  sessionChange,
  joinSessionSuccess,
  createSessionError,
  leaveSessionError,
  joinSessionError,
  updateSessionSettingsSuccess,
} from '../../actions/session.action';
import { HubError } from '../../../models/HubError';

export function* sendSessionCreateRequestToServer(sessionName: string) {
  try {
    const session: Session = yield singalRService.invoke('CreateSession', sessionName);
    yield put(createSessionSuccess(session));
  } catch (err) {
    yield put(createSessionError(new HubError(err)));
  }
}

export function* sendSessionLeaveRequestToServer(sessionId: string) {
  try {
    yield singalRService.invoke('LeaveSession', sessionId);
    yield put(leaveSessionSuccess(sessionId));
  } catch (err) {
    yield put(leaveSessionError(new HubError(err)));
  }
}

export function* sendSessionJoinRequestToServer(sessionId: string) {
  try {
    const session: Session = yield singalRService.invoke('JoinSession', sessionId);
    yield put(joinSessionSuccess(session));
    yield put(sessionChange(session));
  } catch (err) {
    yield put(joinSessionError(new HubError(err)));
  }
}

export function* sendSessionSettingsUpdateRequestToServer(
  sessionId: string,
  settings: SessionSettings
) {
  try {
    const session: Session = yield singalRService.invoke(
      'UpdateSessionSettings',
      sessionId,
      settings
    );
    yield put(updateSessionSettingsSuccess(session));
  } catch (err) {
    yield put(createSessionError(new HubError(err)));
  }
}

export function* writeCurrentSessionToLocalStorage(sessionId: string) {
  localStorage.setItem('currentSession', sessionId);
}
