import React from 'react';
import styles from './EmptyPlaylistPlaceholder.module.css';
import EmptyIllustration from '../../illustrations/Empty';
import { withTheme, Theme, Typography, Button } from '@material-ui/core';
import { localisation } from '../../../i18n/LanguageService';
import autobind from 'autobind-decorator';
import { userPlaylistAddTracksRequest } from '../../../redux/actions/userPlaylist.action';

export interface EmptyPlaylistPlaceholderProps {
  theme?: Theme;
  onAddTracks(): void;
}

export interface EmptyPlaylistPlaceholderState {}

class EmptyPlaylistPlaceholder extends React.Component<
  EmptyPlaylistPlaceholderProps,
  EmptyPlaylistPlaceholderState
> {
  constructor(props: EmptyPlaylistPlaceholderProps) {
    super(props);
  }

  @autobind
  addTracks() {
    this.props.onAddTracks();
  }

  render() {
    return (
      <div className={styles.root}>
        <div className={styles.illustration}>
          <EmptyIllustration color={this.props.theme!.palette.primary.main} />
        </div>
        <Typography color="primary" gutterBottom variant="h5">
          {localisation.strings.playlistEmptyTitle}
        </Typography>
        <Typography color="textSecondary" variant="subtitle1">
          {localisation.strings.playlistEmptyMessage}
        </Typography>
        <Button
          onClick={this.addTracks}
          className={styles.button}
          color="primary"
          variant="contained"
        >
          {localisation.strings.playlistAddTracks}
        </Button>
      </div>
    );
  }
}

export default withTheme(EmptyPlaylistPlaceholder);
