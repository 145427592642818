import { ActionType } from 'typesafe-actions';
import * as actions from './../actions/settings.action';
import { combineReducers } from 'redux';

export interface AppSettingsState {
  loginUrl: string;
  refreshUrl: string;
  serverUrl: string;
  frontendUrl: string;
}

export type SettingsAction = ActionType<typeof actions>;

export const settings = combineReducers<AppSettingsState, SettingsAction>({
  loginUrl: (state = null, action) => {
    return state;
  },
  serverUrl: (state = null, action) => {
    return state;
  },
} as any);
