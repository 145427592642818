import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter as Router, push } from 'connected-react-router';
import LoginPage from '../modules/login/pages/LoginPage';
import SessionRouter from './SessionRouter';
import { history, store } from '../redux/store';
import JoinSessionPage from '../modules/lobby/pages/JoinSessionPage';
import Callback from '../modules/login/pages/Callback';
import autobind from 'autobind-decorator';
import { AppState } from '../redux/reducer';
import FullPageLoading from '../modules/loading/components/FullpageLoading';
import { localisation } from '../i18n/LanguageService';
import { connect } from 'react-redux';
import { currentSessionSelector } from '../redux/selectors/session/currentSession.selector';
import { Session } from '../models/Session';
import Notifier from '../modules/general/components/Notifier/Notifier';
import { SnackbarProvider } from 'notistack';
import { withTheme, Theme, withStyles } from '@material-ui/core';
import LegalNoticePage from '../modules/legal/pages/LegalNoticePage';
import PrivacyPloicyPage from '../modules/legal/pages/PrivacyPolicyPage';

export interface AppRouterProps {
  appInitialized?: boolean;
  currentSession?: Session;
  location?: any;
  theme?: Theme;
  classes?: any;
}

export interface AppRouterState {}

const mapStateToProps = (state: AppState) => {
  return {
    appInitialized: state.general.initialized,
    currentSession: currentSessionSelector(state),
    location: state.router.location || undefined,
  };
};

class AppRouter extends React.Component<AppRouterProps, AppRouterState> {
  constructor(props: AppRouterProps) {
    super(props);
  }

  @autobind
  renderRoutes() {
    if (
      !this.props.appInitialized &&
      (this.props.location!.pathname.startsWith('/session') ||
        this.props.location!.pathname.startsWith('/joinSession'))
    ) {
      return (
        <>
          <FullPageLoading message={localisation.strings.appLoadingMessage} visible={true} />
        </>
      );
    } else {
      return (
        <>
          <FullPageLoading
            message={localisation.strings.appLoadingMessage}
            visible={false}
            artificialDelay={500}
          />
          <Notifier />
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/session" component={SessionRouter} />
            <Route path="/joinSession" component={JoinSessionPage} />
            <Route path="/content" component={SessionRouter} />
            <Route path="/callback" component={Callback} />
            <Route path="/legal" component={LegalNoticePage} />
            <Route path="/privacy" component={PrivacyPloicyPage} />
          </Switch>
        </>
      );
    }
  }
  render() {
    return (
      <SnackbarProvider maxSnack={3} style={{ marginBottom: 42 }}>
        <Router history={history}>{this.renderRoutes()}</Router>
      </SnackbarProvider>
    );
  }
}

export default connect(mapStateToProps)(AppRouter);
