import React from 'react';

export interface RandomIllustrationProps {
  color: string;
  className?: string;
}

export interface RandomIllustrationState {}

class RandomIllustration extends React.Component<RandomIllustrationProps> {

  constructor(props) {
    super(props);
  }

  render() {
    return (
        <svg id="b90d1e5d-c0c0-42b5-8a03-05d524a4d224" data-name="Layer 1" className={this.props.className}
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 891.04812 586.95236">
            <title>random_thoughts</title>
            <path d="M1036.78859,742.88413c21.591-80.74221,1.96362-165.66153-45.69913-226.97822L581.90621,623.35142l388.78793-130.857C777.54254,303.059,467.10106,481.23478,530.32037,742.0466Z" transform="translate(-154.47594 -156.52382)" fill="#e6e6e6"/>
            <circle cx="179.49636" cy="187.22089" r="114.47894" fill="#e6e6e6"/>
            <path d="M486.61089,385.679h-83.449a291.86641,291.86641,0,0,1,0-99.49691h83.449A179.02849,179.02849,0,0,0,486.61089,385.679Z" transform="translate(-154.47594 -156.52382)" fill="#ff6584"/>
            <path d="M468.09493,313.1315H417.31467c-.87635,0-1.58688-1.14154-1.58688-2.55227s.71053-2.55227,1.58688-2.55227h50.78026c.87635,0,1.58688,1.14154,1.58688,2.55227S468.97128,313.1315,468.09493,313.1315Z" transform="translate(-154.47594 -156.52382)" fill="#fff"/>
            <path d="M468.09493,336.80877H417.31467c-.87635,0-1.58688-1.14154-1.58688-2.55226s.71053-2.55227,1.58688-2.55227h50.78026c.87635,0,1.58688,1.14154,1.58688,2.55227S468.97128,336.80877,468.09493,336.80877Z" transform="translate(-154.47594 -156.52382)" fill="#fff"/>
            <path d="M227.3209,284.70748H334.82977a376.01691,376.01691,0,0,0,0-128.18366H227.3209A230.64572,230.64572,0,0,1,227.3209,284.70748Z" transform="translate(-154.47594 -156.52382)" fill={this.props.color}/>
            <path d="M317.86713,193.87033H252.446c-1.129,0-2.04441-1.47066-2.04441-3.28813s.91539-3.28813,2.04441-3.28813h65.42111c1.129,0,2.04441,1.47067,2.04441,3.28813S318.99615,193.87033,317.86713,193.87033Z" transform="translate(-154.47594 -156.52382)" fill="#fff"/>
            <path d="M317.86713,224.3742H252.446c-1.129,0-2.04441-1.47067-2.04441-3.28813s.91539-3.28813,2.04441-3.28813h65.42111c1.129,0,2.04441,1.47066,2.04441,3.28813S318.99615,224.3742,317.86713,224.3742Z" transform="translate(-154.47594 -156.52382)" fill="#fff"/>
            <path d="M317.86713,254.87806H252.446c-1.129,0-2.04441-1.47067-2.04441-3.28813s.91539-3.28813,2.04441-3.28813h65.42111c1.129,0,2.04441,1.47067,2.04441,3.28813S318.99615,254.87806,317.86713,254.87806Z" transform="translate(-154.47594 -156.52382)" fill="#fff"/>
            <path d="M156.30156,483.98564H263.81043a376.01691,376.01691,0,0,0,0-128.18366H156.30156A230.64572,230.64572,0,0,1,156.30156,483.98564Z" transform="translate(-154.47594 -156.52382)" fill={this.props.color}/>
            <path d="M246.84779,393.14849H181.42667c-1.129,0-2.04441-1.47066-2.04441-3.28813s.91539-3.28813,2.04441-3.28813h65.42112c1.129,0,2.04441,1.47067,2.04441,3.28813S247.9768,393.14849,246.84779,393.14849Z" transform="translate(-154.47594 -156.52382)" fill="#fff"/>
            <path d="M246.84779,423.65236H181.42667c-1.129,0-2.04441-1.47067-2.04441-3.28813s.91539-3.28813,2.04441-3.28813h65.42112c1.129,0,2.04441,1.47066,2.04441,3.28813S247.9768,423.65236,246.84779,423.65236Z" transform="translate(-154.47594 -156.52382)" fill="#fff"/>
            <path d="M720,707l15.50238-26.02308-21.9064-8.76256-2.00714,2.56468c-5.017,6.41067-9.496,19.95515-15.58884,25.221" transform="translate(-154.47594 -156.52382)" fill="#ffb8b8"/>
            <path d="M727.42879,711.10837l-.7563,22.5997a8.17376,8.17376,0,0,1-8.25023,7.9l-17.09386-.16947H682.05077s-35.9265-.87625-37.679-14.89635c-.58417-4.67336,3.40766-7.78894,8.98974-9.866a136.77214,136.77214,0,0,0,37.20123-20.80651,9.6567,9.6567,0,0,1,9.6081-1.42735l22.25232,8.97521A8.00412,8.00412,0,0,1,727.42879,711.10837Z" transform="translate(-154.47594 -156.52382)" fill="#2f2e41"/>
            <circle cx="532.81569" cy="548.39492" r="1.75251" fill="#fff"/>
            <circle cx="538.44514" cy="542.28395" r="1.75251" fill="#fff"/>
            <path d="M893.22126,672.70782,885.782,643.34489l-21.68623,9.2941.39424,3.23276c.98544,8.08062,7.39574,20.8251,6.81093,28.85689" transform="translate(-154.47594 -156.52382)" fill="#ffb8b8"/>
            <path d="M901.34675,670.32516l15.12669,16.17418a8.70055,8.70055,0,0,1-.26362,12.1559L904.3702,710.2624l-13.63134,13.63134s-26.02347,24.78426-37.17639,16.10977c-3.71764-2.8915-3.098-7.91719-.61961-13.333A136.72435,136.72435,0,0,0,864.567,685.38229a9.17333,9.17333,0,0,1,5.50276-7.42614l22.37564-9.51353A7.93608,7.93608,0,0,1,901.34675,670.32516Z" transform="translate(-154.47594 -156.52382)" fill="#2f2e41"/>
            <circle cx="714.14534" cy="537.84064" r="1.75251" fill="#fff"/>
            <circle cx="713.80486" cy="529.53891" r="1.75251" fill="#fff"/>
            <path d="M761.79,304.18688l9.63882,27.16393,25.41142-14.89635s-13.14384-15.77261-12.26758-24.53516Z" transform="translate(-154.47594 -156.52382)" fill="#ffb8b8"/>
            <circle cx="610.38101" cy="127.07104" r="24.53517" fill="#ffb8b8"/>
            <path d="M800.34532,312.94944l-5.13972,1.43693s-10.63289-.56068-15.89043,7.32563-9.14721,6.08532-9.14721,6.08532l-21.52174,32.46994s-7.01,13.14384-4.38128,28.04019,11.39132,35.92649,11.39132,35.92649,56.08038,5.25753,57.83289,2.62877,0-20.15389,0-20.15389l7.01-77.98678s-7.8863-13.14383-12.26758-13.14383A14.6308,14.6308,0,0,1,800.34532,312.94944Z" transform="translate(-154.47594 -156.52382)" fill={this.props.color}/>
            <path d="M817.87043,421.60517s-14.89635-.87625-21.03014.87626-42.93654,0-42.93654,0l-10.51507,65.71919-34.174,185.76626L746.01745,687.987l43.81279-104.27445,5.25754,14.89635,66.59545,72.72924,35.05024-15.77261-57.83289-96.38815-18.40138-67.4717s11.39133-38.55527-4.38128-62.21418Z" transform="translate(-154.47594 -156.52382)" fill="#2f2e41"/>
            <rect x="772.74322" y="488.08145" width="1.75258" height="98.379" transform="translate(-288.31957 128.442) rotate(-19.24188)" opacity="0.2"/>
            <path d="M810.3144,244.86854c-1.39606-5.52183-6.06169-9.10475-10.421-8.00256-.04939.01247-.09505.03278-.144.04639a6.52213,6.52213,0,0,1,1.5669-.62269c4.35933-1.1022,9.025,2.48072,10.421,8.00256,1.3802,5.45922-.95573,10.76257-5.22158,11.94731C809.858,254.387,811.53243,249.68648,810.3144,244.86854Z" transform="translate(-154.47594 -156.52382)" fill="#2f2e41"/>
            <path d="M809.286,253.46139c4.39846-3.61845,5.6484-9.36676,2.79169-12.8392-.03235-.03935-.0702-.072-.10337-.11043a6.52234,6.52234,0,0,1,1.23166,1.15148c2.85671,3.47244,1.60678,9.22075-2.79169,12.8392-4.34859,3.5774-10.14166,3.72735-13.03314.37467C800.48264,257.10952,805.44824,256.61852,809.286,253.46139Z" transform="translate(-154.47594 -156.52382)" fill="#2f2e41"/>
            <ellipse cx="803.11335" cy="249.67949" rx="8.14163" ry="10.31273" transform="translate(10.12141 640.53873) rotate(-57.87554)" fill="#2f2e41"/>
            <path d="M794.64022,252.33088a12.0545,12.0545,0,0,1,6.51353,1.91295,12.27876,12.27876,0,0,1,4.14375,4.24369,12.0545,12.0545,0,0,1-6.51353-1.913A12.27909,12.27909,0,0,1,794.64022,252.33088Z" transform="translate(-154.47594 -156.52382)" fill={this.props.color}/>
            <path d="M761.35449,248.54463h0c-3.72173,0-4.89172,3.30341-4.184,6.7684a103.94148,103.94148,0,0,1-4.58111,58.07454h22.51118l4.21463-9.97378,1.63271,9.97378h29.47438V303.32849C820.34175,262.58327,799.30562,242.699,761.35449,248.54463Z" transform="translate(-154.47594 -156.52382)" fill="#2f2e41"/>
            <path d="M757.66335,249.42088h-2.26166a16.83232,16.83232,0,0,0-16.83242,16.83231v5.95035l14.68692-1.79108,1.08569-4.34271,1.02151,4.08573,19.13238-2.33322v-1.56907A16.83232,16.83232,0,0,0,757.66335,249.42088Z" transform="translate(-154.47594 -156.52382)" fill="#2f2e41"/>
            <polygon points="640.277 201.363 631.515 266.206 622.324 266.685 640.277 201.363" opacity="0.2"/>
            <path d="M785.693,435.95711l-17.41012,52.23035s-36.27108,40.62361-15.95927,40.62361S782.79127,492.54,782.79127,492.54l24.66434-52.23035Z" transform="translate(-154.47594 -156.52382)" fill="#ffb8b8"/>
            <path d="M803.8285,317.71339s11.80078-5.10819,18.96512,7.75225,4.24837,61.88822,2.79753,69.14244-11.60675,52.23035-11.60675,52.23035l-34.82024-7.25421s-2.90168-20.31181,5.80338-18.861Z" transform="translate(-154.47594 -156.52382)" fill={this.props.color}/>
            <rect y="584.71164" width="888" height="2.24072" fill="#3f3d56"/>
        </svg>
      );
    }
  }

export default RandomIllustration;
