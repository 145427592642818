import React from 'react';
import * as QRCode from 'qrcode.react';
import styles from './SessionSettingsPage.module.css';
import {
  withTheme,
  Theme,
  Typography,
  Select,
  Input,
  Chip,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { connect } from 'react-redux';

import { string } from 'prop-types';
import { AppState } from '../../../redux/reducer';
import { currentSessionSelector } from '../../../redux/selectors/session/currentSession.selector';
import { Session } from '../../../models/Session';
import autobind from 'autobind-decorator';
import BlockIllustration from '../../illustrations/BlockIllustration';
import ArtificialInteligenceIllustration from '../../illustrations/ArtificialInteligenceIllustration';
import { store } from '../../../redux/store';
import { updateSessionSettingsRequest } from '../../../redux/actions/session.action';
import { localisation } from '../../../i18n/LanguageService';
import { isOwnerSelector } from '../../../redux/selectors/session/isOwner.selector';

const genres = [
  'acoustic',
  'afrobeat',
  'alt-rock',
  'alternative',
  'ambient',
  'anime',
  'black-metal',
  'bluegrass',
  'blues',
  'bossanova',
  'brazil',
  'breakbeat',
  'british',
  'cantopop',
  'chicago-house',
  'children',
  'chill',
  'classical',
  'club',
  'comedy',
  'country',
  'dance',
  'dancehall',
  'death-metal',
  'deep-house',
  'detroit-techno',
  'disco',
  'disney',
  'drum-and-bass',
  'dub',
  'dubstep',
  'edm',
  'electro',
  'electronic',
  'emo',
  'folk',
  'forro',
  'french',
  'funk',
  'garage',
  'german',
  'gospel',
  'goth',
  'grindcore',
  'groove',
  'grunge',
  'guitar',
  'happy',
  'hard-rock',
  'hardcore',
  'hardstyle',
  'heavy-metal',
  'hip hop',
  'holidays',
  'honky-tonk',
  'house',
  'idm',
  'indian',
  'indie',
  'indie-pop',
  'industrial',
  'iranian',
  'j-dance',
  'j-idol',
  'j-pop',
  'j-rock',
  'jazz',
  'k-pop',
  'kids',
  'latin',
  'latino',
  'malay',
  'mandopop',
  'metal',
  'movies',
  'mpb',
  'new-age',
  'new-release',
  'opera',
  'pagode',
  'party',
  'philippines-opm',
  'piano',
  'pop',
  'pop-film',
  'post-dubstep',
  'power-pop',
  'progressive-house',
  'psych-rock',
  'punk',
  'punk-rock',
  'r-n-b',
  'rap',
  'rainy-day',
  'reggae',
  'reggaeton',
  'road-trip',
  'rock',
  'rock-n-roll',
  'rockabilly',
  'romance',
  'sad',
  'salsa',
  'samba',
  'sertanejo',
  'show-tunes',
  'singer-songwriter',
  'ska',
  'sleep',
  'songwriter',
  'soul',
  'soundtracks',
  'spanish',
  'study',
  'summer',
  'swedish',
  'synth-pop',
  'tango',
  'techno',
  'trance',
  'trip-hop',
  'turkish',
  'work-out',
  'world-music',
];

export interface SessionSettingsPageProps {
  theme?: Theme;
  currentSession?: Session;
  appInitialized: boolean;
  isOwner: boolean;
}

export interface SessionSettingsPageState {
  blockedGenres: string[];
  isSelectGenreOpen: boolean;
}

const mapStateToProps = (state: AppState) => {
  return {
    frontendUrl: state.settings.frontendUrl,
    currentSession: currentSessionSelector(state),
    isOwner: isOwnerSelector(state),
    appInitialized: state.general.initialized,
  };
};

class SessionSettingsPage extends React.Component<
  SessionSettingsPageProps,
  SessionSettingsPageState
> {
  constructor(props: SessionSettingsPageProps) {
    super(props);

    this.state = { blockedGenres: [], isSelectGenreOpen: false };
  }

  static getDerivedStateFromProps(
    nextProps: SessionSettingsPageProps,
    prevState: SessionSettingsPageState
  ) {
    if (nextProps.currentSession && nextProps.currentSession.settings) {
      return Object.assign(prevState, {
        blockedGenres: nextProps.currentSession.settings.genreBlacklist || [],
      });
    }
    return prevState;
  }

  componentWillMount() {
    if (this.props.currentSession && this.props.currentSession.settings) {
      this.setState({ blockedGenres: this.props.currentSession.settings.genreBlacklist || [] });
    }
  }

  @autobind
  handleGenreFilterChange(evt) {
    this.setState({ blockedGenres: evt.target.value });
  }

  @autobind
  handleGenreSelectClose() {
    const session = this.props.currentSession;
    if (session) {
      const settings = session.settings || {};

      store.dispatch(
        updateSessionSettingsRequest(
          session.sessionId,
          Object.assign(settings, { genreBlacklist: this.state.blockedGenres })
        )
      );
    }
    this.setState({ isSelectGenreOpen: false });
  }

  render() {
    return (
      <div className={styles.root}>
        <div className={styles.row}>
          <BlockIllustration color={this.props.theme!.palette.primary.main} />
          <Typography color="primary" variant="h6" className={styles.importantText}>
            {localisation.strings.sessionSettingsExcludeGenreTitle}
          </Typography>
          <Typography color="textSecondary" variant="subtitle1" className={styles.importantText}>
            {localisation.strings.sessionSettingsExcludeGenreMessage}
          </Typography>
          <FormControl style={{ width: '100%' }}>
            <InputLabel htmlFor="demo-controlled-open-select">
              {localisation.strings.sessionSettingsExcludeGenrePlaceholder}
            </InputLabel>
            <Select
              multiple
              disabled={!this.props.isOwner}
              open={this.state.isSelectGenreOpen}
              value={this.state.blockedGenres}
              onChange={this.handleGenreFilterChange}
              onClose={this.handleGenreSelectClose}
              onOpen={() => this.setState({ isSelectGenreOpen: true })}
              input={
                <Input
                  id="select-multiple-chip"
                  style={{ width: '100%' }}
                  placeholder="Block genres"
                />
              }
              inputProps={{
                name: 'age',
                id: 'demo-controlled-open-select',
              }}
              renderValue={selected => (
                <div>
                  {(selected as any).map(value => (
                    <Chip key={value} label={value} style={{ marginRight: 8 }} />
                  ))}
                </div>
              )}
            >
              {genres.map(name => (
                <MenuItem key={name} value={name}>
                  <ListItemText primary={name} />
                  <Checkbox color="primary" checked={this.state.blockedGenres.indexOf(name) > -1} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={styles.row}>
          <ArtificialInteligenceIllustration color={this.props.theme!.palette.primary.main} />
          <Typography color="primary" variant="h6" className={styles.importantText}>
            {localisation.strings.sessionSettingsJukeboxModeTitle}
          </Typography>
          <Typography color="textSecondary" variant="subtitle1" className={styles.importantText}>
            {localisation.strings.sessionSettingsJukeboxModeMessage}
          </Typography>
        </div>
      </div>
    );
  }
}

export default withTheme(connect(mapStateToProps)(SessionSettingsPage));
