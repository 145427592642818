import { all, fork, take, put, takeLatest } from 'redux-saga/effects';
import {
  sendUserPlaylistRequestToServer,
  sendAddTracksRequestToServer,
  sendRemoveTrackRequestToServer,
  sendUserPlaylistMovedOne,
  sendUserPlaylistMovedOneTopOrBottom,
  sendUserPlaylistShuffe,
  sendUserPlaylistEmptyPlaylist
} from './playlist.worker';
import { store } from '../../store';
import {
  USERPLAYLIST_FETCH_REQUEST,
  USERPLAYLIST_ADD_TRACKS_REQUEST,
  USERPLAYLIST_REMOVE_TRACK_REQUEST,
  USERPLAYLIST_CHANGED_PLAYLIST,
  USERPLAYLIST_SONG_MOVED_ONE,
  USERPLAYLIST_SONG_MOVED_TOP_BOTTOM,
  USERPLAYLIST_SHUFFLE,
  USERPLAYLIST_EMPTY_PLAYLIST
} from '../../constants/playlist.constants';
import { fetchUserPlaylistRequest } from '../../actions/userPlaylist.action'

function* watchUserPlaylistRequest() {
  yield take(USERPLAYLIST_FETCH_REQUEST);
  const { session } = store.getState();

  if (session.currentSession) {
    yield sendUserPlaylistRequestToServer(session.currentSession);
  }

  yield fork(watchUserPlaylistRequest);
}

function* watchAddTrackRequest() {
  const action = yield take(USERPLAYLIST_ADD_TRACKS_REQUEST);
  const tracks = action.payload;
  const { session } = store.getState();
  if (session.currentSession) {
    yield sendAddTracksRequestToServer(session.currentSession, tracks);
  }

  yield fork(watchAddTrackRequest);
}

function* watchRemoveTrackRequest() {
  const action = yield take(USERPLAYLIST_REMOVE_TRACK_REQUEST);
  const track = action.payload;

  const { session } = store.getState();
  if (session.currentSession) {
    yield sendRemoveTrackRequestToServer(session.currentSession, track);
  }

  yield fork(watchRemoveTrackRequest);
}

function* watchUserPlaylistChanged() {
  const action = yield take(USERPLAYLIST_CHANGED_PLAYLIST);
  const users: String[] = action.payload;

  const { authentication } = store.getState();
  const userId: String = authentication.user.jukify_user_id;

  if (users.findIndex((value, index) => value === userId) >= 0) {
    // Playlist of this user has changed, try to fetch updated playlist
    yield put(fetchUserPlaylistRequest());
  }

  yield fork(watchUserPlaylistChanged);
}

function* watchUserPlaylistMovedOne() {
  const action = yield take(USERPLAYLIST_SONG_MOVED_ONE);
  const movement: { trackId: string, up: boolean } = action.payload;

  const { session } = store.getState();

  yield sendUserPlaylistMovedOne(session.currentSession, movement.trackId, movement.up ? -1 : 1);

  yield fork(watchUserPlaylistMovedOne);
}

function* watchUserPlaylistMovedTopOrBottom() {
  const action = yield take(USERPLAYLIST_SONG_MOVED_TOP_BOTTOM);
  const movement: { trackId: string, top: boolean } = action.payload;

  const { session } = store.getState();

  yield sendUserPlaylistMovedOneTopOrBottom(session.currentSession, movement.trackId, movement.top);

  yield fork(watchUserPlaylistMovedTopOrBottom);
}

function* watchUserPlaylistShuffe() {
  yield take(USERPLAYLIST_SHUFFLE);
  const { session } = store.getState();

  yield sendUserPlaylistShuffe(session.currentSession)
  
  yield fork(watchUserPlaylistShuffe);
}

function* watchUserPlaylistEmptyPlaylist() {
  yield take(USERPLAYLIST_EMPTY_PLAYLIST);
  const { session } = store.getState();

  yield sendUserPlaylistEmptyPlaylist(session.currentSession);

  yield fork(watchUserPlaylistEmptyPlaylist);
}

export default function* playlistSaga() {
  yield all([
    fork(watchUserPlaylistRequest),
    fork(watchAddTrackRequest),
    fork(watchRemoveTrackRequest),
    fork(watchUserPlaylistChanged),
    fork(watchUserPlaylistMovedOne),
    fork(watchUserPlaylistMovedTopOrBottom),
    fork(watchUserPlaylistShuffe),
    fork(watchUserPlaylistEmptyPlaylist)
  ]);
}
