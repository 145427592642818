import { createSelector } from 'reselect';
import { AppState } from '../../reducer';

const getCurrentSessionId = (state: AppState) => state.session.currentSession;
const getSessionMap = (state: AppState) => state.session.sessionMap;

export const currentSessionSelector = createSelector(
  [getCurrentSessionId, getSessionMap],
  (currentSessionId, sessionMap) => {
    if (sessionMap && currentSessionId) {
      return sessionMap[currentSessionId];
    }
    return undefined;
  }
);
