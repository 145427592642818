import React from 'react';
import styles from './SwipeableList.module.css';

import { List, withTheme, Theme } from '@material-ui/core';
import autobind from 'autobind-decorator';
import SwipeableListItem from './components/SwipeableListItem';

export class Item {
  constructor(public id, public title, public subtitle, public icon, public secondary?) {}
}

export interface SwipeableListProps {
  items?: Item[];
  onSwipe?(item: Item): void;
  onClick?(item: Item): void;
  theme?: Theme;
}

export interface SwipeableListState {
  items: Item[];
}

class SwipeableList extends React.Component<SwipeableListProps, SwipeableListState> {
  constructor(props) {
    super(props);
    this.state = { items: [] };
  }

  componentDidMount() {
    const items = this.props.items;
    if (items) this.setState({ items: items });
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.items) {
      return Object.assign(prevState, { items: nextProps.items });
    }
    return prevState;
  }

  @autobind
  getOnClickCallback() {
    if (this.props.onClick) {
      return this.props.onClick;
    }
    return () => {};
  }

  @autobind
  getOnSwipeCallback() {
    if (this.props.onSwipe) {
      return this.props.onSwipe;
    }
    return () => {};
  }

  render() {
    return <List className={styles.list}>{this.props.children}</List>;
  }
}

export default withTheme(SwipeableList);
