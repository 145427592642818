import React from 'react';
import styles from './SessionList.module.css';
import {
  Button,
  Theme,
  withTheme,
  Avatar,
  Typography,
  Chip,
  ListItemText,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Session } from '../../../models/Session';
import autobind from 'autobind-decorator';
import { localisation } from '../../../i18n/LanguageService';
import SwipeableList, { Item } from '../../general/components/SwipeableList/SwipeableList';
import SwipeableListItem from '../../general/components/SwipeableList/components/SwipeableListItem';
import { ListItem } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';

export interface SessionListProps {
  theme?: Theme;
  sessions: Session[];
  onClick?(sessionId: string): void;
  onFabClick?(): void;
  onLeave?(sessionId: string): void;
  hideSecondaryAction?: boolean;
  swipe?: boolean;
}

export interface SessionListState {}

class SessionList extends React.Component<SessionListProps, SessionListState> {
  constructor(props: SessionListProps) {
    super(props);
  }

  @autobind
  onClick(sessionId: string) {
    if (this.props.onClick) {
      this.props.onClick(sessionId);
    }
  }

  @autobind
  onLeave(sessionId: string) {
    if (this.props.onLeave) {
      this.props.onLeave(sessionId);
    }
  }

  @autobind
  renderListItemContent(icon: string, text: string, session: Session) {
    return (
      <>
        <Avatar src={icon} style={{ borderRadius: 0 }} />

        <div className={styles.listText}>
          <Typography variant="subtitle1" noWrap={true}>
            {session.name}
          </Typography>
          <div className={styles.secondRow}>
            <Typography variant="body1" style={{ color: this.props.theme!.palette.text.secondary }}>
              {text}
            </Typography>
          </div>
        </div>
      </>
    );
  }

  @autobind
  renderList() {
    return (
      <SwipeableList>
        {this.props.sessions.map(session => {
          let icon;
          let text = `${session.sessionId} - `;
          if (session.trackQueue && session.trackQueue.length > 0) {
            icon = session.trackQueue[0].thumbnail;
            text += `${session.trackQueue[0].name}`;
          } else {
            icon =
              'https://recordsale.de/assets/record_placeholder-f3f829566497dc26b0abfae50ddeb5c7bc48fe1c58dc1c7fe62a26d64988b9c9.svg';
            text += `${localisation.strings.sessionListNotStarted}`;
          }
          {
            return this.props.swipe ? (
              <SwipeableListItem
                key={session.sessionId}
                onClick={() => this.onClick(session.sessionId)}
                onSwipe={() => this.onLeave(session.sessionId)}
              >
                {this.renderListItemContent(icon, text, session)}
              </SwipeableListItem>
            ) : (
              <ListItem key={session.sessionId} onClick={() => this.onClick(session.sessionId)}>
                {this.renderListItemContent(icon, text, session)}
              </ListItem>
            );
          }
        })}
      </SwipeableList>
    );
  }

  render() {
    return (
      <div className={styles.sessionList}>
        {this.props.sessions ? this.renderList() : null}
        <Fab
          color="primary"
          aria-label="Add"
          className={styles.fab}
          onClick={this.props.onFabClick}
          style={{
            background: `linear-gradient(to right, ${this.props.theme!.palette.primary.main}, ${
              this.props.theme!.palette.primary.dark
            })`,
            color: this.props.theme!.palette.primary.contrastText,
          }}
        >
          <AddIcon />
        </Fab>
      </div>
    );
  }
}

export default withTheme(SessionList);
