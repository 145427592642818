import { take, fork, all } from 'redux-saga/effects';
import { writeTokenToLocalStorage, removeTokenFromLocalStorage } from './authentication.worker';
import { USER_SIGNED_IN, SIGNOUT } from '../../constants/authentication.constants';

function* watchUserSignedIn() {
  const action = yield take(USER_SIGNED_IN);
  yield writeTokenToLocalStorage(action.payload);

  yield fork(watchUserSignedIn);
}

function* watchUserSignedOut() {
  const action = yield take(SIGNOUT);
  yield removeTokenFromLocalStorage();

  yield fork(watchUserSignedOut);
}

export default function* authenticationSaga() {
  yield all([fork(watchUserSignedIn), fork(watchUserSignedOut)]);
}
