import { ActionType } from 'typesafe-actions';
import * as actions from './../actions/userPlaylist.action';
import { combineReducers } from 'redux';
import { UserPlaylist } from '../../models/UserPlaylist';
import {
  USERPLAYLIST_FETCH_SUCCESS,
  USERPLAYLIST_ADD_TRACKS_SUCCESS,
  USERPLAYLIST_REMOVE_TRACK_SUCCESS,
  USERPLAYLIST_FETCH_REQUEST,
  USERPLAYLIST_SONG_MOVED_ONE,
  USERPLAYLIST_SONG_MOVED_TOP_BOTTOM
} from '../constants/playlist.constants';
import { SESSION_SWITCH_SUCCESS } from '../constants/session.constants';
import { LoadingState } from '../../models/LoadingState';
import { FullTrack } from '../../models/Track';

export interface UserPlaylistState {
  playlist: UserPlaylist | null;
  loading: LoadingState;
}

export type UserPlaylistAction = ActionType<typeof actions>;

export const userPlaylist = combineReducers<UserPlaylistState, UserPlaylistAction>({
  playlist: (state = null, action) => {
    switch (action.type) {
      case USERPLAYLIST_FETCH_SUCCESS: {
        const userPlaylist = action.payload;

        return userPlaylist;
      }
      case USERPLAYLIST_ADD_TRACKS_SUCCESS: {
        const tracks = action.payload;
        const newState = Object.assign({}, state);

        tracks.forEach(t => {
          if (!newState.tracks.some(e => e.id === t.id)) {
            newState.tracks.splice(0, 0, t);
          }
        });

        return newState;
      }
      case USERPLAYLIST_REMOVE_TRACK_SUCCESS: {
        const trackId = action.payload;

        const newState = Object.assign({}, state);
        const index: number[] = [];
        newState.tracks.forEach((t, i) => {
          if (t.id === trackId) {
            index.push(i);
          }
        });
        index.forEach(i => {
          newState.tracks.splice(i, 1);
        });
        return newState;
      }
      case USERPLAYLIST_SONG_MOVED_ONE: {
        const movement = action.payload;

        const newState = Object.assign({}, state);
        let tracks = newState.tracks.splice(0);
        let indexTrackToMove = -1;

        tracks.forEach((t, i) => {
          if (t.id === movement.trackId) {
            indexTrackToMove = i;
          }
        });

        if (indexTrackToMove >= 0) {
          if (movement.up && indexTrackToMove > 0) {
            tracks = move(tracks, indexTrackToMove, indexTrackToMove - 1);
          }
          else if (!movement.up && indexTrackToMove < tracks.length - 1) {
            tracks = move(tracks, indexTrackToMove, indexTrackToMove + 1);
          }
        }

        newState.tracks = tracks;
        return newState;
      }
      case USERPLAYLIST_SONG_MOVED_TOP_BOTTOM: {
        const movement = action.payload;

        const newState = Object.assign({}, state);
        let tracks = newState.tracks.splice(0);
        let indexTrackToMove = -1;

        tracks.forEach((t, i) => {
          if (t.id === movement.trackId) {
            indexTrackToMove = i;
          }
        });

        if (indexTrackToMove >= 0) {
          if (movement.top) {
            tracks = move(tracks, indexTrackToMove, 0);
          }
          else {
            tracks = move(tracks, indexTrackToMove, tracks.length - 1);
          }
        }

        newState.tracks = tracks;
        return newState;
      }
      case SESSION_SWITCH_SUCCESS as any: {
        return null;
      }
    }
    return state;
  },
  loading: (state = LoadingState.NOT_STARTED, action) => {
    switch (action.type) {
      case USERPLAYLIST_FETCH_SUCCESS: {
        return LoadingState.SUCCESS;
      }
      case USERPLAYLIST_FETCH_REQUEST: {
        return LoadingState.LOADING;
      }
      case SESSION_SWITCH_SUCCESS as any: {
        return LoadingState.NOT_STARTED;
      }
    }
    return state;
  },
});

function move(tracks: FullTrack[], indexOld: number, indexNew: number): FullTrack[] {
  const temp = tracks[indexOld];
  tracks.splice(indexOld, 1);
  tracks.splice(indexNew, 0, temp);

  return tracks;
}