import React from 'react';
import { Avatar, ListItemText, Grid, withTheme, Theme, ListItemAvatar } from '@material-ui/core';
import { Slider } from '@material-ui/core';
import styles from './DistributionListItem.module.css';
import autobind from 'autobind-decorator';
import { AppState } from '../../../redux/reducer';
import ProfilePicIllustration from '../../illustrations/ProfilePic';

export interface DistributionListItemProps {
  displayName: string;

  percentage: number;
  image: string;
  theme: Theme;
  sliderDisabled: boolean;
  onPercentageChange(percentage: number);
  onDragEnd();
}

export interface DistributionListItemState { }

class DistributionListItem extends React.Component<
  DistributionListItemProps,
  DistributionListItemState
  > {
  constructor(props: DistributionListItemProps) {
    super(props);
  }

  @autobind
  changeDistribution(event, value: any) {
    if (!this.props.sliderDisabled) {
      this.props.onPercentageChange(value / 100);
    }
  }

  @autobind
  onChangeCommitted(event, value: any) {
    if (!this.props.sliderDisabled) {
      this.props.onDragEnd();
    }
  }

  render() {
    return (
      <div className={styles.item}>
        <Grid container>
          <Grid item xs={6} className={styles.itemText}>
            {this.props.image !== '' ? (
              <Avatar src={this.props.image} />
            ) : (
                <div className={styles.illustration}>
                  <ProfilePicIllustration color={this.props.theme!.palette.primary.main} />
                </div>
              )}
            <div style={{ width: 16 }} />

            <ListItemText
              primary={this.props.displayName}
              secondary={Math.round(this.props.percentage * 100) + '%'}
            />
          </Grid>
          <Grid item xs={6} className={styles.splitSlider}>
            <Slider
              value={this.props.percentage * 100}
              disabled={this.props.sliderDisabled}
              aria-labelledby="label"
              onChange={this.changeDistribution}
              onChangeCommitted={this.onChangeCommitted}
              step={1}
              style={{ paddingRight: '8px' }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withTheme(DistributionListItem);
