import React, { Component } from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import ThemedContent from './ThemedContent';
import * as Sentry from '@sentry/browser';
import Button from '@material-ui/core/Button';

export class App extends Component {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontStyle: 'italic',
          fontSize: 32,
          padding: 32,
          boxSizing: 'border-box',
        }}
      >
        <div>
          <p>
            Just because something doesn’t do what you planned it to do doesn’t mean it’s useless.
          </p>
          <p style={{ fontStyle: 'normal', fontSize: 24, fontWeight: 'bold' }}>- Thomas Edison</p>
          <Button
            onClick={() => {
              window.location.href = 'https://jukify.de';
            }}
            variant="contained"
            color="primary"
            size="large"
            style={{ textTransform: 'none', marginTop: 32 }}
          >
            Visit Jukify.de
          </Button>
        </div>
      </div>
    );
  }
}

export default App;
