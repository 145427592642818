import { stringsDe, errorsDe } from './languages/de';
import { stringsEn, errorsEn } from './languages/en';

export interface Ii18nStrings {
  buttonLogin: string;
  labelUsername: string;
  labelPassword: string;
  menuPlaylist: string;
  menuSearch: string;
  menuManagement: string;
  menuSettings: string;
  lobbyEmptyTitle: string;
  lobbyEmptyMessage: string;
  lobbyTitle: string;
  lobbyMessage: string;
  lobbyJoinSessionButton: string;
  lobbyCreateSessionButton: string;
  lobbyScanQRButton: string;
  lobbySessionKey: string;
  lobbyChooseSession: string;
  lobbySwitchOrCreateSession: string;
  dashboardImportPlaylists: string;
  importTracksTitle: string;
  importTracksAccept: string;
  importTracksDecline: string;
  sessionManagePlayRate: string;
  newSessionDialogTitle: string;
  newSessionDialogMessage: string;
  newSessionDialogConfirm: string;
  newSessionDialogCancel: string;
  bottomNavHome: string;
  bottomNavUserPlaylist: string;
  bottomNavDistribution: string;
  bottomNavSessionSettings: string;
  appDrawerHome: string;
  appDrawerSessions: string;
  appDrawerSettings: string;
  appDrawerLogout: string;
  appBarTitleHome: string;
  appBarTitleLobby: string;
  appBarTitleUserPlaylist: string;
  appBarTitleUserDistribution: string;
  appBarTitleUserSettings: string;
  appBarTitleSessionSettings: string;
  leaveSessionDialogTitle: string;
  leaveSessionDialogMessage: string;
  leaveSessionDialogConfirm: string;
  leaveSessionDialogCancel: string;
  searchResultTracks: string;
  searchResultAlbums: string;
  searchResultPlaylists: string;
  searchResultMyPlaylists: string;
  searchInstructionsTitle: string;
  searchInstructionsMessage: string;
  searchNotFoundTitle: string;
  searchNotFoundMessage: string;
  search: string;
  searchAddTracks: string;
  searchCancel: string;
  sessionListNotStarted: string;
  defaultTheme: string;
  darkTheme: string;
  appLoadingMessage: string;
  userPlaylistLoadingMessage: string;
  userPlaylistShuffleDialogTitle: string;
  userPlaylistShuffleDialogMessage: string;
  userPlaylistShuffleDialogConfirm: string;
  userPlaylistShuffleDialogCancel: string;
  userPlaylistEmptyPlaylistDialogTitle: string;
  userPlaylistEmptyPlaylistDialogMessage: string;
  userPlaylistEmptyPlaylistDialogConfirm: string;
  userPlaylistEmptyPlaylistDialogCancel: string;
  playlistEmptyTitle: string;
  playlistEmptyMessage: string;
  playlistAddTracks: string;
  playlistBlocked: string;
  playlistPlayedRecently: string;
  dashboardPlaceholderTitleOwner: string;
  dashboardPlaceholderMessageOwner: string;
  dashboardPlaceholderTitle: string;
  dashboardPlaceholderMessage: string;
  sessionSettingsExcludeGenrePlaceholder: string;
  sessionSettingsExcludeGenreTitle: string;
  sessionSettingsExcludeGenreMessage: string;
  sessionSettingsJukeboxModeTitle: string;
  sessionSettingsJukeboxModeMessage: string;
  playlistTrackMoveTop: string;
  playlistTrackMoveBottom: string;
  playlistTrackMoveUp: string;
  playlistTrackMoveDown: string;
  playlistTrackRemove: string;
  speedDialActionAdd: string;
  speedDialActionShuffle: string;
  speedDialActionEmptyPlaylist: string;
  addDialogDeselectAll: string;
  addDialogSelectAll: string;
}

export interface Ii18nErrors {
  session: {
    create: string;
    join: string;
    leave: string;
  };
  spotify: {
    play: {
      deviceNotFound: string;
      notEnoughSongs: string;
      default: string;
    };
    pause: {
      default: string;
    };
  };
  search: {
    fetch: string;
  };
}

class LanguageService {
  public get strings(): Ii18nStrings {
    switch (navigator.language.split('-')[0].toLowerCase()) {
      case 'de':
        return stringsDe;
      default:
        return stringsEn;
    }
  }
  public get errors(): Ii18nErrors {
    switch (navigator.language.split('-')[0].toLowerCase()) {
      case 'de':
        return errorsDe;
      default:
        return errorsEn;
    }
  }
}

export const localisation = new LanguageService();
