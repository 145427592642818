import * as React from 'react';
import {
  List,
  AppBar as UIBar,
  IconButton,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
  Typography,
  SwipeableDrawer,
  withTheme,
  Theme,
  Toolbar,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import GroupIcon from '@material-ui/icons/Group';
import SettingsIcon from '@material-ui/icons/Settings';
import MemoryIcon from '@material-ui/icons/Memory';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SecurityIcon from '@material-ui/icons/Security';
import autobind from 'autobind-decorator';
import LogoText from '../../modules/logos/components/LogoText';
import styles from './Appbar.module.css';
import { store } from '../../redux/store';
import { push } from 'connected-react-router';
import { localisation } from '../../i18n/LanguageService';
import { logout } from '../../redux/actions/authentication.action';
import ProfilePicIllustration from '../../modules/illustrations/ProfilePic';
import { connect } from 'react-redux';
import { AppState } from '../../redux/reducer';
import { User } from '../../models/User';

import { currentSessionSelector } from '../../redux/selectors/session/currentSession.selector';
import { Session } from '../../models/Session';

export interface AppBarProps {
  location?: any;
  theme?: Theme;
  user?: User;
  currentSession?: Session;
}

export interface AppBarState {
  isDrawerOpen: boolean;
}

const mapStateToProps = (state: AppState) => {
  return {
    user: state.authentication.user || undefined,
    currentSession: currentSessionSelector(state),
  };
};

class AppBar extends React.Component<AppBarProps, AppBarState> {
  constructor(props: AppBarProps) {
    super(props);
    this.state = { isDrawerOpen: false };
  }

  @autobind
  private toggleDrawer() {
    this.setState({
      isDrawerOpen: !this.state.isDrawerOpen,
    });
  }

  @autobind
  navigate(route: string) {
    let absRoute = `/session`;
    if (route) {
      absRoute += '/' + route;
    }
    this.setState({ isDrawerOpen: false });
    store.dispatch(push(absRoute));
  }

  @autobind
  logout() {
    store.dispatch(logout());
    store.dispatch(push('/login'));
  }

  getAppBarTitle() {
    switch (this.props.location!.pathname) {
      case `/session`:
        return localisation.strings.appBarTitleHome;
      case `/session/sessions`:
        return localisation.strings.appBarTitleLobby;
      case `/session/split`:
        return localisation.strings.appBarTitleUserDistribution;
      case `/session/playlist`:
        return localisation.strings.appBarTitleUserPlaylist;
      case `/session/settings`:
        return localisation.strings.appBarTitleUserSettings;
      case `/session/sessionSettings`:
        return localisation.strings.appBarTitleSessionSettings;
      case `/session/qrcode`:
        return 'QR Code';
      case `/session/legal`:
        return 'Legal & Privacy';
    }
  }

  render() {
    return (
      <>
        <UIBar
          position="static"
          color="transparent"
          className={styles.appbar}
          elevation={0}
          style={{ color: this.props.theme!.palette.primary.main }}
        >
          <Toolbar>
            <IconButton color="inherit" aria-label="Menu" onClick={this.toggleDrawer}>
              <MenuIcon />
            </IconButton>

            <Typography
              variant="h6"
              color="inherit"
              className={[styles.grow, styles.noUserSelect].join(' ')}
            >
              {this.getAppBarTitle()}
            </Typography>
          </Toolbar>
        </UIBar>
        <SwipeableDrawer
          onOpen={() => this.setState({ isDrawerOpen: true })}
          onClose={() => this.setState({ isDrawerOpen: false })}
          open={this.state.isDrawerOpen}
          className="drawer"
          ModalProps={{ onBackdropClick: this.toggleDrawer }}
        >
          <div className={styles.drawerContent}>
            <div className={styles.userInfo}>
              <div
                className={styles.illustration}
                style={{
                  background: `linear-gradient(to right, ${
                    this.props.theme!.palette.primary.dark
                  }, ${this.props.theme!.palette.primary.main})`,
                }}
              ></div>
              <div className={styles.sessionInfo}>
                <Typography color="inherit" variant="subtitle2">
                  Session
                </Typography>
                <Typography color="inherit" variant="h6">
                  {this.props.currentSession ? this.props.currentSession.sessionId : ''}
                </Typography>
              </div>
              <div className={styles.drawerLogo}>
                <div className={styles.logo}>
                  <LogoText color="white" />
                </div>
              </div>

              <div className={styles.userInfoWrapper}>
                <div className={styles.defaultAvatar}>
                  <ProfilePicIllustration color={this.props.theme!.palette.primary.main} />
                </div>
                <div className={styles.userInfoTextWrapper}>
                  <p className={styles.userInfoText}>
                    {this.props.user ? this.props.user.display_name : ''}
                  </p>
                </div>
              </div>
            </div>

            <List>
              <ListItem button onClick={() => this.navigate('')}>
                <ListItemIcon>
                  <HomeIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={localisation.strings.appDrawerHome} />
              </ListItem>
              <ListItem button onClick={() => this.navigate('sessions')}>
                <ListItemIcon>
                  <GroupIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={localisation.strings.appDrawerSessions} />
              </ListItem>
              <ListItem button onClick={() => this.navigate('qrcode')}>
                <ListItemIcon>
                  <MemoryIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="QR Code" />
              </ListItem>
              <ListItem button onClick={() => this.navigate('settings')}>
                <ListItemIcon>
                  <SettingsIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={localisation.strings.appDrawerSettings} />
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem button onClick={() => this.navigate('legal')}>
                <ListItemIcon>
                  <SecurityIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Legal & Privacy" />
              </ListItem>
              <ListItem button onClick={this.logout}>
                <ListItemIcon>
                  <ArrowBackIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={localisation.strings.appDrawerLogout} />
              </ListItem>
            </List>
          </div>
        </SwipeableDrawer>
      </>
    );
  }
}

export default withTheme(connect(mapStateToProps)(AppBar));
