import React from 'react';

export interface QueueIllustrationProps {
  color: string;
}

export interface QueueIllustrationState {}

function QueueIllustration(props: QueueIllustrationProps) {
  return (
    <svg
      id="daff031e-6a8c-4088-9f26-b9cdb1850187"
      data-name="Layer 1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 905.75 574"
    >
      <defs>
        <linearGradient
          id="e213e05c-63fe-4364-900d-fe7549d896d2"
          x1="1016.02"
          y1="690.34"
          x2="1016.02"
          y2="362.35"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="gray" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
          <stop offset="1" stopColor="gray" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="14bdc54d-534f-4928-9f3e-47d86a640297"
          x1="889.74"
          y1="689.71"
          x2="889.74"
          y2="190.13"
          xlinkHref="#e213e05c-63fe-4364-900d-fe7549d896d2"
        />
        <linearGradient
          id="5f1ffd26-0c81-4b65-8186-c7c520ab7d59"
          x1="351.33"
          y1="706.35"
          x2="351.33"
          y2="198.91"
          xlinkHref="#e213e05c-63fe-4364-900d-fe7549d896d2"
        />
        <linearGradient
          id="d357dd14-bd45-4fbe-9a53-ea26fa1d369a"
          x1="714.73"
          y1="737"
          x2="714.73"
          y2="163"
          xlinkHref="#e213e05c-63fe-4364-900d-fe7549d896d2"
        />
        <linearGradient
          id="1a4786fb-df01-4110-af9f-6a367a2e53de"
          x1="542.25"
          y1="701.25"
          x2="542.25"
          y2="216.46"
          xlinkHref="#e213e05c-63fe-4364-900d-fe7549d896d2"
        />
        <linearGradient
          id="a7defa4f-9564-416e-8ed2-e91fc3fa82a6"
          x1="596.32"
          y1="543.36"
          x2="596.32"
          y2="401.7"
          xlinkHref="#e213e05c-63fe-4364-900d-fe7549d896d2"
        />
        <linearGradient
          id="5a563cc2-1281-4323-9b9c-e9fa5d90f13d"
          x1="183.98"
          y1="690.34"
          x2="183.98"
          y2="362.35"
          xlinkHref="#e213e05c-63fe-4364-900d-fe7549d896d2"
        />
      </defs>
      <title>queue</title>
      <g opacity="0.5">
        <path
          d="M1031.15,653.49h-4.81V400.84a20.64,20.64,0,1,0-20.64,0V653.49h-4.81a21.72,21.72,0,0,0-21.72,21.72v15.13h73.71V675.21A21.72,21.72,0,0,0,1031.15,653.49Z"
          transform="translate(-147.13 -163)"
          fill="url(#e213e05c-63fe-4364-900d-fe7549d896d2)"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M967.15,672c-7.47-4.42-11.41-19.2-12.59-24.41l-8.91-115.11s-6.69-38.1-32.1-92.37c-.31-32.31-1.5-134.48-4.39-145.47l-16.84-20.47a40.43,40.43,0,0,1,2.65-4.57c.3-.08.61-.15.91-.24.37-.49.76-1,1.16-1.41a27.88,27.88,0,0,0,14.48-41c4.15-3.05-1.14-4.34-1.14-4.34h0a27.89,27.89,0,0,0-31.57-21.41,15.14,15.14,0,0,0-14.56-11l-.86,0A15.15,15.15,0,0,1,877,200.75a15.15,15.15,0,0,0-9.56-7.1,15.08,15.08,0,0,0-9.7-3.52l-.86,0a15.06,15.06,0,0,1,8.38,3.14c-.44,0-.88-.07-1.32-.07a15.13,15.13,0,0,0-7.65,28.2,27.88,27.88,0,0,0,1.49,18.68c-.57,8,1.17,18.19,11.88,23.35l-1.82,4.12c-2.19.11-20,2.27-31.46,42.69-4.93,10.13-8.65,26.52-8.4,53-.48,6.78-.84,14-1,21.81l-8.26,34.08.12.2c0,.16,0,.31,0,.47l-.09.36.06.09c-2,27.21-1,54.92,4.5,81.63,3.12,15.12,5.53,30.29,4,35.65-3.3,12-15,55.74-13.31,69.18l-3.87,23.51.26.09-14.59,27.71s9,8.24,12.55,8.42,7.64,6.22,7.64,6.22,20.95,16.19,28.07,11.18-2.68-11-9.38-19.17c-5.49-6.73-4.09-22-3.4-27.25l31.33-111.12s3.11-17.29,3.57-46.82a25,25,0,0,0,8.06,4.33,5.06,5.06,0,0,0,3,0l2,7.24s36.14,51.63,37.17,64,5.16,57.48,11.36,69.53l4.47,23.41h.27l-4.14,31s11.3,4.63,14.68,3.58,9.32,3.21,9.32,3.21,25.25,8,30.2.81S976.27,677.36,967.15,672Z"
          transform="translate(-147.13 -163)"
          fill="url(#14bdc54d-534f-4928-9f3e-47d86a640297)"
        />
      </g>
      <path
        d="M903.83,428.56l9,20.92s-1-137.16-4.32-149.78l-21.59-26.24-7.31,19.26,16.61,20.59s10.3,30.55,8.63,60.44S903.83,428.56,903.83,428.56Z"
        transform="translate(-147.13 -163)"
        fill="#3ad29f"
      />
      <path
        d="M903.83,428.56l9,20.92s-1-137.16-4.32-149.78l-21.59-26.24-7.31,19.26,16.61,20.59s10.3,30.55,8.63,60.44S903.83,428.56,903.83,428.56Z"
        transform="translate(-147.13 -163)"
        fill="#381e1d"
      />
      <path
        d="M902.83,428.56l9,20.92s-1-137.16-4.32-149.78L885.9,273.46l-7.31,19.26,16.61,20.59s10.3,30.55,8.63,60.44S902.83,428.56,902.83,428.56Z"
        transform="translate(-147.13 -163)"
        fill="#333"
      />
      <path
        d="M821.3,419.43c-2.09,26.76-1.19,54.05,4.23,80.35,3,14.59,5.33,29.22,3.91,34.4-3.19,11.57-14.45,53.78-12.85,66.75l-3.74,22.69,20.26,7.44,30.37-107.72s7.83-43.47.08-110l-42.27,6.1Z"
        transform="translate(-147.13 -163)"
        fill={props.color}
      />
      <path
        d="M814,622l-15,28.39s8.69,7.95,12.11,8.13,7.37,6,7.37,6,20.21,15.62,27.09,10.78-2.58-10.57-9-18.5-3-28.1-3-28.1Z"
        transform="translate(-147.13 -163)"
        fill="#381e1d"
      />
      <path
        d="M879.59,489.33s34.87,49.82,35.87,61.77,5,55.46,11,67.09l4.32,22.58h21.59l-8.63-111.59S936,485.68,905.82,425.9l-38.19,20.92Z"
        transform="translate(-147.13 -163)"
        fill={props.color}
      />
      <path
        d="M906.32,434c0,2.66-30.55,0-30.55,0V304.52l13.95-20.59s5.36,5.76,10.59,21.51c2,6.09,6.35,17.33,8,26.65,1,5.66-2.68,13.51-2,20.59C908.74,377.61,911,404.87,906.32,434Z"
        transform="translate(-147.13 -163)"
        fill="#fff"
      />
      <path
        d="M888.06,291.23s3.16-13.45,10.46-20.09l-25.9-6.64-6.31,14.28Z"
        transform="translate(-147.13 -163)"
        fill="#a3643b"
      />
      <path
        d="M868.79,274.63S832.1,273.8,829.11,388l-8,32.88s64.43,105.61,77.38,19.93c0,0,7-93.32-6.64-124.87L898.52,300Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <path
        d="M868.79,273.63S832.1,272.8,829.11,387l-8,32.88s64.43,105.61,77.38,19.93c0,0,7-93.32-6.64-124.87L898.52,299Z"
        transform="translate(-147.13 -163)"
        fill="#333"
      />
      <path
        d="M854,439.85s-2.36,35.09,20.7,42.47a4.93,4.93,0,0,0,6.42-4.38c.39-6-1.52-18.25-12.51-43.07Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <path
        d="M855.34,439.19S853,474.27,876,481.66a4.93,4.93,0,0,0,6.42-4.38c.39-6-1.52-18.25-12.51-43.07Z"
        transform="translate(-147.13 -163)"
        fill="#a3643b"
      />
      <circle cx="747.74" cy="172.57" r="1.33" fill="#fa9221" />
      <circle cx="747.74" cy="222.38" r="1.33" fill="#fa9221" />
      <path
        d="M931.24,638.92,927,670.72s10.9,4.46,14.17,3.45,9,3.09,9,3.09,24.36,7.7,29.14.78-6.07-9-14.87-14.24-12.53-25.33-12.53-25.33Z"
        transform="translate(-147.13 -163)"
        fill="#381e1d"
      />
      <path
        d="M859.66,299.37s-33.21-6.64-29.23,77.71c0,0,8,61.77,21.92,65.76l20.92-5.31L865,379.74l8.3-29.89S879.92,304.68,859.66,299.37Z"
        transform="translate(-147.13 -163)"
        fill="#daa4a2"
        opacity="0.1"
      />
      <path
        d="M862.65,299.37s-33.21-6.64-29.23,77.71c0,0,8,61.77,21.92,65.76l20.92-5.31L868,379.74l8.3-29.89S882.91,304.68,862.65,299.37Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <path
        d="M861,299.37s-33.21-6.64-29.23,77.71c0,0,8,61.77,21.92,65.76l20.92-5.31-8.3-57.79,8.3-29.89S881.25,304.68,861,299.37Z"
        transform="translate(-147.13 -163)"
        fill="#333"
      />
      <polygon
        points="708.15 281.56 708.15 279.43 724.59 275.26 725.35 277.05 708.15 281.56"
        opacity="0.1"
      />
      <polygon
        points="709.38 122.92 743.92 152.81 744.91 152.14 710.04 122.59 709.38 122.92"
        opacity="0.1"
      />
      <polygon
        points="751.77 272.04 751.67 273.7 758.41 272.87 758.06 272.04 751.77 272.04"
        opacity="0.1"
      />
      <path
        d="M869.79,268.65c4.68,3.95,11.5,7.8,18.1,7.8a26.89,26.89,0,0,0,7.77-1.14,22.92,22.92,0,0,1,2.86-3.17l-25.9-6.64Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <circle cx="736.28" cy="73.1" r="26.9" fill="#fdd835" />
      <circle cx="740.76" cy="85.56" r="26.9" fill="#a3643b" />
      <path
        d="M861.15,236.1s-10.07,26.38,11.24,34.44h0a12.61,12.61,0,0,0,3.14-20.17Z"
        transform="translate(-147.13 -163)"
        fill="#fdd835"
      />
      <circle cx="717.68" cy="53.51" r="14.61" fill="#fdd835" />
      <path
        d="M909.65,230.2s9.29,2.25-7,7.9a20.59,20.59,0,0,0-12.19,11,12.65,12.65,0,0,1-17.53,5.78L867.8,252l-9-20.92,10.63-12.29,18.27-2.32Z"
        transform="translate(-147.13 -163)"
        fill="#fdd835"
      />
      <path
        d="M878.09,213.52a14.61,14.61,0,0,0-13.78-14.59l.83,0a14.61,14.61,0,0,1,0,29.23l-.83,0A14.61,14.61,0,0,0,878.09,213.52Z"
        transform="translate(-147.13 -163)"
        fill="#fdd835"
      />
      <path
        d="M871.78,213.52A14.61,14.61,0,0,0,858,198.93l.83,0a14.61,14.61,0,1,1,0,29.23l-.83,0A14.61,14.61,0,0,0,871.78,213.52Z"
        transform="translate(-147.13 -163)"
        fill="#fdd835"
      />
      <ellipse cx="733.62" cy="94.36" rx="2.66" ry="4.65" fill="#a3643b" />
      <g opacity="0.5">
        <path
          d="M464.39,688c-7.73-4.58-11.8-19.86-13-25.25l-9.21-119.06s-6.92-39.4-33.19-95.53c-.32-33.42-1.55-139.09-4.54-150.46l-17.77-21.6a40.78,40.78,0,0,1,2.91-4.4h0l4.54,12.83,16.38-1.78-8.84-16.9a29.53,29.53,0,0,0,5.55-40,7.27,7.27,0,0,0-.31-9.22,37.79,37.79,0,0,0-9.7-8.06,34.52,34.52,0,0,0-50.36,47.09,7.54,7.54,0,0,1-3.44,2.21c-6.2,1.74-11.14,6.13-14.35,9.76a6,6,0,0,0,1.25,9l2.68,1.71a6,6,0,0,0,7-.43l5.55-4.58a.44.44,0,0,1,.6.64,5.39,5.39,0,0,0,2.62,8.91l.11,0-.12.06.15.13c-5,5-10.12,12.22-14.73,22.84-7.41,9-14.18,27.3-13.7,64q-.79,10-1.1,21.44l-8.54,35.24S244.93,548,261.31,564c0,0,9.86,1.76,23.75,5.43l-39.42,52.24.54.52-25.11,18.35s4.63,11.75,7.83,13.53,4.27,9.26,4.27,9.26,12.1,24.56,21,23.14,2.49-11.39,0-22.07c-1.94-8.31,5.39-20.92,8.74-26.12l.88.84s46.79-35.16,64.7-54.82c9.8,4.35,19.15,9.44,26.79,15.3,0,0,13.45-32.76,26.07-87l0,0,.11.17c11.05,16.49,29.65,45.43,30.4,54.41,1.07,12.82,5.34,59.45,11.75,71.91l4.63,24.21h.28l-4.29,32.1s11.69,4.78,15.19,3.7,9.64,3.32,9.64,3.32,26.11,8.25,31.24.84S473.83,693.58,464.39,688Z"
          transform="translate(-147.13 -163)"
          fill="url(#5f1ffd26-0c81-4b65-8186-c7c520ab7d59)"
        />
      </g>
      <circle cx="225.2" cy="79.92" r="33.04" />
      <path
        d="M293.13,557.46l-42.92,56.88L267.59,631s60.17-45.2,67.69-60.11a6,6,0,0,0,.77-2.56C336,557.12,293.13,557.46,293.13,557.46Z"
        transform="translate(-147.13 -163)"
        fill={props.color}
      />
      <path
        d="M372.49,498.88s35.76,51.09,36.79,63.35,5.11,56.88,11.24,68.8l4.43,23.16h22.14l-8.86-114.44s-7.83-44.62-38.83-105.93l-39.17,21.46Z"
        transform="translate(-147.13 -163)"
        fill={props.color}
      />
      <rect x="221.45" y="146.33" width="31.34" height="132.84" fill="#fff" />
      <path
        d="M396.33,436.55l9.2,21.46s-1-140.67-4.43-153.61L379,277.49l-7.49,19.75,17,21.12s10.56,31.34,8.86,62S396.33,436.55,396.33,436.55Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <polygon points="249.21 104.95 260.79 127.09 245.12 128.79 237.29 106.65 249.21 104.95" />
      <circle cx="234.56" cy="88.94" r="28.27" fill="#fda57d" />
      <path
        d="M381.69,291.45a39.35,39.35,0,0,1,10.22-16.35l-26.57-6.81-6.47,14.65Z"
        transform="translate(-147.13 -163)"
        fill="#fda57d"
      />
      <path
        d="M369.77,280.21s-46-3.41-49,113.76l-8.17,33.72s-63,116.15-47.34,131.47c0,0,59.26,10.56,89.92,34.06,0,0,23.5-57.22,36.79-145.1,0,0,7.15-95.71-6.81-128.07l6.81-16.35Z"
        transform="translate(-147.13 -163)"
        fill="#3ad29f"
      />
      <path
        d="M397.36,436.55l9.2,21.46s-1-140.67-4.43-153.61L380,277.49l-7.49,19.75,17,21.12s10.56,31.34,8.86,62S397.36,436.55,397.36,436.55Z"
        transform="translate(-147.13 -163)"
        fill="#3ad29f"
      />
      <path
        d="M397.36,436.55l9.2,21.46s-1-140.67-4.43-153.61L380,277.49l-7.49,19.75,17,21.12s10.56,31.34,8.86,62S397.36,436.55,397.36,436.55Z"
        transform="translate(-147.13 -163)"
        opacity="0.05"
      />
      <path
        d="M346.26,448.13s-2.42,36,21.23,43.56a5.05,5.05,0,0,0,6.58-4.49c.4-6.12-1.56-18.72-12.83-44.18Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <path
        d="M347.63,447.45s-2.42,36,21.23,43.56a5.05,5.05,0,0,0,6.58-4.49c.4-6.12-1.56-18.72-12.83-44.18Z"
        transform="translate(-147.13 -163)"
        fill="#fda57d"
      />
      <circle cx="241.03" cy="178.18" r="1.36" fill="#4d8af0" />
      <circle cx="241.03" cy="229.27" r="1.36" fill="#4d8af0" />
      <path
        d="M253.28,613l-26.57,19.41s4.43,11.24,7.49,12.94,4.09,8.86,4.09,8.86,11.58,23.5,20.1,22.14,2.38-10.9,0-21.12S268.27,628,268.27,628Z"
        transform="translate(-147.13 -163)"
      />
      <path
        d="M425.47,652.29l-4.36,32.62s11.18,4.58,14.53,3.54,9.22,3.17,9.22,3.17,25,7.89,29.89.8-6.22-9.26-15.25-14.61-12.85-26-12.85-26Z"
        transform="translate(-147.13 -163)"
      />
      <path
        d="M352.05,304.05s-34.06-6.81-30,79.7c0,0,8.17,63.35,22.48,67.44L366,445.74l-8.52-59.26L366,355.82S372.83,309.5,352.05,304.05Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <path
        d="M355.12,304.05s-34.06-6.81-30,79.7c0,0,8.17,63.35,22.48,67.44l21.46-5.45-8.52-59.26,8.52-30.65S375.9,309.5,355.12,304.05Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <path
        d="M353.42,304.05s-34.06-6.81-30,79.7c0,0,8.17,63.35,22.48,67.44l21.46-5.45-8.52-59.26,8.52-30.65S374.19,309.5,353.42,304.05Z"
        transform="translate(-147.13 -163)"
        fill="#3ad29f"
      />
      <polygon
        points="200.43 289.95 200.43 287.78 217.29 283.5 218.07 285.33 200.43 289.95"
        opacity="0.1"
      />
      <polygon
        points="201.69 127.26 237.12 157.91 238.14 157.23 202.38 126.92 201.69 127.26"
        opacity="0.1"
      />
      <polygon
        points="211.74 126.92 231.67 123.17 249.04 146.33 238.99 152.4 211.74 126.92"
        fill="#3ad29f"
      />
      <path
        d="M404.31,236.21,391.39,249.9v8.39a42.14,42.14,0,0,1-3.15,16l-8.68,21.16a5.7,5.7,0,0,1-7.38,3.14l-7.31-2.9a5.7,5.7,0,0,1-3.12-7.6l2.05-4.68a.42.42,0,0,0-.73-.41l-4.17,5.94a5.7,5.7,0,0,1-6,2.25l-4-1a5.15,5.15,0,0,1-2.51-8.53h0a.42.42,0,0,0-.58-.61l-5.31,4.38a5.7,5.7,0,0,1-6.69.41l-2.56-1.63a5.71,5.71,0,0,1-1.2-8.61c3.07-3.47,7.79-7.67,13.73-9.34,6.23-1.75,8.45-12.73,9.22-21.83a31.14,31.14,0,0,1,20-26.73c9.28-3.44,20.68-3.16,31.54,9.11A7,7,0,0,1,404.31,236.21Z"
        transform="translate(-147.13 -163)"
      />
      <polygon
        points="245.17 279.17 245.06 280.88 251.98 280.02 251.62 279.17 245.17 279.17"
        opacity="0.1"
      />
      <path
        d="M392.08,443.87h2.55s-7.1,65.71-14,66.91l-.54-.8S391.39,456.47,392.08,443.87Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <g opacity="0.5">
        <path
          d="M797.74,326.2l2.8-5.51-30-15.22-.73,1.45-.89-.45-4.3,8.47c-.72-4-1.57-7.73-2.46-11.19-1.39-8-3-15.5-4.54-21.88-.06-.55-.12-1.1-.18-1.62-2.52-22.36-16-32.85-26.06-37.63a36.82,36.82,0,0,0,27-51.57c3.51-2.89,4.45-7.74.53-15.58,0,0-68.41-36-80.08,14.38a54.35,54.35,0,0,0,1.68,29.95,62,62,0,0,0,8,15.76c-17.61,2.95-49,11.57-57,36.05-8.91,27.08-3.85,78.44-1.25,99.4a9.73,9.73,0,0,0,1.61,4.27,8.38,8.38,0,0,0,1.92,2.9V428l.91-.25v1.16s.41-.1,1.15-.32c1,46.85,7.42,120.15,7.91,125.69v-.12l0,.23c-5,39.55-10,130-10,130h0v.23l0-.23h10v37.18a4.66,4.66,0,0,0,2.52,4.14c4.9,2.52,15.72,8,21.57,10.05,7.73,2.73,25.91,0,25.91,0,.91-4.55-13.64-8.64-13.64-8.64l-17.28-19.07V684.41h3.64l0-.05,0,.28C678.68,672.93,710,453,710.61,448.75l-7.26,217.93h0v.23l0-.23h5.43V701.9a4.9,4.9,0,0,0,2.65,4.36c5,2.55,15.64,7.94,21.44,10,7.73,2.73,25.91,0,25.91,0,.91-4.55-13.64-8.64-13.64-8.64L727.9,688.53V666.68h6.82c5.74-23,15.34-193,18.24-246.34,4.61,3.14,8.13,2.65,8.13,2.65v-.43a6.65,6.65,0,0,0,.78,0,6,6,0,0,0,1,0s-.22-17.13-.75-40l10.71-9.87.14.07.19-.37.95-.87.49.25.66-1.31L788.82,358l0,0,.93-.86-1.25-2.39c7.43-4.62,11.73-13,14.15-20.09A6.43,6.43,0,0,0,797.74,326.2Z"
          transform="translate(-147.13 -163)"
          fill="url(#d357dd14-bd45-4fbe-9a53-ea26fa1d369a)"
        />
      </g>
      <polygon
        points="580.18 488.32 580.18 524.36 561.94 524.36 561.94 484.41 580.18 488.32"
        fill="#fec9b1"
      />
      <path
        d="M727.09,677.59,743.81,696s13.9,3.91,13,8.25c0,0-17.37,2.61-24.75,0-5.55-2-15.75-7.1-20.48-9.54a4.68,4.68,0,0,1-2.53-4.16V677.37Z"
        transform="translate(-147.13 -163)"
        fill="#381e1d"
      />
      <polygon
        points="518.08 506.99 518.08 543.03 499.84 543.03 499.84 503.08 518.08 506.99"
        fill="#fec9b1"
      />
      <path
        d="M665,696.26l16.72,18.46s13.9,3.91,13,8.25c0,0-17.37,2.61-24.75,0-5.6-2-15.93-7.19-20.61-9.6a4.45,4.45,0,0,1-2.41-4V696Z"
        transform="translate(-147.13 -163)"
        fill="#381e1d"
      />
      <path
        d="M752.06,406.4S740.33,630.91,733.82,657h-30l6.95-208.44s-30.4,214.09-42.12,225.38H637.42s4.78-86.42,9.55-124.2c0,0-13-145.91-5.21-156.77H749Z"
        transform="translate(-147.13 -163)"
        fill="#4d8af0"
      />
      <g opacity="0.1">
        <path
          d="M650.44,514.31c-1.19,9.43-2.38,21.89-3.5,35.28-.67-7.53-12.79-145.88-5.18-156.44h1.08C643.78,439.64,650.44,514.31,650.44,514.31Z"
          transform="translate(-147.13 -163)"
        />
        <path d="M637.42,674.12s4.67-84.4,9.39-122.85" transform="translate(-147.13 -163)" />
        <path
          d="M714.28,413.13l-6.95,208.44-3.47,35.61,6.95-208.44s-30.4,214.09-42.12,225.38"
          transform="translate(-147.13 -163)"
        />
      </g>
      <path
        d="M773.34,348.21a53.16,53.16,0,0,1,16.4-15.69,6.13,6.13,0,0,1,8.95,7.25c-3.67,10.78-11.81,24.49-29.26,22.34Z"
        transform="translate(-147.13 -163)"
        fill="#fec9b1"
      />
      <path
        d="M752.93,289.15s.39.85,1,2.41c3.27,8.1,13.12,35.35,9.84,62.29l12.16-12.59,10.42,20-33.44,30.83Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <path
        d="M747.93,270.26s2.41,1,3,2.61c3.27,8.1,15.22,54.92,11.94,81.86l12.16-12.59,10.42,20-33.44,30.83Z"
        transform="translate(-147.13 -163)"
        fill={props.color}
      />
      <polygon
        points="581.92 92.28 596.68 236.89 523.73 236.89 542.4 94.89 581.92 92.28"
        fill="#333"
      />
      <path
        d="M719.92,248.33s31.7,4.78,35.61,39.52,5.21,135.92,5.21,135.92S733,427.68,732.52,341.7,719.92,248.33,719.92,248.33Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <path
        d="M718.19,248.77s31.7,4.78,35.61,39.52S759,424.21,759,424.21s-27.79,3.91-28.23-82.07S718.19,248.77,718.19,248.77Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <path
        d="M719.06,248.33s31.7,4.78,35.61,39.52,5.21,135.92,5.21,135.92-27.79,3.91-28.23-82.07S719.06,248.33,719.06,248.33Z"
        transform="translate(-147.13 -163)"
        fill={props.color}
      />
      <rect
        x="752.37"
        y="317.2"
        width="32.13"
        height="54.72"
        transform="translate(1150.65 836.56) rotate(-153.09)"
        fill="#f5f5f5"
      />
      <rect
        x="753.92"
        y="316.25"
        width="32.13"
        height="54.72"
        transform="translate(1154.01 835.46) rotate(-153.09)"
        fill="#e0e0e0"
      />
      <path
        d="M731.65,351.68a52.86,52.86,0,0,1,19.44-17.36A4.89,4.89,0,0,1,758,340c-3.08,11-11,28-30.27,25.58Z"
        transform="translate(-147.13 -163)"
        fill="#fec9b1"
      />
      <path
        d="M702.12,223.58s-2.61,14.76-12.59,20l3,17.8L713,279.6l10.64-22.8-3.26-11.94Z"
        transform="translate(-147.13 -163)"
        fill="#fec9b1"
      />
      <path
        d="M638.28,372.09v57.76s66-16.5,62.53-90.32-6-94.14-6-94.14L646.1,300.44Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <path
        d="M637.42,371.23V429s66-16.5,62.53-90.32-6-94.14-6-94.14l-48.72,55.06Z"
        transform="translate(-147.13 -163)"
        fill={props.color}
      />
      <path
        d="M720.79,246.6l2.05,7.53a35.12,35.12,0,0,1-26.44-14.52,38,38,0,0,0,6.14-14.3Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <circle cx="577.36" cy="54.28" r="35.17" fill="#fec9b1" />
      <path
        d="M636.11,280.47c-8.69,26.43-3.47,77.07-1,96.21a8,8,0,0,0,8.58,7l93.64-7.23V346l-66.44-1.3L680,286.11S647,247.46,636.11,280.47Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <path
        d="M701.33,243.76s-55.23,2.84-66.08,35.84c-8.51,25.86-3.68,74.92-1.2,94.94a9.35,9.35,0,0,0,10,8.16l92.38-7.14v-30.4L670,343.87l9.12-58.62Z"
        transform="translate(-147.13 -163)"
        fill={props.color}
      />
      <path
        d="M757,187.78s-65.34-34.39-76.49,13.73a51.92,51.92,0,0,0,1.6,28.6c3.4,9.87,10.28,22.5,24,29l2.72-30.56h1.13a8.37,8.37,0,0,0,8.37-8.37V203.39S771.23,216.29,757,187.78Z"
        transform="translate(-147.13 -163)"
        fill="#381e1d"
      />
      <ellipse cx="563.46" cy="67.09" rx="3.47" ry="6.73" fill="#fec9b1" />
      <circle
        cx="775.29"
        cy="333.15"
        r="3.47"
        transform="translate(-19.76 710.76) rotate(-63.09)"
        fill="#f5f5f5"
      />
      <g opacity="0.5">
        <path
          d="M586.93,454.19c-.06-11.18-6.85-21.86-11.71-28.06l2.35-3.2-6.8-40.78s-.35-3.91-.4-10.77A184.68,184.68,0,0,0,572,341.82c.48-4.43,1.1-9.13,1.88-14.05,2.58-16.15-2.93-26.22-9-32.23a34,34,0,0,0-12.75-8.22l-.12,0c.38-1.29.76-2.33,1.1-3.17.18-.4.33-.68.42-.86a27.53,27.53,0,0,0,16.22-41.08c4.1-3-1.12-4.28-1.12-4.28h0a27.53,27.53,0,1,0-52,17.33c-.58,8.28,1.33,18.87,13.23,23.7l0,0,.47.34a34.48,34.48,0,0,1-11.77,6.53c-7.41.84-17.4,6.91-25.24,29.67-7.9,22.94-9.66,56.59-9.65,82.09,0,17.9.82,31.95,1.08,35.8l-.95,0C472.56,466.77,498,555.81,498,555.81l-3.74,110.12h1.88l-3.32,24.87s11.16,4.57,14.5,3.53,9.2,3.17,9.2,3.17,24.93,7.88,29.82.8c1.86-2.69,1.4-4.68-.37-6.39,7.66,1.86,20.76,4.18,24.16-.75,4.9-7.08-6.21-9.24-15.22-14.58-5.89-3.49-9.56-13.49-11.39-20h1.89l23.11-114.87V467q1.8.89,3.4,1.58V700.09h10.2V471.78a5,5,0,0,0,5.71-5.51c-.08-.75-.19-1.48-.32-2.19,3.25,1.61,5.84,7,7.18,11,2,6.12,8.84-6.46,8.84-6.46C598.09,457.8,592.16,454.34,586.93,454.19Z"
          transform="translate(-147.13 -163)"
          fill="url(#1a4786fb-df01-4110-af9f-6a367a2e53de)"
        />
      </g>
      <rect x="423.77" y="299.06" width="9.83" height="229.29" fill="#381e1d" />
      <path
        d="M570.89,462.06s16.71-21,30.46,6.22c0,0-6.55,12.12-8.52,6.22s-6.67-14.71-12.18-9.48"
        transform="translate(-147.13 -163)"
        fill="#381e1d"
      />
      <path
        d="M569.42,422S594.13,446.34,582,466.9a4.86,4.86,0,0,1-7.6,1c-4.2-4.15-10.83-14.46-19-40Z"
        transform="translate(-147.13 -163)"
        fill="#fda57d"
      />
      <path
        d="M552.06,293.86s25.55,7.86,20.64,38.65-2.95,52.41-2.95,52.41l6.55,39.31L564.5,440.28S544.2,420,537.32,399.33,531.09,297.13,552.06,293.86Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <path
        d="M551.73,293.53s25.55,7.86,20.64,38.65-2.95,52.41-2.95,52.41L576,423.9,564.18,440S543.87,419.64,537,399,530.77,296.81,551.73,293.53Z"
        transform="translate(-147.13 -163)"
        fill={props.color}
      />
      <path
        d="M521.77,644.16l-4.19,31.37s10.75,4.4,14,3.41,8.87,3.05,8.87,3.05,24,7.59,28.74.77-6-8.91-14.67-14-12.35-25-12.35-25Z"
        transform="translate(-147.13 -163)"
      />
      <path
        d="M567.62,422.75v116L545.34,649.43H521.76l3.6-106.13s-24.57-85.82-13.76-117.92Z"
        transform="translate(-147.13 -163)"
        fill="#333"
      />
      <path
        d="M549.45,437.38s34.34,4.86,36.78,28.62a4.86,4.86,0,0,1-5.53,5.3c-5.84-.84-17.31-5.18-39-20.84Z"
        transform="translate(-147.13 -163)"
        fill="#fda57d"
      />
      <path
        d="M553.7,288.29s-5.57,9.5-3.28,25.88c0,0-32.92-2.78-31.94-21.78,0,0,16.05-3.93,19.82-18.51Z"
        transform="translate(-147.13 -163)"
        fill="#fda57d"
      />
      <path
        d="M498.84,651l-4.19,31.37s10.75,4.4,14,3.41,8.87,3.05,8.87,3.05,24,7.59,28.74.77-6-8.91-14.67-14-12.35-25-12.35-25Z"
        transform="translate(-147.13 -163)"
      />
      <path
        d="M542.89,431.76v116L520.61,658.43H497l3.6-106.13s-24.57-85.82-13.76-117.92Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <path
        d="M541.9,431.76v116L519.63,658.43H496l3.6-106.13s-24.57-85.82-13.76-117.92Z"
        transform="translate(-147.13 -163)"
        fill="#333"
      />
      <path
        d="M530.11,294.19s-20.64-13.43-34.39,26.53-8.19,113.66-8.19,113.66,58.63,19,79.6-7.53V387.21s11.14-45.2-4.59-84.51C562.54,302.7,548.78,308.27,530.11,294.19Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <path
        d="M529.46,295.17s-20.64-13.43-34.39,26.53-8.19,113.66-8.19,113.66,58.63,19,79.6-7.53V388.2s11.14-45.2-4.59-84.51C561.88,303.68,548.13,309.25,529.46,295.17Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <path
        d="M529.46,294.19s-20.64-13.43-34.39,26.53-8.19,113.66-8.19,113.66,58.63,19,79.6-7.53V387.21s11.14-45.2-4.59-84.51C561.88,302.7,548.13,308.27,529.46,294.19Z"
        transform="translate(-147.13 -163)"
        fill={props.color}
      />
      <path
        d="M508.49,314.17S534,322,529.13,352.82s-2.95,52.41-2.95,52.41l32.76,33.08-11.79,16.05s-46.51-14.09-53.39-34.72S487.53,317.44,508.49,314.17Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <path
        d="M506.85,315.15S532.4,323,527.49,353.8s-2.95,52.41-2.95,52.41L557.3,439.3l-11.79,16.05S499,441.26,492.11,420.62,485.89,318.43,506.85,315.15Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <path
        d="M507.51,314.17s25.55,7.86,20.64,38.65-2.95,52.41-2.95,52.41L558,438.31l-11.79,16.05s-46.51-14.09-53.39-34.72S486.55,317.44,507.51,314.17Z"
        transform="translate(-147.13 -163)"
        fill={props.color}
      />
      <path
        d="M537.81,275l15.4,14.41a15.38,15.38,0,0,0-.68,1.4,26.56,26.56,0,0,1-6.85.9c-5.88,0-11-3-15.4-6.22C533.35,282.73,536,280,537.81,275Z"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <circle cx="394.61" cy="88.77" r="26.53" fill="#e0e0e0" />
      <circle cx="399.04" cy="101.05" r="26.53" fill="#fda57d" />
      <path
        d="M519.79,251.77s-9.94,26,11.08,34h0a12.43,12.43,0,0,0,3.1-19.9Z"
        transform="translate(-147.13 -163)"
        fill="#e0e0e0"
      />
      <path
        d="M567.63,245.94s9.16,2.22-6.89,7.79a20.31,20.31,0,0,0-12,10.85,12.48,12.48,0,0,1-17.29,5.7l-5.08-2.79-8.84-20.64L528,234.73l18-2.29Z"
        transform="translate(-147.13 -163)"
        fill="#e0e0e0"
      />
      <ellipse cx="391.99" cy="109.73" rx="2.62" ry="4.59" fill="#fda57d" />
      <path
        d="M530.11,294.19s17.85,28,28,28S551,295.93,551,295.93l-.41,7.26Z"
        transform="translate(-147.13 -163)"
        fill="#333"
      />
      <g opacity="0.5">
        <path
          d="M632.94,543.36c-59.33,0-120.77-6-183.69-18.06-169.2-32.36-260.63-95.12-265.63-97.81L168.54,402.4h0c1.41.76,121.56,64.67,286.65,96.11C606.7,527.37,828.38,534,1024.09,401.7l-4.3,30.21c-90,60.87-173,91.33-285.43,105.3A822.19,822.19,0,0,1,632.94,543.36Z"
          transform="translate(-147.13 -163)"
          fill="url(#a7defa4f-9564-416e-8ed2-e91fc3fa82a6)"
        />
      </g>
      <path
        d="M632.94,539c-59.33,0-120.77-6-183.69-18.06-169.2-32.36-260.63-95.12-265.63-97.81L168.54,398h0c1.41.76,121.56,64.67,286.65,96.11,151.5,28.85,373.18,35.48,568.89-96.81l-4.3,30.21c-90,60.87-173,91.33-285.43,105.3A822.19,822.19,0,0,1,632.94,539Z"
        transform="translate(-147.13 -163)"
        fill="#f55f44"
      />
      <g opacity="0.5">
        <path
          d="M199.11,653.49H194.3V400.84a20.64,20.64,0,1,0-20.64,0V653.49h-4.81a21.72,21.72,0,0,0-21.72,21.72v15.13h73.71V675.21A21.72,21.72,0,0,0,199.11,653.49Z"
          transform="translate(-147.13 -163)"
          fill="url(#5a563cc2-1281-4323-9b9c-e9fa5d90f13d)"
        />
      </g>
      <rect x="26.96" y="214.58" width="19.79" height="279.87" fill="#e0e0e0" />
      <path
        d="M20.5,485.26H53.2a19,19,0,0,1,19,19V520.6a0,0,0,0,1,0,0H1.52a0,0,0,0,1,0,0V504.25A19,19,0,0,1,20.5,485.26Z"
        fill="#e0e0e0"
      />
      <circle cx="36.85" cy="227.3" r="19.79" opacity="0.1" />
      <circle cx="36.85" cy="225.89" r="19.79" fill="#e0e0e0" />
      <path
        d="M174.08,649.67s12.72,4.23,19.79,0"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
      <rect x="859.63" y="214.58" width="19.79" height="279.87" fill="#e0e0e0" />
      <path
        d="M853.17,485.26h32.7a19,19,0,0,1,19,19V520.6a0,0,0,0,1,0,0H834.19a0,0,0,0,1,0,0V504.25a19,19,0,0,1,19-19Z"
        fill="#e0e0e0"
      />
      <circle cx="869.52" cy="227.3" r="19.79" opacity="0.1" />
      <circle cx="869.52" cy="225.89" r="19.79" fill="#e0e0e0" />
      <path
        d="M1006.75,649.67s12.72,4.23,19.79,0"
        transform="translate(-147.13 -163)"
        opacity="0.1"
      />
    </svg>
  );
}

export default QueueIllustration;
