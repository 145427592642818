import React from 'react';
import {
  List,
  ListItem,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListItemAvatar,
} from '@material-ui/core';
import { Track } from '../../../models/Track';
import styles from './TrackList.module.css';
import Delete from '@material-ui/icons/Delete';
import autobind from 'autobind-decorator';

export interface TrackListProps {
  tracks?: Track[];
  onClick?(track: Track): void;
  removeable?: boolean;
  onRemove?(tack: Track): void;
}

export interface TrackListState {}

class TrackList extends React.Component<TrackListProps, TrackListState> {
  constructor(props: TrackListProps) {
    super(props);
  }

  private onClick(track: Track) {
    if (this.props && this.props.onClick) {
      this.props.onClick(track);
    }
  }

  formatSeconds(secs: number) {
    const sec_num = secs / 1000;

    let minutes = Math.floor(sec_num / 60);
    let seconds = Math.floor(sec_num - minutes * 60);

    let minutesString: string = '00';
    let secondsString: string = '00';

    if (minutes < 10) {
      minutesString = '0' + minutes;
    } else {
      minutesString = minutes.toString();
    }
    if (seconds < 10) {
      secondsString = '0' + seconds;
    } else {
      secondsString = seconds.toString();
    }

    return minutesString + ':' + secondsString;
  }

  @autobind
  onDelete(track: Track) {
    if (this.props.onRemove) {
      this.props.onRemove(track);
    }
  }

  render() {
    if (this.props.tracks) {
      return (
        <List className={styles.list}>
          {this.props.tracks.map((t: Track) => {
            const src = t.thumbnail;

            return (
              <ListItem key={t.id} onClick={() => this.onClick(t)}>
                <ListItemAvatar>
                  <Avatar src={src} style={{ borderRadius: 0 }} />
                </ListItemAvatar>
                <ListItemText
                  primary={t.name}
                  secondary={`${t.artist} - ${t.album}`}
                  primaryTypographyProps={{ noWrap: true }}
                  secondaryTypographyProps={{ noWrap: true, variant: 'subtitle2' }}
                />
                {this.props.removeable ? (
                  <ListItemSecondaryAction>
                    <IconButton aria-label="delete track" onClick={() => this.onDelete(t)}>
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                ) : (
                  <ListItemText
                    secondary={this.formatSeconds(t.duration_ms)}
                    style={{ textAlign: 'right', padding: 0, width: '4em' }}
                  />
                )}
              </ListItem>
            );
          })}
        </List>
      );
    }
  }
}

export default TrackList;
