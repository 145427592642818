import { action } from 'typesafe-actions';

import { FullAlbum } from '../../models/Album';
import {
  ALBUM_FETCH_REQUEST,
  ALBUM_FETCH_SUCCESS,
  ALBUM_FETCH_ERROR,
} from '../constants/general.constants';
import { HubError } from '../../models/HubError';

export const fetchAlbumRequest = (albumId: string) => action(ALBUM_FETCH_REQUEST, albumId);
export const fetchAlbumSuccess = (album: FullAlbum) => action(ALBUM_FETCH_SUCCESS, album);
export const fetchAlbumError = (albumId: string, err: HubError) =>
  action(ALBUM_FETCH_ERROR, { err, id: albumId });
