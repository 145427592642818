import { all, fork, take, put } from 'redux-saga/effects';
import {
  sendAlbumRequestToServer,
  sendPlaylistRequestToServer,
  sendUserSpecificSearchRequestToServer,
} from './search.worker';
import { SEARCH_FETCH_REQUEST, SEARCH_FETCH_ERROR } from '../../constants/search.constants';
import { ALBUM_FETCH_REQUEST, PLAYLIST_FETCH_REQUEST } from '../../constants/general.constants';
import { enqueueSnackbar } from '../../actions/notification.action';
import { localisation } from '../../../i18n/LanguageService';

function* watchSearchRequest() {
  const action = yield take(SEARCH_FETCH_REQUEST);
  const query = action.payload;

  yield sendUserSpecificSearchRequestToServer(query);

  yield fork(watchSearchRequest);
}

function* watchAlbumRequest() {
  const action = yield take(ALBUM_FETCH_REQUEST);
  const albumId = action.payload;

  yield sendAlbumRequestToServer(albumId);

  yield fork(watchAlbumRequest);
}

function* watchPlaylistRequest() {
  const action = yield take(PLAYLIST_FETCH_REQUEST);
  const playlistId = action.payload;

  yield sendPlaylistRequestToServer(playlistId);

  yield fork(watchPlaylistRequest);
}

function* watchSearchError() {
  const action = yield take(SEARCH_FETCH_ERROR);

  yield put(
    enqueueSnackbar({
      message: localisation.errors.search.fetch,
      options: {
        variant: 'error',
      },
    })
  );

  yield fork(watchSearchError);
}

export default function* sarchSaga() {
  yield all([
    fork(watchSearchRequest),
    fork(watchAlbumRequest),
    fork(watchPlaylistRequest),
    fork(watchSearchError),
  ]);
}
