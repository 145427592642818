import React, { ChangeEvent } from 'react';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Paper,
  Theme,
  withTheme,
  Typography,
} from '@material-ui/core';
import styles from './SettingsPage.module.css';
import { connect } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import autobind from 'autobind-decorator';
import { changeTheme } from '../../../redux/actions/general.action';
import { store } from '../../../redux/store';
import { localisation } from '../../../i18n/LanguageService';
import { DeviceSize } from '../../../redux/reducers/general.reducer';

export interface SettingsPageProps {
  currentTheme: string;
  deviceSize: DeviceSize;
  theme?: Theme;
}

export interface SettingsPageState {}

const mapStateToProps = (state: AppState) => {
  return {
    currentTheme: state.general.theme,
    deviceSize: state.general.deviceSize,
  };
};

class SettingsPage extends React.Component<SettingsPageProps, SettingsPageState> {
  constructor(props: SettingsPageProps) {
    super(props);
  }

  @autobind
  changeTheme(evt: ChangeEvent<any>) {
    store.dispatch(changeTheme(evt.target.value));
  }

  @autobind
  renderSettingsForm() {
    return (
      <FormControl>
        <InputLabel>Theme</InputLabel>
        <Select value={this.props.currentTheme} onChange={this.changeTheme}>
          <MenuItem value="default">{localisation.strings.defaultTheme}</MenuItem>
          <MenuItem value="dark">{localisation.strings.darkTheme}</MenuItem>
          <MenuItem value="green">Emerald</MenuItem>
          <MenuItem value="red">Ruby</MenuItem>
          <MenuItem value="neon">Neon</MenuItem>
          <MenuItem value="orange">Orange</MenuItem>
        </Select>
      </FormControl>
    );
  }

  render() {
    if (this.props.deviceSize <= DeviceSize.TABLET) {
      return <div className={styles.settings}>{this.renderSettingsForm()}</div>;
    }
    return (
      <div
        className={styles.settings}
        style={{
          background: this.props.theme!.palette.background.paper,
          alignItems: 'center',
        }}
      >
        <Grid
          item
          className={styles.item}
          md={6}
          style={{ display: 'flex', flex: 1, padding: 32, width: '50%' }}
        >
          <Paper
            style={{
              background: this.props.theme!.palette.background.default,
              overflowY: 'auto',
              flex: 1,
              padding: 32,
            }}
          >
            <Typography variant="h4" gutterBottom color="primary" style={{ marginBottom: 32 }}>
              {localisation.strings.appBarTitleUserSettings}
            </Typography>
            {this.renderSettingsForm()}
          </Paper>
        </Grid>
      </div>
    );
  }
}

export default withTheme(connect(mapStateToProps)(SettingsPage));
