import { ActionType } from 'typesafe-actions';
import * as actions from './../actions/search.action';
import { combineReducers } from 'redux';
import { SearchResult } from '../../models/SearchResult';
import {
  SEARCH_FETCH_SUCCESS,
  SEARCH_FETCH_REQUEST,
  SEARCH_FETCH_ERROR,
} from '../constants/search.constants';
import { HubError } from '../../models/HubError';
import { LoadingState } from '../../models/LoadingState';

export interface SearchState {
  result: SearchResult | null;
  userDidSearch: boolean;
  loadingState: LoadingState;
  error: HubError | null;
}

export type SearchAction = ActionType<typeof actions>;

export const search = combineReducers<SearchState, SearchAction>({
  result: (state = null, action) => {
    switch (action.type) {
      case SEARCH_FETCH_SUCCESS: {
        return action.payload;
      }
    }
    return state;
  },
  userDidSearch: (state = false, action) => {
    switch (action.type) {
      case SEARCH_FETCH_REQUEST: {
        return true;
      }
    }
    return state;
  },
  loadingState: (state = LoadingState.NOT_STARTED, action) => {
    switch (action.type) {
      case SEARCH_FETCH_REQUEST: {
        return LoadingState.LOADING;
      }
      case SEARCH_FETCH_SUCCESS: {
        return LoadingState.SUCCESS;
      }
      case SEARCH_FETCH_ERROR: {
        return LoadingState.ERROR;
      }
    }
    return state;
  },
  error: (state = null, action) => {
    switch (action.type) {
      case SEARCH_FETCH_REQUEST: {
        return null;
      }
      case SEARCH_FETCH_SUCCESS: {
        return null;
      }
      case SEARCH_FETCH_ERROR: {
        return action.payload;
      }
    }
    return state;
  },
});
