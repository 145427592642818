import React from 'react';
import styles from './JoinSessionPage.module.css';
import { Grid, withTheme, Theme, Typography } from '@material-ui/core';
import LobbyEmptyPlaceholder from '../components/LobbyEmptyPlaceholder';
import { localisation } from '../../../i18n/LanguageService';
import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import { store } from '../../../redux/store';
import {
  joinSessionRequest,
  createSessionRequest,
  switchSessionRequest,
} from '../../../redux/actions/session.action';
import { Session } from '../../../models/Session';
import { connect } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import SessionList from '../components/SessionList';
import SelectIllustration from '../../illustrations/Select';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router';
import JoinSessionDialog from '../components/JoinSessionDialog';
import NewSessionDialog from '../components/NewSessionDialog';

export interface JoinSessionPageProps extends RouteComponentProps {
  theme?: Theme;
  sessions?: Session[];
}

export interface JoinSessionPageState {
  newSessionDialogOpen: boolean;
}

const mapStateToProps = (state: AppState) => {
  return {
    sessions: state.session.sessionList || undefined,
  };
};

class JoinSessionPage extends React.Component<JoinSessionPageProps, JoinSessionPageState> {
  constructor(props: JoinSessionPageProps) {
    super(props);
    this.state = { newSessionDialogOpen: false };
  }

  @autobind
  joinSession(sessionId?: string) {
    this.setState({ newSessionDialogOpen: false });
    if (sessionId) {
      store.dispatch(joinSessionRequest(sessionId));
    }
  }

  @autobind
  createSession(session?: Partial<Session>) {
    this.setState({ newSessionDialogOpen: false });
    if (session && session.name) {
      store.dispatch(createSessionRequest(session.name));
    }
  }

  @autobind
  openNewSessionDialog() {
    this.setState({ newSessionDialogOpen: true });
  }

  @autobind
  switchSession(sessionId: string) {
    store.dispatch(switchSessionRequest(sessionId));
    store.dispatch(push(`/session`));
  }

  render() {
    const { id } = queryString.parse(this.props.location.search);
    if (id) {
      store.dispatch(joinSessionRequest(id as string));
    }
    if (this.props.sessions!.length > 0) {
      return (
        <div className={styles.sessions}>
          <Grid item className={styles.item} xs={12} md={3}>
            <div className={styles.sessionsHeader}>
              <div className={styles.illustration}>
                <SelectIllustration color={this.props.theme!.palette.primary.main} />
              </div>
              <Typography color="primary" variant="h5">
                {localisation.strings.lobbyChooseSession}
              </Typography>
              <Typography color="textSecondary" variant="subtitle1">
                {localisation.strings.lobbySwitchOrCreateSession}
              </Typography>
            </div>
            <SessionList
              swipe={false}
              sessions={this.props.sessions!}
              onClick={this.switchSession}
              onFabClick={this.openNewSessionDialog}
              hideSecondaryAction
            />
          </Grid>
          <JoinSessionDialog open={this.state.newSessionDialogOpen} onClose={this.joinSession} />
        </div>
      );
    }
    if (this.props.sessions!.length <= 0) {
      return (
        <div className={styles.noSession}>
          <Grid item className={styles.item} xs={12} md={3}>
            <LobbyEmptyPlaceholder
              title={localisation.strings.lobbyEmptyTitle}
              message={localisation.strings.lobbyEmptyMessage}
              onJoinSession={this.joinSession}
              onNewSession={this.openNewSessionDialog}
            />
          </Grid>
          <NewSessionDialog open={this.state.newSessionDialogOpen} onClose={this.createSession} />
        </div>
      );
    }
  }
}

export default withTheme(connect(mapStateToProps)(JoinSessionPage));
