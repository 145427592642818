import {
  JsonHubProtocol,
  HttpTransportType,
  HubConnectionBuilder,
  HubConnection,
} from '@aspnet/signalr';
import { store } from '../store';
import { push } from 'connected-react-router';
import {
  signalRConnectionLost,
  signalRConnectionEstablished,
  signalRConnectingError,
  signalRReconnectingSuccess,
} from '../actions/signalR.action';

export class SingalRService {
  private connection?: HubConnection;
  private callbackToRegister?: (connection: HubConnection) => void = undefined;
  private connectionHub = '';

  public async connect(token: string) {
    this.connection = this.getOrCreateHubConnection(token);

    if (this.callbackToRegister) {
      this.callbackToRegister(this.connection);
    }

    this.connection.onclose(() => {
      store.dispatch(signalRConnectionLost());
    });

    return this.startSignalRConnection();
  }

  public invoke(remoteFunctionName: string, ...args: any[]) {
    if (this.connection) {
      if (args.length > 0) {
        return this.connection.invoke(remoteFunctionName, ...args);
      }
      return this.connection.invoke(remoteFunctionName);
    }
    return new Promise((resolve, reject) => {});
  }

  public send(remoteFunctionName: string, ...args: any[]) {
    if (this.connection) {
      if (args.length > 0) {
        this.connection.send(remoteFunctionName, ...args);
      } else {
        this.connection.send(remoteFunctionName);
      }
    }
  }

  public register(callback: (connection: HubConnection) => void) {
    if (this.connection) {
      callback(this.connection);
    } else {
      this.callbackToRegister = callback;
    }
  }

  public startSignalRConnection() {
    this.connection = this.getOrCreateHubConnection();
    return new Promise((resolve, reject) => {
      if (this.connection) {
        return this.connection
          .start()
          .then(() => {
            store.dispatch(signalRConnectionEstablished());
            return resolve();
          })
          .catch(err => store.dispatch(signalRConnectingError(err)));
      }
      return reject('Could not start connection because connection is not defined');
    });
  }

  public reconnect() {
    this.connection = this.getOrCreateHubConnection();
    return new Promise((resolve, reject) => {
      if (this.connection) {
        return this.connection
          .start()
          .then(() => {
            store.dispatch(signalRReconnectingSuccess());
            store.dispatch(signalRConnectionEstablished());
            return resolve();
          })
          .catch(err => store.dispatch(signalRConnectingError(err)));
      }
      return reject('Could not start connection because connection is not defined');
    });
  }

  private getOrCreateHubConnection(token?: string) {
    const { settings, authentication } = store.getState();
    const connectionHub = `${settings.serverUrl}/jukifyhub?access_token=${
      token || authentication.token ? authentication.token!.token : ''
    }`;

    if (this.connectionHub === connectionHub && this.connection) {
      return this.connection;
    }

    this.connectionHub = connectionHub;

    const protocol = new JsonHubProtocol();

    const transport = HttpTransportType.WebSockets;

    const options = {
      transport,
    };

    return new HubConnectionBuilder()
      .withUrl(connectionHub, options)
      .withHubProtocol(protocol)
      .build();
  }
}

export const singalRService = new SingalRService();
