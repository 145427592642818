import { action } from 'typesafe-actions';
import { Device } from '../../models/Device';
import {
  SPOTIFY_PAUSE_REQUEST,
  SPOTIFY_PAUSE_SUCCESS,
  SPOTIFY_TOGGLE_PAUSED_REQUEST,
  SPOTIFY_PLAY_REQUEST,
  SPOTIFY_FETCH_DEVICES_REQUEST,
  SPOTIFY_FETCH_DEVICES_SUCCESS,
  SPOTIFY_SWITCH_DEVICE_REQUEST,
  SPOTIFY_SWITCH_DEVICE_SUCCESS,
  SPOTIFY_PAUSE_ERROR,
  SPOTIFY_PLAY_SUCCESS,
  SPOTIFY_PLAY_ERROR,
  SPOTIFY_FETCH_DEVICES_ERROR,
  SPOTIFY_DEVICES_UPDATE_ASYNC,
} from '../constants/spotify.constants';
import { HubError } from '../../models/HubError';

export const spoitfyPauseRequest = () => action(SPOTIFY_PAUSE_REQUEST);
export const spotifyPauseSuccess = () => action(SPOTIFY_PAUSE_SUCCESS);
export const spotifyPauseError = (err: HubError) => action(SPOTIFY_PAUSE_ERROR, err);

export const togglePaused = () => action(SPOTIFY_TOGGLE_PAUSED_REQUEST);

export const spoitfyPlayRequest = () => action(SPOTIFY_PLAY_REQUEST);
export const spoitfyPlaySuccess = () => action(SPOTIFY_PLAY_SUCCESS);
export const spoitfyPlayError = (err: HubError) => action(SPOTIFY_PLAY_ERROR, err);

export const spotifyFetchDevicesRequest = () => action(SPOTIFY_FETCH_DEVICES_REQUEST);
export const spotifyFetchDevicesSuccess = (devices: Device[]) =>
  action(SPOTIFY_FETCH_DEVICES_SUCCESS, devices);
export const spotifyFetchDevicesError = (err: HubError) => action(SPOTIFY_FETCH_DEVICES_ERROR, err);

export const spotifyAsyncDevicesUpdate = (devices: Device[]) =>
  action(SPOTIFY_DEVICES_UPDATE_ASYNC, devices);

export const spotifySwitchDeviceRequest = (id: string) => action(SPOTIFY_SWITCH_DEVICE_REQUEST, id);
export const spotifySwitchDeviceSuccess = (id: string) => action(SPOTIFY_SWITCH_DEVICE_SUCCESS, id);
export const spotifySwitchDeviceError = (err: HubError) =>
  action(SPOTIFY_SWITCH_DEVICE_SUCCESS, err);
