export const ALBUM_FETCH_REQUEST = 'ALBUM_FETCH_REQUEST';
export const ALBUM_FETCH_SUCCESS = 'ALBUM_FETCH_SUCCESS';
export const ALBUM_FETCH_ERROR = 'ALBUM_FETCH_ERROR';

export const PLAYLIST_FETCH_REQUEST = 'PLAYLIST_FETCH_REQUEST';
export const PLAYLIST_FETCH_SUCCESS = 'PLAYLIST_FETCH_SUCCESS';
export const PLAYLIST_FETCH_ERROR = 'PLAYLIST_FETCH_ERROR';

export const APP_INITIALIZE_REQUEST = 'APP_INITIALIZE_REQUEST';
export const APP_INITIALIZE_SUCCESS = 'APP_INITIALIZE_SUCCESS';
export const APP_INITIALIZE_ERROR = 'APP_INITIALIZE_ERROR';

export const APPLY_SETTINGS = 'APPLY_SETTINGS';

export const APP_THEME_CHANGE = 'APP_THEME_CHANGE';

export const WINDOW_RESIZED = 'WINDOW_RESIZED';
