import React from 'react';
import { Route, Switch, Link, RouteComponentProps } from 'react-router-dom';
import LobbyPage from '../modules/lobby/pages/LobbyPage';
import AppBar from './components/Appbar';
import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Typography,
  withTheme,
  Theme,
} from '@material-ui/core';
import PlayArrow from '@material-ui/icons/PlayArrow';
import QueueMusic from '@material-ui/icons/QueueMusic';
import PieChart from '@material-ui/icons/PieChart';
import Settings from '@material-ui/icons/Settings';
import DashboardPage from '../modules/dashboard/pages/DashboardPage';
import { localisation } from '../i18n/LanguageService';
import PlaylistPage from '../modules/playlist/pages/PlaylistPage';
import QrCodePage from '../modules/qrcode/pages/QrCodePage';
import autobind from 'autobind-decorator';
import SettingsPage from '../modules/settings/pages/SettingsPage';
import DistributionPage from '../modules/distribution/pages/DistributionPage';
import { AppState } from '../redux/reducer';
import { connect } from 'react-redux';
import Notifier from '../modules/general/components/Notifier/Notifier';
import LegalTabsPage from '../modules/legal/pages/LegalTabsPage';
import { DeviceSize } from '../redux/reducers/general.reducer';
import DesktopOverviewPage from '../modules/desktop/pages/DesktopOverviewPage';
import SessionSettingsPage from '../modules/session-settings/pages/SessionSettingsPage';

export interface SessionRouterProps extends Partial<RouteComponentProps> {
  appInitialized: boolean;
  currentSessionId?: string;
  deviceSize: DeviceSize;
  theme: Theme;
}

export interface SessionRouterState {}

const mapStateToProps = (state: AppState) => {
  return {
    appInitialized: state.general.initialized,
    currentSessionId: state.session.currentSession || undefined,
    deviceSize: state.general.deviceSize,
  };
};

class SessionRouter extends React.Component<SessionRouterProps, SessionRouterState> {
  public action: any = BottomNavigationAction;

  constructor(props: SessionRouterProps) {
    super(props);
  }

  @autobind
  getValue() {
    const location = this.props.location!.pathname;

    if (location === `/session`) {
      return 'home';
    }
    if (location === `/session/playlist`) {
      return 'playlist';
    }
    if (location === `/session/split`) {
      return 'split';
    }
    if (location === `/session/sessionSettings`) {
      return 'sessionSettings';
    }
  }

  @autobind
  renderRoutes() {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          position: 'relative',
          overflowY: 'auto',
        }}
      >
        {this.props.deviceSize <= DeviceSize.TABLET && (
          <Switch>
            <Route path={`/session/sessions`} component={LobbyPage} />
            <Route path="/session/playlist" component={PlaylistPage} />
            <Route path="/session/qrcode" component={QrCodePage} />
            <Route path="/session/settings" component={SettingsPage} />
            <Route path="/session/sessionSettings" component={SessionSettingsPage} />
            <Route path="/session/split" component={DistributionPage} />
            <Route path="/session/legal" component={LegalTabsPage} />
            <Route path="/session" component={DashboardPage} />
          </Switch>
        )}
        {this.props.deviceSize > DeviceSize.TABLET && (
          <Switch>
            <Route path={`/session/sessions`} component={LobbyPage} />
            <Route path="/session/playlist" component={DesktopOverviewPage} />
            <Route path="/session/qrcode" component={QrCodePage} />
            <Route path="/session/settings" component={SettingsPage} />
            <Route path="/session/sessionSettings" component={SessionSettingsPage} />
            <Route path="/session/split" component={DesktopOverviewPage} />
            <Route path="/session/legal" component={LegalTabsPage} />
            <Route path="/session" component={DesktopOverviewPage} />
          </Switch>
        )}
      </div>
    );
  }

  render() {
    return (
      <>
        <AppBar location={this.props.location} />
        {this.renderRoutes()}
        {this.props.deviceSize <= DeviceSize.TABLET && (
          <BottomNavigation showLabels={true} value={this.getValue()}>
            <this.action
              label={localisation.strings.bottomNavHome}
              component={Link}
              to={`/session`}
              value="home"
              icon={<PlayArrow />}
            />
            <this.action
              label={localisation.strings.bottomNavUserPlaylist}
              component={Link}
              to={`/session/playlist`}
              value="playlist"
              icon={<QueueMusic />}
            />
            <this.action
              label={localisation.strings.bottomNavDistribution}
              component={Link}
              to={`/session/split`}
              value="split"
              icon={<PieChart />}
            />
            <this.action
              label={localisation.strings.bottomNavSessionSettings}
              component={Link}
              to={`/session/sessionSettings`}
              value="sessionSettings"
              icon={<Settings />}
            />
          </BottomNavigation>
        )}
      </>
    );
  }
}

export default withTheme(connect(mapStateToProps)(SessionRouter));
