import { all, fork, take, put } from 'redux-saga/effects';
import { sendUpdatePercentageRequestToServer } from './sessionUser.worker';
import { SESSIONUSER_UPDATE_PERCENTAGE_REQUEST } from '../../constants/sessionUser.constants';
import {
  sessionUserUpdatePercentageSuccess,
  sessionUserUpdatePercentageError,
} from '../../actions/sessionUsers.action';

function* watchUpdatePercentageRequest() {
  const action = yield take(SESSIONUSER_UPDATE_PERCENTAGE_REQUEST);
  const { sessionId, userId, percentage } = action.payload;

  yield sendUpdatePercentageRequestToServer(sessionId, userId, percentage);

  yield fork(watchUpdatePercentageRequest);
}

export default function* sessionUserSaga() {
  yield all([fork(watchUpdatePercentageRequest)]);
}
