import React from 'react';
import styles from './FullpageLoading.module.css';
import { Typography, withTheme, Theme, Hidden } from '@material-ui/core';
import VinylSpinner from './VinylSpinnner';

export interface FullPageLoadingProps {
  message?: string;
  visible?: boolean;
  theme?: Theme;
  zIndex?: number;
  artificialDelay?: number;
}

export interface FullPageLoadingState {
  render: boolean;
}

class FullPageLoading extends React.Component<FullPageLoadingProps, FullPageLoadingState> {
  private element: HTMLDivElement | null;
  private spinner: HTMLDivElement | null;

  constructor(props: FullPageLoadingProps) {
    super(props);

    this.element = null;
    this.spinner = null;

    this.state = { render: true };
  }

  componentDidMount() {
    if (!this.props.visible) {
      this.setState({ render: false });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: FullPageLoadingProps) {
    if (newProps.visible === false && this.props.visible && this.element && this.spinner) {
      setTimeout(() => {
        if (this.element) {
          this.element!.style.opacity = '0';
        }
        if (this.spinner) {
          this.spinner!.style.transform = 'scale(0)';
        }
        setTimeout(() => {
          this.setState({ render: false });
        }, 1000);
      }, this.props.artificialDelay || 0);
    }
    if (newProps.visible) {
      this.setState({ render: true });
      if (this.element) {
        this.element!.style.opacity = '1';
        this.spinner!.style.transform = 'scale(1)';
      }
    }
  }

  render() {
    if (this.state.render) {
      return (
        <div
          className={styles.loading}
          ref={div => (this.element = div)}
          style={{
            backgroundColor: this.props.theme!.palette.background.default,
            zIndex: this.props.zIndex || 1000000,
          }}
        >
          <div ref={div => (this.spinner = div)} className={styles.spinner} style={{ opacity: 1 }}>
            <VinylSpinner />
          </div>
          <Typography color="primary" variant="h6" className={styles.text}>
            {this.props.message}
          </Typography>
        </div>
      );
    }
    return <></>;
  }
}

export default withTheme(FullPageLoading);
