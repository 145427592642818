import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  withTheme,
  Theme,
} from '@material-ui/core';
import { localisation } from '../../../i18n/LanguageService';
import styles from './LeaveSessionDialog.module.css';
import autobind from 'autobind-decorator';
import { Session } from 'inspector';
import SadFaceIllustration from '../../illustrations/SadFace';

export interface LeaveSessionDialogProps {
  open: boolean;
  theme?: Theme;
  onClose: (leave: boolean) => void;
}

export interface LeaveSessionDialogState {}

class LeaveSessionDialog extends React.Component<LeaveSessionDialogProps, LeaveSessionDialogState> {
  constructor(props: LeaveSessionDialogProps) {
    super(props);
  }

  @autobind
  handleCloseConfirm() {
    this.props.onClose(true);
  }

  @autobind
  handleCloseCancel() {
    this.props.onClose(false);
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleCloseCancel}
        aria-labelledby="form-dialog-title"
      >
        <SadFaceIllustration color={this.props.theme!.palette.primary.main} />
        <DialogTitle id="form-dialog-title">
          {localisation.strings.leaveSessionDialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{localisation.strings.leaveSessionDialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button onClick={this.handleCloseCancel} color="primary">
            {localisation.strings.leaveSessionDialogCancel}
          </Button>
          <Button onClick={this.handleCloseConfirm} color="primary">
            {localisation.strings.leaveSessionDialogConfirm}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTheme(LeaveSessionDialog);
