import React from 'react';
import { Typography, List, ListItem, withTheme, Theme } from '@material-ui/core';
import styles from './PlaylistList.module.css';
import { Playlist } from '../../../models/Playlist';

export interface PlaylistListProps {
  playlists?: Playlist[];
  onClick?(playlist: Playlist): void;
  theme?: Theme;
}

export interface PlaylistListState {}

class PlaylistList extends React.Component<PlaylistListProps, PlaylistListState> {
  constructor(props: PlaylistListProps) {
    super(props);
  }

  onPlaylistClick(playlist: Playlist) {
    if (this.props.onClick) {
      this.props.onClick(playlist);
    }
  }

  render() {
    const flexContainer = {
      display: 'flex',
      flexDirection: 'row',
    };
    if (this.props.playlists)
      return (
        <>
          <div className={styles.listWrapper}>
            <List style={flexContainer as any}>
              {this.props.playlists.map(playlist => (
                <ListItem
                  key={playlist.id}
                  onClick={evt => this.onPlaylistClick(playlist)}
                  style={{
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    height: 'fit-content',
                    width: '132px',
                  }}
                >
                  <div
                    className={styles.horizontalItem}
                    style={{
                      backgroundColor: this.props.theme!.palette.background.default,
                      color: this.props.theme!.palette.text.primary,
                    }}
                  >
                    <div className={styles.thumbnailWrapper}>
                      <img className={styles.thumbnail} src={playlist.thumbnail} />
                    </div>
                    <Typography variant="subtitle2" color="inherit" noWrap={true}>
                      {playlist.name}
                    </Typography>
                  </div>
                </ListItem>
              ))}
            </List>
          </div>
        </>
      );
    return <></>;
  }
}

export default withTheme(PlaylistList);
