import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Theft from './Theft';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://79e1fa8731ce45a09686b8187672fd0e@sentry.io/1376150',
  });
}
if (location.hostname === 'jukify.de' || location.hostname === 'localhost') {
  ReactDOM.render(<App />, document.getElementById('root'));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.register();
} else {
  ReactDOM.render(<Theft />, document.getElementById('root'));
}
