import React from 'react';
import { Paper, Typography, withTheme, Theme } from '@material-ui/core';
import DashboardPage from '../../dashboard/pages/DashboardPage';
import { localisation } from '../../../i18n/LanguageService';
import PlaylistPage from '../../playlist/pages/PlaylistPage';
import DistributionPage from '../../distribution/pages/DistributionPage';
import styles from './DesktopOverviewPage.module.css';

export interface DesktopOverviewPageProps {
  theme?: Theme;
}

export interface DesktopOverviewPageState {}

class DesktopOverviewPage extends React.Component<
  DesktopOverviewPageProps,
  DesktopOverviewPageState
> {
  constructor(props: DesktopOverviewPageProps) {
    super(props);
  }
  render() {
    return (
      <div
        className={styles.scrollbar}
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gridTemplateRows: '100%',
          gap: 16,
          padding: 24,
          height: '100%',
          backgroundColor: this.props.theme!.palette.background.paper,
          minWidth: 0,
        }}
      >
        <Paper
          style={{
            padding: 16,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: this.props.theme!.palette.background.default,
            minWidth: 0,
            position: 'relative',
          }}
        >
          <DashboardPage />
        </Paper>
        <Paper
          style={{
            padding: 16,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: this.props.theme!.palette.background.default,
            minWidth: 0,
            position: 'relative',
          }}
        >
          <Typography
            style={{ marginLeft: 16, color: this.props.theme!.palette.primary.main }}
            variant="h5"
            gutterBottom
            color="inherit"
            noWrap={true}
          >
            {localisation.strings.appBarTitleUserPlaylist}
          </Typography>
          <PlaylistPage />
        </Paper>
        <Paper
          style={{
            padding: 16,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: this.props.theme!.palette.background.default,
            minWidth: 0,
            position: 'relative',
          }}
        >
          <Typography
            style={{ marginLeft: 16, color: this.props.theme!.palette.primary.main }}
            variant="h5"
            gutterBottom
            color="inherit"
            noWrap={true}
          >
            {localisation.strings.appBarTitleUserDistribution}
          </Typography>
          <DistributionPage />
        </Paper>
      </div>
    );
  }
}

export default withTheme(DesktopOverviewPage);
