import React from 'react';
import {
  List,
  ListItem,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
} from '@material-ui/core';
import { Track } from '../../../models/Track';
import styles from './SelectableTrackList.module.css';
import AlbumCoverIllustration from '../../illustrations/AlbumCover';
import autobind from 'autobind-decorator';

export interface SelectableTrackListProps {
  tracks?: Track[];
  onSelectedTracksChange(tracks: Track[]);
}

export interface SelectableTrackListState {
  selectedTracks: Track[];
}

class SelectableTrackList extends React.Component<
  SelectableTrackListProps,
  SelectableTrackListState
> {
  constructor(props: SelectableTrackListProps) {
    super(props);
    if (this.props.tracks) {
      this.state = { selectedTracks: this.props.tracks };
    } else {
      this.state = { selectedTracks: [] };
    }
  }

  // static getDerivedStateFromProps(
  //   nextProps: SelectableTrackListProps,
  //   prevState: SelectableTrackListState
  // ) {
  //   if (nextProps.tracks) {
  //     nextProps.onSelectedTracksChange(nextProps.tracks);
  //     return Object.assign(prevState, { selectedTracks: nextProps.tracks });
  //   }
  //   return prevState;
  // }

  componentDidMount() {
    if (this.props.tracks) {
      this.props.onSelectedTracksChange(this.state.selectedTracks);
    }
  }

  @autobind
  private toggleTrack(track: Track) {
    const index = this.state.selectedTracks.indexOf(track);
    if (index < 0) {
      this.setState({ selectedTracks: [...this.state.selectedTracks, track] });
      this.props.onSelectedTracksChange([...this.state.selectedTracks, track]);
    } else {
      const newArray = [...this.state.selectedTracks];
      newArray.splice(index, 1);
      this.setState({ selectedTracks: newArray });
      this.props.onSelectedTracksChange(newArray);
    }
  }

  formatSeconds(secs: number) {
    const sec_num = secs / 1000;

    let minutes = Math.floor(sec_num / 60);
    let seconds = Math.floor(sec_num - minutes * 60);

    let minutesString: string = '00';
    let secondsString: string = '00';

    if (minutes < 10) {
      minutesString = '0' + minutes;
    } else {
      minutesString = minutes.toString();
    }
    if (seconds < 10) {
      secondsString = '0' + seconds;
    } else {
      secondsString = seconds.toString();
    }

    return minutesString + ':' + secondsString;
  }

  @autobind
  selectAll() {
    this.setState( { selectedTracks: this.props.tracks! });
    this.props.onSelectedTracksChange(this.props.tracks!);
  }

  @autobind
  deselectAll() {
    this.setState( { selectedTracks: [] });
    this.props.onSelectedTracksChange([]);
  }

  render() {
    if (this.props.tracks) {
      return (
        <List className={styles.list}>
          {this.props.tracks.map((t: Track) => {
            return (
              <ListItem key={t.id} onClick={() => this.toggleTrack(t)}>
                <ListItemText
                  onClick={() => this.toggleTrack(t)}
                  primary={t.name}
                  secondary={`${t.artist} - ${t.album}`}
                  primaryTypographyProps={{ noWrap: true }}
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    color="primary"
                    onChange={() => this.toggleTrack(t)}
                    checked={this.state.selectedTracks.some(track => track.id === t.id)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      );
    }
  }
}

export default SelectableTrackList;
