import { applyMiddleware, createStore, Store, combineReducers, AnyAction } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducer, { AppState } from './reducer';

import { createBrowserHistory, createHashHistory } from 'history';
import { connectRouter, routerMiddleware, push } from 'connected-react-router';
import { userSignedIn } from './actions/authentication.action';
import createSagaMiddleware from '@redux-saga/core';
import rootSaga from './sagas/root.saga';
import { switchSessionSuccess } from './actions/session.action';
import { TokenDto } from './../interfaces/Token.dto';
import { isCordova } from '../utils/isCordova';
import { windowResized } from './actions/general.action';
import { DeviceSize } from './reducers/general.reducer';

export let history;

if (isCordova()) {
  history = createHashHistory();
} else {
  history = createBrowserHistory();
}

const sagaMiddleware = createSagaMiddleware();

const getMiddleware = () => {
  return applyMiddleware(routerMiddleware(history), sagaMiddleware);
};

const tokenFromLocalStorage = localStorage.getItem('token');
const currentSessionFromLocalStorage = localStorage.getItem('currentSession');
const theme = localStorage.getItem('theme') || 'default';
const initialState: Partial<AppState> = {};

initialState.settings = {
  loginUrl: process.env.REACT_APP_LOGIN_URL as any,
  serverUrl: process.env.REACT_APP_SERVER_URL as string,
  frontendUrl: process.env.REACT_APP_FRONTEND_URL as string,
  refreshUrl: process.env.REACT_APP_REFRESH_URL as string,
};

initialState.general = {
  theme,
  initialized: false,
  initializedError: null,
  notifications: [],
  deviceSize: DeviceSize.PHONE,
};

// @ts-ignore
export const store: Store<any, AnyAction> = createStore(
  combineReducers(Object.assign({ router: connectRouter(history) }, reducer as any)),
  initialState as any,
  composeWithDevTools(getMiddleware())
);

sagaMiddleware.run(rootSaga);

if (tokenFromLocalStorage) {
  try {
    const rawToken: TokenDto = JSON.parse(tokenFromLocalStorage);
    store.dispatch(userSignedIn(rawToken));
  } catch (err) {
    store.dispatch(push('/login'));
  }
}
if (currentSessionFromLocalStorage) {
  store.dispatch(switchSessionSuccess(currentSessionFromLocalStorage));
}

window.addEventListener('resize', (ev: any) => {
  const width = ev.target.outerWidth;
  const height = ev.target.outerHeight;
  store.dispatch(windowResized(width, height));
});
store.dispatch(windowResized(window.outerWidth, window.outerHeight));
