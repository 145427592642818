import React from 'react';
import { AppState } from './redux/reducer';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import AppRouter from './router/AppRouter';
import { connect } from 'react-redux';
import bars from './assets/svg/bars.svg';
import lines from './assets/svg/lines.svg';
import barsCenterNeon from './assets/svg/bars-center-neon.svg';
import { CssBaseline } from '@material-ui/core';

export interface ThemedContentProps {
  currentTheme: string;
}

export interface ThemedContentState {}

const mapStateToProps = (state: AppState) => {
  return {
    currentTheme: state.general.theme,
  };
};

const themes: any = {
  default: {
    dashboardBackground: null,
    dashboardDecoration: null,
    palette: {
      common: { black: '#000', white: '#fff' },
      background: { paper: 'rgba(255, 255, 255, 1)', default: 'rgba(255, 255, 255, 1)' },
      primary: {
        light: '#8E2DE2',
        main: '#8E2DE2',
        dark: '#4A00E0',
        contrastText: '#fff',
      },
      secondary: {
        light: 'rgba(131, 250, 224, 1)',
        main: 'rgba(36, 255, 206, 1)',
        dark: 'rgba(3, 145, 113, 1)',
        contrastText: 'rgba(1, 175, 196, 1)',
      },
      error: { light: '#e57373', main: '#f44336', dark: '#d32f2f', contrastText: '#fff' },
      text: {
        primary: 'rgba(24, 24, 24, 1)',
        secondary: '#666',
        disabled: 'rgba(74, 74, 74, 1)',
        hint: 'rgba(0, 0, 0, 0.38)',
      },
    },
  },
  dark: {
    dashboardBackground: 'transparent',
    dashboardDecoration: lines,
    dashboardFontColor: 'white',
    palette: {
      common: {
        black: '#000',
        white: '#fff',
      },
      background: {
        paper: '#23242a',
        default: '#1b1c22',
      },
      primary: {
        light: 'rgba(209, 196, 233, 1)',
        main: '#c078ff',
        dark: '#4A00E0',
        contrastText: '#fff',
      },
      secondary: {
        light: 'rgba(131, 250, 224, 1)',
        main: 'rgba(36, 255, 206, 1)',
        dark: 'rgba(3, 145, 113, 1)',
        contrastText: 'rgba(1, 175, 196, 1)',
      },
      error: {
        light: '#e57373',
        main: '#f44336',
        dark: '#d32f2f',
        contrastText: '#fff',
      },
      text: {
        primary: 'rgba(255, 255, 255, 1)',
        secondary: 'rgba(155, 155, 155, 1)',
        disabled: 'rgba(74, 74, 74, 1)',
        hint: 'rgba(0, 0, 0, 0.38)',
      },
    },
  },
  green: {
    dashboardBackground: 'rgba(196, 233, 199, 1)',
    dashboardDecoration: bars,
    dashboardFontColor: 'rgba(50, 195, 73, 1)',
    palette: {
      common: { black: '#000', white: '#fff' },
      background: { paper: 'rgba(255, 255, 255, 1)', default: 'rgba(255, 255, 255, 1)' },
      primary: {
        light: 'rgba(196, 233, 199, 1)',
        main: 'rgba(50, 195, 73, 1)',
        dark: 'rgba(56, 149, 72, 1)',
        contrastText: '#fff',
      },
      secondary: {
        light: 'rgba(177, 249, 255, 1)',
        main: '#00FF22',
        dark: 'rgba(1, 175, 196, 1)',
        contrastText: 'rgba(1, 175, 196, 1)',
      },
      error: { light: '#e57373', main: '#f44336', dark: '#d32f2f', contrastText: '#fff' },
      text: {
        primary: 'rgba(24, 24, 24, 1)',
        secondary: 'rgba(155, 155, 155, 1)',
        disabled: 'rgba(74, 74, 74, 1)',
        hint: 'rgba(0, 0, 0, 0.38)',
      },
    },
  },
  red: {
    dashboardBackground: 'transparent',
    dashboardDecoration: bars,
    palette: {
      common: { black: '#000', white: '#fff' },
      background: { paper: '#23242a', default: '#1b1c22' },
      primary: {
        light: 'rgba(252, 139, 141, 1)',
        main: 'rgba(234, 44, 47, 1)',
        dark: 'rgba(206, 6, 8, 1)',
        contrastText: '#fff',
      },
      secondary: {
        light: 'rgba(250, 206, 90, 1)',
        main: 'rgba(245, 179, 0, 1)',
        dark: 'rgba(255, 143, 0, 1)',
        contrastText: '#fff',
      },
      error: { light: '#e57373', main: '#f44336', dark: '#d32f2f', contrastText: '#fff' },
      text: {
        primary: 'rgba(255, 255, 255, 0.87)',
        secondary: 'rgba(255, 255, 255, 0.54)',
        disabled: 'rgba(255, 255, 255, 0.38)',
        hint: 'rgba(255, 255, 255, 0.38)',
      },
    },
  },
  neon: {
    dashboardBackground: 'transparent',
    dashboardDecoration: barsCenterNeon,
    palette: {
      common: { black: '#000', white: '#fff' },
      background: { paper: 'rgba(55, 67, 86, 1)', default: 'rgba(16, 27, 45, 1)' },
      primary: {
        light: 'rgba(115, 249, 249, 1)',
        main: '#0575E6',
        dark: '#00F260',
        contrastText: '#fff',
      },
      secondary: {
        light: 'rgba(255, 255, 255, 1)',
        main: 'rgba(255, 255, 255, 1)',
        dark: 'rgba(255, 255, 255, 1)',
        contrastText: 'rgba(0, 0, 0, 1)',
      },
      error: { light: '#e57373', main: '#f44336', dark: '#d32f2f', contrastText: '#fff' },
      text: {
        primary: 'rgba(255, 255, 255, 1)',
        secondary: 'rgba(255, 255, 255, 0.8)',
        disabled: 'rgba(150, 147, 147, 0.38)',
        hint: 'rgba(0, 0, 0, 0.38)',
      },
    },
  },
  orange: {
    dashboardBackground: 'transparent',
    dashboardDecoration: barsCenterNeon,
    palette: {
      common: { black: '#000', white: '#fff' },
      background: { paper: 'rgb(44, 50, 64)', default: 'rgb(24, 29, 38)' },
      primary: {
        light: '#FF9F00',
        main: '#FF6700',
        dark: '#F28500',
        contrastText: '#fff',
      },
      secondary: {
        light: 'rgba(255, 255, 255, 1)',
        main: 'rgba(255, 255, 255, 1)',
        dark: 'rgba(255, 255, 255, 1)',
        contrastText: 'rgba(50, 50, 50, 1)',
      },
      error: { light: '#e57373', main: '#f44336', dark: '#d32f2f', contrastText: '#fff' },
      text: {
        primary: 'rgba(255, 255, 255, 1)',
        secondary: 'rgba(255, 255, 255, 0.8)',
        disabled: 'rgba(150, 147, 147, 0.38)',
        hint: 'rgba(0, 0, 0, 0.38)',
      },
    },
  },
};

class ThemedContent extends React.Component<ThemedContentProps, ThemedContentState> {
  constructor(props: ThemedContentProps) {
    super(props);
  }

  render() {
    console.log(this.props.currentTheme);
    return (
      <ThemeProvider theme={createMuiTheme(themes[this.props.currentTheme])}>
        <CssBaseline />
        <AppRouter />
      </ThemeProvider>
    );
  }
}

export default connect(mapStateToProps)(ThemedContent);
