import React from 'react';
import { withTheme, Theme } from '@material-ui/core';

export interface LegalNoticePageProps {
  theme?: Theme;
}

export interface LegalNoticePageState {}

class LegalNoticePage extends React.Component<LegalNoticePageProps, LegalNoticePageState> {
  constructor(props: LegalNoticePageProps) {
    super(props);
  }
  render() {
    return (
      <div style={{ padding: 16, color: this.props.theme!.palette.text.primary }}>
        <h1>About this Website</h1>
        <h3>Contact</h3>
        <p>Lukas Marx</p>
        <p>Auf der Kirchwiese 2</p>
        <p>46414 Rhede, Germany</p>
        <p>Phone: +49 174 3859441</p>
        <p>E-Mail: lukas@jukify.de</p>
        <h3>Responsible for content</h3>
        <p>Lukas Marx</p>{' '}
      </div>
    );
  }
}

export default withTheme(LegalNoticePage);
