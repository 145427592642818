import { ActionType } from 'typesafe-actions';
import * as actions from './../actions/playlist.action';
import { combineReducers } from 'redux';
import { FullPlaylist } from '../../models/Playlist';
import {
  PLAYLIST_FETCH_SUCCESS,
  PLAYLIST_FETCH_REQUEST,
  PLAYLIST_FETCH_ERROR,
} from '../constants/general.constants';
import { HubError } from '../../models/HubError';
import { LoadingState } from '../../models/LoadingState';

export type PlaylistState = {
  playlistById: { [albumId: string]: FullPlaylist };
  loadingStateById: { [albumId: string]: LoadingState };
  errorById: { [albumId: string]: HubError | null };
};

export type PlaylistAction = ActionType<typeof actions>;

export const playlist = combineReducers<PlaylistState, PlaylistAction>({
  playlistById: (state = {}, action) => {
    switch (action.type) {
      case PLAYLIST_FETCH_SUCCESS: {
        const newState = Object.assign({}, state);
        newState[action.payload.id] = action.payload;
        return newState;
      }
      default:
        return state;
    }
  },
  loadingStateById: (state = {}, action) => {
    switch (action.type) {
      case PLAYLIST_FETCH_REQUEST: {
        const newState = Object.assign({}, state);
        newState[action.payload] = LoadingState.LOADING;
        return newState;
      }
      case PLAYLIST_FETCH_SUCCESS: {
        const newState = Object.assign({}, state);
        newState[action.payload.id] = LoadingState.SUCCESS;
        return newState;
      }
      case PLAYLIST_FETCH_ERROR: {
        const newState = Object.assign({}, state);
        newState[action.payload.id] = LoadingState.ERROR;
        return newState;
      }
      default:
        return state;
    }
  },
  errorById: (state = {}, action) => {
    switch (action.type) {
      case PLAYLIST_FETCH_REQUEST: {
        const newState = Object.assign({}, state);
        newState[action.payload] = null;
        return newState;
      }
      case PLAYLIST_FETCH_SUCCESS: {
        const newState = Object.assign({}, state);
        newState[action.payload.id] = null;
        return newState;
      }
      case PLAYLIST_FETCH_ERROR: {
        const newState = Object.assign({}, state);
        newState[action.payload.id] = action.payload.err;
        return newState;
      }
      default:
        return state;
    }
  },
});
