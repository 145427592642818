import { singalRService } from '../../services/singalR.service';
import { Initialization } from '../../../models/Initialization';
import { sessionsChange } from '../../actions/session.action';
import { put } from 'redux-saga/effects';
import { sessionUsersChanged } from '../../actions/sessionUsers.action';
import { initializeSuccess, initializeError } from '../../actions/general.action';
import { HubError } from '../../../models/HubError';

export function* sendInitializeAppRequestToServer() {
  try {
    const initialization: Initialization = yield singalRService.invoke('Initialize');
    yield put(sessionsChange(initialization.sessions));
    yield put(sessionUsersChanged(initialization.sessionUserLists));
    yield put(initializeSuccess());
  } catch (err) {
    put(initializeError(new HubError(err)));
  }
}

export async function establishServerConnection(token: string) {
  await singalRService.connect(token);
}

export function writeThemeToLocalStorage(theme: string) {
  window.localStorage.setItem('theme', theme);
}
