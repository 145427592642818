import React from 'react';
import styles from './Player2.module.css';
import { withTheme, Theme, LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import { currentSessionSelector } from '../../../redux/selectors/session/currentSession.selector';
import { isOwnerSelector } from '../../../redux/selectors/session/isOwner.selector';
import autobind from 'autobind-decorator';
import { Session } from '../../../models/Session';
import { Track } from '../../../models/Track';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Pause from '@material-ui/icons/Pause';
import { store } from '../../../redux/store';
import { togglePaused } from '../../../redux/actions/spotify.action';
import VinylIllustration from '../../illustrations/Vinyl';
import PlayerArm from '../../illustrations/VinylWithPlayerArm';
import VinylWithPlayerArm from '../../illustrations/VinylWithPlayerArm';

export interface PlayerProps {
  theme?: Theme;
  currentSession?: Session;
  isOwner?: boolean;
}

export interface PlayerState {
  now: Date;
}

const mapStateToProps = (state: AppState) => {
  return {
    isOwner: isOwnerSelector(state),
    currentSession: currentSessionSelector(state),
  };
};

class Player extends React.Component<PlayerProps, PlayerState> {
  private interval: any;

  constructor(props: PlayerProps) {
    super(props);

    this.state = { now: new Date() };
  }

  @autobind
  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({ now: new Date() });
    }, 50);
  }

  @autobind
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  formatSeconds(secs: number) {
    const sec_num = secs / 1000;

    let minutes = Math.floor(sec_num / 60);
    let seconds = Math.floor(sec_num - minutes * 60);

    let minutesString: string = '00';
    let secondsString: string = '00';

    if (minutes < 10) {
      minutesString = '0' + minutes;
    } else {
      minutesString = minutes.toString();
    }
    if (seconds < 10) {
      secondsString = '0' + seconds;
    } else {
      secondsString = seconds.toString();
    }

    return minutesString + ':' + secondsString;
  }

  @autobind
  calculateProgressMs() {
    const session = this.props.currentSession;
    if (session) {
      if (session.startedAt && session.trackQueue.length > 0) {
        if (session.paused) {
          return session.trackPosition;
        } else {
          const position =
            this.state.now.getTime() -
            new Date(session.startedAt).getTime() +
            session.trackPosition;
          if (position > session.trackQueue[0].duration_ms) {
            return session.trackQueue[0].duration_ms;
          } else {
            return position;
          }
        }
      }
    }
    return 0;
  }

  @autobind
  renderPlayIcon() {
    if (this.props.isOwner) {
      if (this.props.currentSession && this.props.currentSession.paused) {
        return <PlayArrow className={styles.playIcon} style={{ color: 'white' }} />;
      } else {
        return <Pause className={styles.playIcon} style={{ color: 'white' }} />;
      }
    } else {
      if (this.props.currentSession && this.props.currentSession.paused) {
        return <PlayArrow className={styles.playIcon} style={{ color: 'white' }} />;
      } else {
        return <Pause className={styles.playIcon} style={{ color: 'white' }} />;
      }
    }
  }

  @autobind
  renderPlayButton() {
    if (this.props.isOwner) {
      return (
        <div
          className={styles.whitespace}
          style={{ background: this.props.theme!.palette.background.default }}
          onClick={() => store.dispatch(togglePaused())}
        >
          <div
            className={styles.playWrapper}
            style={{
              background: `linear-gradient(to right, ${this.props.theme!.palette.primary.main}, ${
                this.props.theme!.palette.primary.dark
              })`,
            }}
          >
            {this.renderPlayIcon()}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={styles.whitespace}
          style={{ backgroundColor: this.props.theme!.palette.background.default }}
        >
          <div
            className={styles.playWrapper}
            style={{ backgroundColor: this.props.theme!.palette.primary.light }}
          >
            {this.renderPlayIcon()}
          </div>
        </div>
      );
    }
  }

  render() {
    const session = this.props.currentSession;
    const progress = this.calculateProgressMs();
    const color = this.props.theme!.palette.primary.main;
    const paused = !this.props.currentSession || this.props.currentSession.paused;

    let percentage = 0;

    if (session && session.startedAt && session.trackQueue.length > 0) {
      const trackDuration = session.trackQueue[0].duration_ms;
      percentage = (this.calculateProgressMs() / trackDuration) * 100;
      if (percentage > 100) {
        percentage = 100;
      }
      if (percentage < 0) {
        percentage = 0;
      }
    }

    return (
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <div className={styles.player}>
            <VinylWithPlayerArm progress={progress} spinning={!paused}></VinylWithPlayerArm>
            <div className={styles.controls}>{this.renderPlayButton()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withTheme(Player));
