import spotifySaga from './spotify/spotify.saga';
import sessionSaga from './session/session.saga';
import playlistSaga from './playlist/playlist.saga';
import searchSaga from './search/search.saga';
import sessionUserSaga from './sessionUser/sessionUser.saga';
import generalSaga from './general/general.saga';
import authenticationSaga from './authentication/authentication.saga';

import { all, fork, put } from 'redux-saga/effects';
import { singalRService } from '../services/singalR.service';
import { HubConnection } from '@aspnet/signalr';
import { sessionChange, leaveSessionSuccess } from '../actions/session.action';
import { sessionUsersChangedForSession } from '../actions/sessionUsers.action';
import { userPlaylistChangedPlaylist } from '../actions/userPlaylist.action';
import { SessionUserList } from '../../models/SessionUserList';
import { Session } from '../../models/Session';
import { store } from '../store';
import signalRSaga from './signalR/signalR.saga';
import { spotifyAsyncDevicesUpdate } from '../actions/spotify.action';
import { Device } from '../../models/Device';

const registerCallback = (connection: HubConnection) => {
  connection.on('onSessionUpdate', (data: Session) => {
    store.dispatch(sessionChange(data));
  });
  connection.on('onSessionDelete', (sessionId: string) => {
    store.dispatch(leaveSessionSuccess(sessionId));
  });
  connection.on('onSessionUsersUpdate', (sessionUserList: SessionUserList) => {
    store.dispatch(sessionUsersChangedForSession(sessionUserList));
  });
  connection.on('onDeviceListUpdate', (devices: Device[]) => {
    store.dispatch(spotifyAsyncDevicesUpdate(devices));
  });
  connection.on('onUserPlaylistUpdate', (userIds: string[]) => {
    store.dispatch(userPlaylistChangedPlaylist(userIds));
  })
};

singalRService.register(registerCallback);

export default function* rootSaga() {
  yield all([
    fork(generalSaga),
    fork(spotifySaga),
    fork(sessionSaga),
    fork(playlistSaga),
    fork(searchSaga),
    fork(sessionUserSaga),
    fork(authenticationSaga),
    fork(signalRSaga),
  ]);
}
