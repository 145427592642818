import { SearchResult } from '../../../models/SearchResult';
import { singalRService } from '../../services/singalR.service';
import { put } from 'redux-saga/effects';
import { fetchSearchSuccess, fetchSearchError } from '../../actions/search.action';
import { FullAlbum } from '../../../models/Album';
import { fetchAlbumSuccess, fetchAlbumError } from '../../actions/album.action';
import { FullPlaylist } from '../../../models/Playlist';
import { fetchPlaylistError, fetchPlaylistSuccess } from '../../actions/playlist.action';
import { store } from '../../store';
import { HubError } from '../../../models/HubError';

export function* sendSearchRequestToServer(query: string) {
  const searchResult: SearchResult = yield singalRService.invoke('Search', query);
  yield put(fetchSearchSuccess(searchResult));
}

export function* sendUserSpecificSearchRequestToServer(query: string) {
  const { authentication } = store.getState();
  try {
    if (authentication && authentication.user) {
      const searchResult: SearchResult = yield singalRService.invoke(
        'SearchWithUserPlaylists',
        query
      );
      yield put(fetchSearchSuccess(searchResult));
    }
  } catch (err) {
    yield put(fetchSearchError(new HubError(err)));
  }
}

export function* sendAlbumRequestToServer(albumId: string) {
  try {
    const fullAlbum: FullAlbum = yield singalRService.invoke('GetAlbum', albumId);
    yield put(fetchAlbumSuccess(fullAlbum));
  } catch (err) {
    yield put(fetchAlbumError(albumId, new HubError(err)));
  }
}

export function* sendPlaylistRequestToServer(playlistId: string) {
  try {
    const fullPlaylist: FullPlaylist = yield singalRService.invoke('GetPlaylist', playlistId);
    yield put(fetchPlaylistSuccess(fullPlaylist));
  } catch (err) {
    yield put(fetchPlaylistError(playlistId, new HubError(err)));
  }
}
