import { action } from 'typesafe-actions';

import { SessionUserList } from '../../models/SessionUserList';
import {
  SESSIONUSERS_CHANGED,
  SESSIONUSER_UPDATE_PERCENTAGE_SUCCESS,
  SESSIONUSER_UPDATE_PERCENTAGE_REQUEST,
  SESSIONUSER_UPDATE_PERCENTAGE_ERROR,
} from '../constants/sessionUser.constants';
import { HubError } from '../../models/HubError';

export const sessionUsersChanged = (sessionUsersList: SessionUserList[]) =>
  action(SESSIONUSERS_CHANGED, sessionUsersList);

export const sessionUsersChangedForSession = (sessionUsersList: SessionUserList) =>
  action(SESSIONUSER_UPDATE_PERCENTAGE_SUCCESS, sessionUsersList);

export const sessionUserUpdatePercentageRequest = (input: {
  sessionId: string;
  userId: string;
  percentage: number;
}) => action(SESSIONUSER_UPDATE_PERCENTAGE_REQUEST, input);

export const sessionUserUpdatePercentageSuccess = () =>
  action(SESSIONUSER_UPDATE_PERCENTAGE_SUCCESS);

export const sessionUserUpdatePercentageError = (err: HubError) =>
  action(SESSIONUSER_UPDATE_PERCENTAGE_ERROR, err);
