import { singalRService } from '../../services/singalR.service';
import { Device } from '../../../models/Device';
import { put } from 'redux-saga/effects';
import {
  spotifyFetchDevicesSuccess,
  spoitfyPlaySuccess,
  spoitfyPlayError,
  spotifyPauseSuccess,
  spotifyPauseError,
  spotifyFetchDevicesError,
} from '../../actions/spotify.action';
import { HubError } from '../../../models/HubError';
import { PLAYBACK_DEVICE_NOT_FOUND, PLAYBACK_NOT_ENOUGH_SONGS } from '../../../constants';
import { enqueueSnackbar } from '../../actions/notification.action';
import { localisation } from '../../../i18n/LanguageService';

export function* sendPlayToServer(sessionId: string, deviceId: string) {
  try {
    yield singalRService.invoke('Play', sessionId, deviceId);
    yield put(spoitfyPlaySuccess());
  } catch (err) {
    yield put(spoitfyPlayError(new HubError(err)));
  }
}

export function* sendPauseToServer(sessionId: string) {
  try {
    yield singalRService.invoke('Pause', sessionId);
    yield put(spotifyPauseSuccess());
  } catch (err) {
    yield put(spotifyPauseError(new HubError(err)));
  }
}

export function* fetchDeviceIdsFromServer(sessionId: string) {
  try {
    const devices: Device[] = yield singalRService.invoke('GetDevices', sessionId);
    yield put(spotifyFetchDevicesSuccess(devices));
  } catch (err) {
    yield put(spotifyFetchDevicesError(new HubError(err)));
  }
}

export function* handlePlayError(err: HubError) {
  switch (err.code) {
    case PLAYBACK_DEVICE_NOT_FOUND: {
      yield showErrorNotification(localisation.errors.spotify.play.deviceNotFound);
      break;
    }
    case PLAYBACK_NOT_ENOUGH_SONGS: {
      yield showErrorNotification(localisation.errors.spotify.play.notEnoughSongs);
      break;
    }
    default: {
      yield showErrorNotification(localisation.errors.spotify.play.default);
    }
  }
}

export function* showErrorNotification(message: string) {
  yield put(
    enqueueSnackbar({
      message,
      options: {
        variant: 'error',
      },
      preventDuplicate: true,
    })
  );
}
