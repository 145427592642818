import React from 'react';
import createRef from 'react';
import styles from './AddAlbumDialog.module.css';
import { Dialog, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import autobind from 'autobind-decorator';
import { Track } from '../../../models/Track';
import { connect } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import { FullAlbum } from '../../../models/Album';
import { store } from '../../../redux/store';
import { fetchAlbumRequest } from '../../../redux/actions/album.action';
import SelectableTrackList from './SelectableTrackList';
import { localisation } from '../../../i18n/LanguageService';

export interface AddAlbumDialogProps {
  open: boolean;
  albumId?: string;
  onClose: (track?: Track[]) => void;
  albums: { [albumId: string]: FullAlbum };
}

export interface AddAlbumDialogState {
  selectDeselectAllText: string;
}

const mapStateToProps = (state: AppState) => {
  return {
    albums: state.album.albumById,
  };
};

class AddAlbumDialog extends React.Component<AddAlbumDialogProps, AddAlbumDialogState> {
  private selectedTracks: Track[] = [];
  private selectableTrackList: React.RefObject<SelectableTrackList> = React.createRef<SelectableTrackList>();

  constructor(props: AddAlbumDialogProps) {
    super(props);

    this.state = {
      selectDeselectAllText: localisation.strings.addDialogDeselectAll
    };
  }

  @autobind
  handleCloseConfirm() {
    if (this.selectedTracks.length > 0) this.props.onClose(this.selectedTracks);
    else this.props.onClose();
  }

  @autobind
  handleCloseCancel() {
    this.props.onClose();
  }

  @autobind
  handleSelectedTracksChange(trackIds: Track[]) {
    this.selectedTracks = trackIds

    if (this.selectedTracks.length > 0) {
      this.setState({ selectDeselectAllText: localisation.strings.addDialogDeselectAll });
    } else {
      this.setState({ selectDeselectAllText: localisation.strings.addDialogSelectAll })
    }
  }

  @autobind
  handleSelectDeselectAllClick() {
    if (this.selectedTracks.length > 0) {
      this.selectableTrackList.current!.deselectAll();
    } else {
      this.selectableTrackList.current!.selectAll();
    }
  }

  @autobind
  renderDialogContent() {
    if (this.props.albumId && this.props.albums[this.props.albumId]) {
      const album = this.props.albums[this.props.albumId];
      return (
        <>
          <div className={styles.scrollContainer}>
            <div className={styles.thumbnailWrapper}>
              <img src={album.thumbnail} className={styles.thumbnail} />
            </div>
            <div style={{ padding: '8px' }}>
              <Typography
                color="primary"
                style={{ textAlign: 'center' }}
                variant="h6"
                noWrap={true}
              >
                {album.name}
              </Typography>
              <Typography
                color="textSecondary"
                style={{ textAlign: 'center' }}
                variant="subtitle2"
                noWrap={true}
              >
                {album.artist}
              </Typography>
            </div>
            {album.tracks && (
              <SelectableTrackList
                tracks={album.tracks}
                onSelectedTracksChange={this.handleSelectedTracksChange}
                ref={this.selectableTrackList}
              />
            )}
          </div>
          <DialogActions className={styles.actions}>
            <Button 
              className={styles.selectDeselectButton}
              onClick={this.handleSelectDeselectAllClick}
              color="primary"
              >
              {this.state.selectDeselectAllText}
            </Button>
            <Button onClick={this.handleCloseCancel} color="primary">
              {localisation.strings.searchCancel}
            </Button>
            <Button variant="contained" onClick={this.handleCloseConfirm} color="primary">
              {localisation.strings.searchAddTracks}
            </Button>
          </DialogActions>
        </>
      );
    }
    return <div />;
  }

  render() {
    if (this.props.albumId && !this.props.albums[this.props.albumId]) {
      store.dispatch(fetchAlbumRequest(this.props.albumId));
    }
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleCloseCancel}
        aria-labelledby="form-dialog-title"
      >
        {this.renderDialogContent()}
      </Dialog>
    );
  }
}

export default connect(mapStateToProps)(AddAlbumDialog);
