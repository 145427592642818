import React, { ChangeEvent } from 'react';
import {
  Dialog,
  AppBar,
  Grid,
  IconButton,
  InputBase,
  Toolbar,
  Typography,
  withTheme,
  Theme,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import autobind from 'autobind-decorator';
import ArrowBack from '@material-ui/icons/ArrowBack';
import styles from './Search.module.css';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import { SearchResult } from '../../../models/SearchResult';
import { store } from '../../../redux/store';
import { fetchSearchRequest } from '../../../redux/actions/search.action';
import AlbumList from './AlbumList';
import PlaylistList from './PlaylistList';
import { Track } from '../../../models/Track';
import SearchIllustration from '../../illustrations/Search';
import { localisation } from '../../../i18n/LanguageService';
import QuestionIllustration from '../../illustrations/Question';
import { Playlist } from '../../../models/Playlist';
import { Album } from '../../../models/Album';
import AddAlbumDialog from './AddAlbumDialog';
import { userPlaylistAddTracksRequest } from '../../../redux/actions/userPlaylist.action';
import AddPlaylistDialog from './AddPlaylistDialog';
import { Session } from '../../../models/Session';
import TrackList from './TrackList';
import { LoadingState } from '../../../models/LoadingState';
import { DeviceSize } from '../../../redux/reducers/general.reducer';

const styleOverride = {
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
};

export interface SearchProps {
  open: boolean;
  onClose: (sessionId?: string) => void;
  searchResult?: SearchResult;
  userDidSearch: boolean;
  searchLoadingState: LoadingState;
  theme?: Theme;
  deviceSize: DeviceSize;
  classes?: any;
}

export interface SearchState {
  isAlbumDialogOpen: boolean;
  isPlaylistDialogOpen: boolean;
  currentlyViewedAlbumId?: string;
  currentlyViewedPlaylistId?: string;
}

const mapStateToProps = (state: AppState) => {
  return {
    searchResult: state.search.result || undefined,
    userDidSearch: state.search.userDidSearch,
    searchLoadingState: state.search.loadingState,
    deviceSize: state.general.deviceSize,
  };
};

class Search extends React.Component<SearchProps, SearchState> {
  focusSearchInputField = input => {
    input && input.focus();
  };

  constructor(props: SearchProps) {
    super(props);
    this.state = {
      isAlbumDialogOpen: false,
      isPlaylistDialogOpen: false,
    };
  }

  @autobind
  handleClose() {
    this.props.onClose();
  }

  @autobind
  onBack() {
    this.props.onClose();
  }

  timeoutId;
  @autobind
  search(evt: ChangeEvent<HTMLInputElement>) {
    if (this.timeoutId) clearTimeout(this.timeoutId);
    const search = evt.currentTarget.value;
    if (search === '') {
    } else {
      this.timeoutId = setTimeout(() => {
        store.dispatch(fetchSearchRequest(search));
      }, 500);
    }
  }

  @autobind
  addTracks(tracks?: Track[]) {
    this.setState({ isAlbumDialogOpen: false, isPlaylistDialogOpen: false });
    if (tracks) {
      store.dispatch(userPlaylistAddTracksRequest(tracks));
      this.props.onClose();
    }
  }

  @autobind
  addTrack(track: Track) {
    this.addTracks([track]);
  }

  @autobind
  openAlbumDialog(album: Album) {
    this.setState({
      isAlbumDialogOpen: true,
      currentlyViewedAlbumId: album.id,
    });
  }

  @autobind
  openPlaylistDialog(playlist: Playlist) {
    this.setState({
      isPlaylistDialogOpen: true,
      currentlyViewedPlaylistId: playlist.id,
    });
  }

  @autobind
  renderMyPlaylists() {
    if (
      this.props.searchResult &&
      this.props.searchResult.userPlaylists &&
      this.props.searchResult.userPlaylists.length > 0
    ) {
      return (
        <>
          <Typography variant="h6" gutterBottom color="primary" className={styles.subHeading}>
            {localisation.strings.searchResultMyPlaylists}
          </Typography>
          <PlaylistList
            playlists={this.props.searchResult ? this.props.searchResult.userPlaylists : undefined}
            onClick={this.openPlaylistDialog}
          />
        </>
      );
    }
  }

  @autobind
  renderAlbums() {
    if (
      this.props.searchResult &&
      this.props.searchResult.albums &&
      this.props.searchResult.albums.length > 0
    ) {
      return (
        <>
          <Typography variant="h6" gutterBottom color="primary" className={styles.subHeading}>
            {localisation.strings.searchResultAlbums}
          </Typography>
          <AlbumList
            albums={this.props.searchResult ? this.props.searchResult.albums : undefined}
            onClick={this.openAlbumDialog}
          />
        </>
      );
    }
  }

  @autobind
  renderPlaylists() {
    if (
      this.props.searchResult &&
      this.props.searchResult.playlists &&
      this.props.searchResult.playlists.length > 0
    ) {
      return (
        <>
          <Typography variant="h6" gutterBottom color="primary" className={styles.subHeading}>
            {localisation.strings.searchResultPlaylists}
          </Typography>
          <PlaylistList
            playlists={this.props.searchResult ? this.props.searchResult.playlists : undefined}
            onClick={this.openPlaylistDialog}
          />
        </>
      );
    }
  }

  @autobind
  renderTracks() {
    if (
      this.props.searchResult &&
      this.props.searchResult.tracks &&
      this.props.searchResult.tracks.length > 0
    ) {
      return (
        <>
          <Typography variant="h6" gutterBottom color="primary" className={styles.subHeading}>
            {localisation.strings.searchResultTracks}
          </Typography>
          <TrackList
            tracks={this.props.searchResult ? this.props.searchResult.tracks : undefined}
            onClick={this.addTrack}
          />
        </>
      );
    }
  }

  @autobind
  renderSearchResult() {
    const searchResult = this.props.searchResult;
    if (
      (this.props.searchLoadingState === LoadingState.SUCCESS ||
        this.props.searchLoadingState === LoadingState.LOADING) &&
      searchResult &&
      searchResult.albums &&
      searchResult.albums.length > 0 &&
      searchResult.playlists &&
      searchResult.playlists.length > 0 &&
      searchResult.tracks &&
      searchResult.tracks.length > 0
    ) {
      return (
        <div className={styles.searchResult}>
          {this.renderMyPlaylists()}

          {this.renderAlbums()}

          {this.renderPlaylists()}

          {this.renderTracks()}

          <AddAlbumDialog
            open={this.state.isAlbumDialogOpen}
            onClose={this.addTracks}
            albumId={this.state.currentlyViewedAlbumId}
          />
          <AddPlaylistDialog
            open={this.state.isPlaylistDialogOpen}
            onClose={this.addTracks}
            playlistId={this.state.currentlyViewedPlaylistId}
          />
        </div>
      );
    }
    if (
      !this.props.userDidSearch ||
      !searchResult || this.props.searchLoadingState === LoadingState.LOADING
    ) {
      return (
        <div className={styles.searchInstructions}>
          <Grid item className={styles.item} xs={12} md={6}>
            <SearchIllustration color={this.props.theme!.palette.primary.main} />
            <Typography variant="h6" gutterBottom color="primary">
              {localisation.strings.searchInstructionsTitle}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" style={{ textAlign: 'center' }}>
              {localisation.strings.searchInstructionsMessage}
            </Typography>
          </Grid>
        </div>
      );
    }

    return (
      <div className={styles.notFound}>
        <Grid item className={styles.item} xs={12} md={6}>
          <QuestionIllustration color={this.props.theme!.palette.primary.main} />
          <Typography variant="h6" gutterBottom color="primary">
            {localisation.strings.searchNotFoundTitle}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" style={{ textAlign: 'center' }}>
            {localisation.strings.searchNotFoundMessage}
          </Typography>
        </Grid>
      </div>
    );
  }

  render() {
    return (
      <Dialog
        fullScreen={this.props.deviceSize <= DeviceSize.TABLET}
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
        classes={{
          paper: this.props.deviceSize > DeviceSize.TABLET ? this.props.classes!.dialogPaper : null,
        }}
      >
        <AppBar position="static" className={styles.appbar} color="transparent">
          <Toolbar className={styles.toolbar}>
            <IconButton color="inherit" aria-label="Menu" onClick={this.onBack}>
              <ArrowBack />
            </IconButton>

            <div className={styles.search}>
              <div className={styles.searchIcon}>
                {this.props.searchLoadingState === LoadingState.LOADING ? (
                  <CircularProgress className={styles.progress} color="secondary" />
                ) : (
                  <SearchIcon />
                )}
              </div>
              <InputBase
                placeholder={localisation.strings.search}
                spellCheck={false}
                inputRef={this.focusSearchInputField}
                classes={{
                  root: styles.inputRoot,
                  input: styles.inputInput,
                }}
                onChange={this.search}
              />
            </div>
          </Toolbar>
        </AppBar>
        {this.renderSearchResult()}
      </Dialog>
    );
  }
}

export default withStyles(styleOverride)(withTheme(connect(mapStateToProps)(Search)));
