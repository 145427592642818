export const USERPLAYLIST_FETCH_REQUEST = 'FETCH_USERPLAYLIST_REQUEST';
export const USERPLAYLIST_FETCH_SUCCESS = 'FETCH_USERPLAYLIST_SUCCESS';
export const USERPLAYLIST_FETCH_ERROR = 'USERPLAYLIST_FETCH_ERROR';

export const USERPLAYLIST_ADD_TRACKS_REQUEST = 'USERPLAYLIST_ADD_TRACKS_REQUEST';
export const USERPLAYLIST_ADD_TRACKS_SUCCESS = 'USERPLAYLIST_ADD_TRACKS_SUCCESS';
export const USERPLAYLIST_ADD_TRACKS_ERROR = 'USERPLAYLIST_ADD_TRACKS_ERROR';

export const USERPLAYLIST_REMOVE_TRACK_REQUEST = 'USERPLAYLIST_REMOVE_TRACK_REQUEST';
export const USERPLAYLIST_REMOVE_TRACK_SUCCESS = 'USERPLAYLIST_REMOVE_TRACK_SUCCESS';
export const USERPLAYLIST_REMOVE_TRACK_ERROR = 'USERPLAYLIST_REMOVE_TRACK_ERROR';

export const USERPLAYLIST_CHANGED_PLAYLIST = 'USERPLAYLIST_CHANGED_PLAYLIST';

export const USERPLAYLIST_SONG_MOVED_ONE = 'USERPLAYLIST_SONG_MOVED_ONE';
export const USERPLAYLIST_SONG_MOVED_TOP_BOTTOM = 'USERPLAYLIST_SONG_MOVED_TOP_BOTTOM';
export const USERPLAYLIST_SHUFFLE = 'USERPLAYLIST_SHUFFLE';
export const USERPLAYLIST_EMPTY_PLAYLIST = 'USERPLAYLIST_EMPTY_PLAYLIST';