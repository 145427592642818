import { ActionType } from 'typesafe-actions';
import * as actions from './../actions/authentication.action';
import { combineReducers } from 'redux';
import { TokenDto } from '../../interfaces/Token.dto';
import { User } from '../../models/User';
import { USER_SIGNED_IN, USER_SIGNED_OUT, SIGNOUT } from '../constants/authentication.constants';

export type AuthenticationState = {
  token: TokenDto | null;
  user: User | null;
};

// Union of all authentication action types
export type AuthenticationAction = ActionType<typeof actions>;

export const authentication = combineReducers<AuthenticationState, AuthenticationAction>({
  token: (state = null, action) => {
    switch (action.type) {
      case USER_SIGNED_IN:
        return action.payload;

      case USER_SIGNED_OUT:
        return null;

      case SIGNOUT:
        return null;

      default:
        return state;
    }
  },
  user: (state = null, action) => {
    switch (action.type) {
      case USER_SIGNED_IN:
        const token = action.payload.token;

        const user = parseJwt(token);

        return user;

      case USER_SIGNED_OUT:
        return null;

      case SIGNOUT:
        return null;

      default:
        return state;
    }
  },
});

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}
