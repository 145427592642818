import React from 'react';
import { useTheme } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export interface SearchIllustrationProps {
  color: string;
}

export interface SearchIllustrationState {}

function SearchIllustration(props: SearchIllustrationProps) {
  const theme = useTheme() as Theme;
  const color1 = theme.palette.primary.dark;
  const color2 = theme.palette.primary.main;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <defs>
        <clipPath id="a">
          <path d="M0 0h500v500H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path fill="none" d="M0 0h500v500H0z" />
        <clipPath id="b">
          <path fill="#FFF" d="M49.47 172.44h277.44v305.55H49.47z" />
        </clipPath>
        <g clipPath="url(#b)">
          <path fill="none" d="M49.47 172.44h277.44v305.55H49.47z" />
          <path
            d="M49.47 334.1c0-76.57 62.15-138.73 138.72-138.73 76.56 0 138.71 62.16 138.71 138.72S264.75 472.81 188.2 472.81c-76.57 0-138.72-62.16-138.72-138.72zm131.66 0a7.06 7.06 0 1114.11 0 7.06 7.06 0 01-14.1 0z"
            fillRule="evenodd"
            fill="#050409"
          />
          <path
            d="M54.17 334.1c0-73.97 60.05-134.02 134.02-134.02 73.96 0 134.01 60.05 134.01 134.01 0 73.97-60.05 134.02-134.01 134.02-73.97 0-134.02-60.05-134.02-134.02zm126.96 0a7.06 7.06 0 1114.11 0 7.06 7.06 0 01-14.1 0z"
            fillRule="evenodd"
            fill="#0B0916"
            vectorEffect="non-scaling-stroke"
            strokeWidth="2"
            stroke="#050409"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="c" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M72.98 334.1c0-63.6 51.62-115.22 115.2-115.22 63.59 0 115.21 51.63 115.21 115.21 0 63.59-51.62 115.2-115.2 115.2-63.59 0-115.21-51.62-115.21-115.2z" />
          </mask>
          <path
            d="M72.98 334.1c0-63.6 51.62-115.22 115.2-115.22 63.59 0 115.21 51.63 115.21 115.21 0 63.59-51.62 115.2-115.2 115.2-63.59 0-115.21-51.62-115.21-115.2z"
            fill="none"
          />
          <path
            d="M72.98 334.1c0-63.6 51.62-115.22 115.2-115.22 63.59 0 115.21 51.63 115.21 115.21 0 63.59-51.62 115.2-115.2 115.2-63.59 0-115.21-51.62-115.21-115.2z"
            fill="none"
            mask="url(#c)"
            vectorEffect="non-scaling-stroke"
            stroke="#050409"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="d" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M62.78 334.1c0-69.22 56.2-125.41 125.4-125.41 69.22 0 125.4 56.19 125.4 125.4 0 69.21-56.18 125.4-125.4 125.4-69.2 0-125.4-56.19-125.4-125.4z" />
          </mask>
          <path
            d="M62.78 334.1c0-69.22 56.2-125.41 125.4-125.41 69.22 0 125.4 56.19 125.4 125.4 0 69.21-56.18 125.4-125.4 125.4-69.2 0-125.4-56.19-125.4-125.4z"
            fill="none"
          />
          <path
            d="M62.78 334.1c0-69.22 56.2-125.41 125.4-125.41 69.22 0 125.4 56.19 125.4 125.4 0 69.21-56.18 125.4-125.4 125.4-69.2 0-125.4-56.19-125.4-125.4z"
            fill="none"
            mask="url(#d)"
            vectorEffect="non-scaling-stroke"
            stroke="#050409"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="e" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M92.96 334.1c0-52.56 42.67-95.23 95.23-95.23 52.55 0 95.22 42.67 95.22 95.22 0 52.56-42.67 95.22-95.23 95.22-52.55 0-95.22-42.66-95.22-95.22z" />
          </mask>
          <path
            d="M92.96 334.1c0-52.56 42.67-95.23 95.23-95.23 52.55 0 95.22 42.67 95.22 95.22 0 52.56-42.67 95.22-95.23 95.22-52.55 0-95.22-42.66-95.22-95.22z"
            fill="none"
          />
          <path
            d="M92.96 334.1c0-52.56 42.67-95.23 95.23-95.23 52.55 0 95.22 42.67 95.22 95.22 0 52.56-42.67 95.22-95.23 95.22-52.55 0-95.22-42.66-95.22-95.22z"
            fill="none"
            mask="url(#e)"
            vectorEffect="non-scaling-stroke"
            stroke="#050409"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="f" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M82.76 334.1c0-58.2 47.24-105.43 105.42-105.43 58.2 0 105.43 47.23 105.43 105.42 0 58.19-47.24 105.43-105.43 105.43-58.18 0-105.42-47.24-105.42-105.43z" />
          </mask>
          <path
            d="M82.76 334.1c0-58.2 47.24-105.43 105.42-105.43 58.2 0 105.43 47.23 105.43 105.42 0 58.19-47.24 105.43-105.43 105.43-58.18 0-105.42-47.24-105.42-105.43z"
            fill="none"
          />
          <path
            d="M82.76 334.1c0-58.2 47.24-105.43 105.42-105.43 58.2 0 105.43 47.23 105.43 105.42 0 58.19-47.24 105.43-105.43 105.43-58.18 0-105.42-47.24-105.42-105.43z"
            fill="none"
            mask="url(#f)"
            vectorEffect="non-scaling-stroke"
            stroke="#050409"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="g" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M115.3 334.1a72.92 72.92 0 0172.89-72.9 72.92 72.92 0 0172.88 72.9 72.92 72.92 0 01-72.88 72.88 72.92 72.92 0 01-72.9-72.89z" />
          </mask>
          <path
            d="M115.3 334.1a72.92 72.92 0 0172.89-72.9 72.92 72.92 0 0172.88 72.9 72.92 72.92 0 01-72.88 72.88 72.92 72.92 0 01-72.9-72.89z"
            fill="none"
          />
          <path
            d="M115.3 334.1a72.92 72.92 0 0172.89-72.9 72.92 72.92 0 0172.88 72.9 72.92 72.92 0 01-72.88 72.88 72.92 72.92 0 01-72.9-72.89z"
            fill="none"
            mask="url(#g)"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="h" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M124.93 334.1a63.28 63.28 0 0163.25-63.26 63.28 63.28 0 0163.26 63.25 63.29 63.29 0 01-63.25 63.26 63.29 63.29 0 01-63.26-63.26z" />
          </mask>
          <path
            d="M124.93 334.1a63.28 63.28 0 0163.25-63.26 63.28 63.28 0 0163.26 63.25 63.29 63.29 0 01-63.25 63.26 63.29 63.29 0 01-63.26-63.26z"
            fill="none"
          />
          <path
            d="M124.93 334.1a63.28 63.28 0 0163.25-63.26 63.28 63.28 0 0163.26 63.25 63.29 63.29 0 01-63.25 63.26 63.29 63.29 0 01-63.26-63.26z"
            fill="none"
            mask="url(#h)"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="i" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M104.21 334.1c0-46.35 37.63-83.98 83.98-83.98a84.01 84.01 0 0183.96 83.97c0 46.35-37.62 83.97-83.96 83.97a84.01 84.01 0 01-83.98-83.97z" />
          </mask>
          <path
            d="M104.21 334.1c0-46.35 37.63-83.98 83.98-83.98a84.01 84.01 0 0183.96 83.97c0 46.35-37.62 83.97-83.96 83.97a84.01 84.01 0 01-83.98-83.97z"
            fill="none"
          />
          <path
            d="M104.21 334.1c0-46.35 37.63-83.98 83.98-83.98a84.01 84.01 0 0183.96 83.97c0 46.35-37.62 83.97-83.96 83.97a84.01 84.01 0 01-83.98-83.97z"
            fill="none"
            mask="url(#i)"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <path
            d="M176.1 334.1H111.6a76.6 76.6 0 0076.58 76.56 76.6 76.6 0 0076.57-76.57h-64.48a12.1 12.1 0 01-24.19 0z"
            fill="#D7D7D7"
          />
          <path
            d="M200.28 334.1h64.48a76.6 76.6 0 00-76.57-76.58 76.6 76.6 0 00-76.58 76.57h64.48a12.1 12.1 0 0124.19 0z"
            fill="#FFF"
          />
          <circle vectorEffect="non-scaling-stroke" cx="188.18" cy="334.09" r="79.53" fill="none" />
          <clipPath id="j">
            <circle
              vectorEffect="non-scaling-stroke"
              cx="188.18"
              cy="334.09"
              r="79.53"
              fill="#FFF"
            />
          </clipPath>
          <g clipPath="url(#j)">
            <mask id="k">
              <circle
                vectorEffect="non-scaling-stroke"
                cx="188.18"
                cy="334.09"
                r="79.53"
                fill="#fff"
              />
            </mask>
            <circle
              vectorEffect="non-scaling-stroke"
              cx="188.18"
              cy="334.09"
              r="79.53"
              fill="none"
            />
            <linearGradient id="l" x1="0" y1=".5" x2="1" y2=".5">
              <stop offset="0%" stop-color={color2} />
              <stop offset="100%" stop-color={color1} />
            </linearGradient>
            <circle
              vectorEffect="non-scaling-stroke"
              cx="188.18"
              cy="334.09"
              r="79.53"
              fill="none"
              mask="url(#k)"
              strokeWidth="10"
              stroke="url(#l)"
              strokeLinecap="square"
              strokeMiterlimit="3"
            />
          </g>
          <clipPath id="m">
            <path fill="#FFF" d="M159.23 281.62h57.91v18.81h-57.91z" />
          </clipPath>
          <g clipPath="url(#m)">
            <path
              d="M170.05 291.72v-.6l1.69-.3v.82q.01.68.34 1.01.34.34.9.34.54 0 .87-.36.32-.35.32-.95v-6.2h1.75v6.2q0 1.24-.83 2.1-.82.87-2.1.87-1.33 0-2.13-.8-.8-.81-.8-2.13zm13.39 2.74h-1.6q-.06-.26-.06-.67-.24.41-.72.62-.47.21-1 .21-1.1 0-1.74-.7-.63-.7-.63-1.75v-3.94h1.68v3.6q0 .55.3.9.29.35.84.35t.86-.34q.32-.34.32-.9v-3.61h1.69v5.1q0 .6.06 1.13zm5.57-6.23h2.2l-2.49 2.62 2.55 3.6h-2.07l-1.64-2.37-.7.73v1.65h-1.69v-9.17h1.69v5.25l2.15-2.31zm4.93 0v6.23h-1.69v-6.23h1.69zm-1.89-2.04q0-.43.3-.74t.74-.31q.43 0 .73.3t.3.75q0 .42-.3.72t-.73.3q-.43 0-.74-.3-.3-.3-.3-.72zm6.57.5q-.35 0-.61.19-.26.2-.26.66v.69h1.4v1.44h-1.4v4.79h-1.7v-4.8h-1.04v-1.43h1.04v-.71q0-1.05.62-1.69.61-.63 1.65-.63.56 0 .84.13v1.41q-.22-.06-.54-.06zm7.7 1.54l-3.92 8.68h-1.79l1.47-3.24-2.65-5.44h1.9l1.65 3.64 1.53-3.64h1.8z"
              fillRule="evenodd"
              fill={color1}
            />
          </g>
        </g>
        <clipPath id="n">
          <path
            transform="rotate(41.32 270.47 314.12)"
            fill="#FFF"
            d="M131.67 161.45h277.44V467H131.67z"
          />
        </clipPath>
        <g clipPath="url(#n)">
          <path fill="none" d="M267 107.85l208.35 183.11L273.7 520.43 65.33 337.33z" />
          <path
            d="M160.32 229.34c50.53-57.52 138.25-63.19 195.77-12.65 57.51 50.53 63.18 138.25 12.65 195.76-50.54 57.52-138.26 63.19-195.77 12.65-57.52-50.53-63.18-138.25-12.65-195.76zm98.9 86.9a7.06 7.06 0 1110.6 9.32 7.06 7.06 0 01-10.6-9.32z"
            fillRule="evenodd"
            fill="#050409"
          />
          <path
            d="M163.85 232.44c48.82-55.57 133.57-61.04 189.13-12.22 55.57 48.82 61.04 133.57 12.22 189.13-48.81 55.56-133.56 61.04-189.13 12.22-55.56-48.82-61.04-133.57-12.22-189.13zm95.38 83.8a7.06 7.06 0 1110.6 9.32 7.06 7.06 0 01-10.6-9.32z"
            fillRule="evenodd"
            fill="#0B0916"
            vectorEffect="non-scaling-stroke"
            strokeWidth="2"
            stroke="#050409"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="o" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M177.98 244.85c41.97-47.76 114.82-52.47 162.59-10.5 47.76 41.97 52.47 114.82 10.5 162.59-41.96 47.76-114.82 52.47-162.58 10.5-47.77-41.97-52.48-114.82-10.5-162.59z" />
          </mask>
          <path
            d="M177.98 244.85c41.97-47.76 114.82-52.47 162.59-10.5 47.76 41.97 52.47 114.82 10.5 162.59-41.96 47.76-114.82 52.47-162.58 10.5-47.77-41.97-52.48-114.82-10.5-162.59z"
            fill="none"
          />
          <path
            d="M177.98 244.85c41.97-47.76 114.82-52.47 162.59-10.5 47.76 41.97 52.47 114.82 10.5 162.59-41.96 47.76-114.82 52.47-162.58 10.5-47.77-41.97-52.48-114.82-10.5-162.59z"
            fill="none"
            mask="url(#o)"
            vectorEffect="non-scaling-stroke"
            stroke="#050409"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="p" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M170.32 238.13c45.69-52 124.99-57.12 176.98-11.44 52 45.68 57.12 124.98 11.43 176.98-45.68 51.99-124.98 57.11-176.97 11.43-52-45.68-57.12-124.98-11.44-176.98z" />
          </mask>
          <path
            d="M170.32 238.13c45.69-52 124.99-57.12 176.98-11.44 52 45.68 57.12 124.98 11.43 176.98-45.68 51.99-124.98 57.11-176.97 11.43-52-45.68-57.12-124.98-11.44-176.98z"
            fill="none"
          />
          <path
            d="M170.32 238.13c45.69-52 124.99-57.12 176.98-11.44 52 45.68 57.12 124.98 11.43 176.98-45.68 51.99-124.98 57.11-176.97 11.43-52-45.68-57.12-124.98-11.44-176.98z"
            fill="none"
            mask="url(#p)"
            vectorEffect="non-scaling-stroke"
            stroke="#050409"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="q" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M193 258.05c34.68-39.49 94.9-43.38 134.38-8.69 39.48 34.69 43.37 94.9 8.68 134.38-34.69 39.49-94.9 43.38-134.38 8.69-39.48-34.69-43.37-94.9-8.69-134.38z" />
          </mask>
          <path
            d="M193 258.05c34.68-39.49 94.9-43.38 134.38-8.69 39.48 34.69 43.37 94.9 8.68 134.38-34.69 39.49-94.9 43.38-134.38 8.69-39.48-34.69-43.37-94.9-8.69-134.38z"
            fill="none"
          />
          <path
            d="M193 258.05c34.68-39.49 94.9-43.38 134.38-8.69 39.48 34.69 43.37 94.9 8.68 134.38-34.69 39.49-94.9 43.38-134.38 8.69-39.48-34.69-43.37-94.9-8.69-134.38z"
            fill="none"
            mask="url(#q)"
            vectorEffect="non-scaling-stroke"
            stroke="#050409"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="r" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M185.33 251.31c38.4-43.71 105.07-48.02 148.78-9.61 43.71 38.4 48.02 105.07 9.62 148.78-38.4 43.71-105.08 48.02-148.79 9.61-43.7-38.4-48.02-105.07-9.61-148.78z" />
          </mask>
          <path
            d="M185.33 251.31c38.4-43.71 105.07-48.02 148.78-9.61 43.71 38.4 48.02 105.07 9.62 148.78-38.4 43.71-105.08 48.02-148.79 9.61-43.7-38.4-48.02-105.07-9.61-148.78z"
            fill="none"
          />
          <path
            d="M185.33 251.31c38.4-43.71 105.07-48.02 148.78-9.61 43.71 38.4 48.02 105.07 9.62 148.78-38.4 43.71-105.08 48.02-148.79 9.61-43.7-38.4-48.02-105.07-9.61-148.78z"
            fill="none"
            mask="url(#r)"
            vectorEffect="non-scaling-stroke"
            stroke="#050409"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="s" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M209.77 272.79a72.92 72.92 0 01102.87-6.65A72.92 72.92 0 01319.28 369a72.92 72.92 0 01-102.86 6.65 72.92 72.92 0 01-6.65-102.86z" />
          </mask>
          <path
            d="M209.77 272.79a72.92 72.92 0 01102.87-6.65A72.92 72.92 0 01319.28 369a72.92 72.92 0 01-102.86 6.65 72.92 72.92 0 01-6.65-102.86z"
            fill="none"
          />
          <path
            d="M209.77 272.79a72.92 72.92 0 01102.87-6.65A72.92 72.92 0 01319.28 369a72.92 72.92 0 01-102.86 6.65 72.92 72.92 0 01-6.65-102.86z"
            fill="none"
            mask="url(#s)"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="t" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M217 279.14a63.28 63.28 0 0189.28-5.76 63.28 63.28 0 015.77 89.27 63.29 63.29 0 01-89.27 5.76 63.28 63.28 0 01-5.77-89.27z" />
          </mask>
          <path
            d="M217 279.14a63.28 63.28 0 0189.28-5.76 63.28 63.28 0 015.77 89.27 63.29 63.29 0 01-89.27 5.76 63.28 63.28 0 01-5.77-89.27z"
            fill="none"
          />
          <path
            d="M217 279.14a63.28 63.28 0 0189.28-5.76 63.28 63.28 0 015.77 89.27 63.29 63.29 0 01-89.27 5.76 63.28 63.28 0 01-5.77-89.27z"
            fill="none"
            mask="url(#t)"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="u" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M201.45 265.47a84.01 84.01 0 01118.5-7.66 84 84 0 017.66 118.5c-30.59 34.82-83.69 38.25-118.5 7.67a84.01 84.01 0 01-7.66-118.5z" />
          </mask>
          <path
            d="M201.45 265.47a84.01 84.01 0 01118.5-7.66 84 84 0 017.66 118.5c-30.59 34.82-83.69 38.25-118.5 7.67a84.01 84.01 0 01-7.66-118.5z"
            fill="none"
          />
          <path
            d="M201.45 265.47a84.01 84.01 0 01118.5-7.66 84 84 0 017.66 118.5c-30.59 34.82-83.69 38.25-118.5 7.67a84.01 84.01 0 01-7.66-118.5z"
            fill="none"
            mask="url(#u)"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <path
            d="M255.45 312.92L207 270.35a76.6 76.6 0 006.98 108.07 76.61 76.61 0 00108.06-6.98l-48.44-42.56a12.1 12.1 0 01-18.16-15.96z"
            fill="#D7D7D7"
          />
          <path
            d="M273.61 328.88l48.44 42.56a76.6 76.6 0 00-6.98-108.07A76.6 76.6 0 00207 270.35l48.44 42.56a12.1 12.1 0 0118.16 15.96z"
            fill="#FFF"
          />
          <path
            d="M204.78 268.4a79.57 79.57 0 01112.24-7.25 79.57 79.57 0 017.25 112.24 79.57 79.57 0 01-112.24 7.25 79.57 79.57 0 01-7.25-112.24z"
            fill="none"
          />
          <clipPath id="v">
            <path
              d="M204.78 268.4a79.57 79.57 0 01112.24-7.25 79.57 79.57 0 017.25 112.24 79.57 79.57 0 01-112.24 7.25 79.57 79.57 0 01-7.25-112.24z"
              fill="#FFF"
            />
          </clipPath>
          <g clipPath="url(#v)">
            <mask id="w">
              <path
                d="M204.78 268.4a79.57 79.57 0 01112.24-7.25 79.57 79.57 0 017.25 112.24 79.57 79.57 0 01-112.24 7.25 79.57 79.57 0 01-7.25-112.24z"
                fill="#fff"
              />
            </mask>
            <path
              d="M204.78 268.4a79.57 79.57 0 01112.24-7.25 79.57 79.57 0 017.25 112.24 79.57 79.57 0 01-112.24 7.25 79.57 79.57 0 01-7.25-112.24z"
              fill="none"
            />
            <linearGradient id="x" x1="0" y1=".5" x2="1" y2=".5">
              <stop offset="0%" stop-color={color2} />
              <stop offset="100%" stop-color={color1} />
            </linearGradient>
            <path
              d="M204.78 268.4a79.57 79.57 0 01112.24-7.25 79.57 79.57 0 017.25 112.24 79.57 79.57 0 01-112.24 7.25 79.57 79.57 0 01-7.25-112.24z"
              fill="none"
              mask="url(#w)"
              vectorEffect="non-scaling-stroke"
              strokeWidth="10"
              stroke="url(#x)"
              strokeLinecap="square"
              strokeMiterlimit="3"
            />
          </g>
          <clipPath id="y">
            <path
              transform="rotate(41.32 293.03 288.43)"
              fill="#FFF"
              d="M264 279.14h57.91v18.81H264z"
            />
          </clipPath>
          <g clipPath="url(#y)">
            <path
              d="M278.87 277.1l.4-.45 1.46.88-.54.62q-.44.51-.4.99.03.47.44.84.41.36.89.3.48-.05.87-.5l4.1-4.66 1.3 1.16-4.09 4.66q-.82.93-2 1.04-1.19.1-2.15-.75-1-.87-1.07-2-.08-1.14.8-2.13zm8.25 10.89l-1.2-1.06q.12-.23.4-.54-.46.15-.95 0-.5-.16-.9-.51-.82-.73-.84-1.67 0-.95.69-1.74l2.6-2.96 1.26 1.11-2.37 2.7q-.37.42-.38.88-.01.45.4.82.42.36.88.32.45-.05.82-.47l2.4-2.72 1.26 1.12-3.37 3.83q-.4.45-.7.89zm8.3-1l1.66 1.45-3.6.32-.48 4.4-1.55-1.37.34-2.87-1.01.09-1.09 1.24-1.26-1.12 6.05-6.88 1.26 1.1-3.47 3.95 3.15-.31zm3.7 3.25l-4.1 4.68-1.27-1.12 4.1-4.68 1.27 1.12zm-.07-2.78q.28-.32.72-.36.43-.03.75.25.33.29.36.72.02.43-.27.76-.28.31-.7.34-.43.03-.76-.25-.32-.29-.35-.72-.03-.43.25-.74zm4.61 4.7q-.26-.23-.59-.25-.32-.02-.63.33l-.45.51 1.05.93-.95 1.08-1.06-.92-3.16 3.6-1.27-1.13 3.16-3.6-.78-.68.95-1.08.78.68.47-.53q.7-.79 1.57-.86.88-.07 1.66.62.42.36.55.64l-.94 1.07q-.12-.2-.36-.4zm4.76 6.24l-8.68 3.95-1.34-1.18 3.25-1.47 1.6-5.84 1.42 1.25-1.16 3.83 3.56-1.72 1.35 1.18z"
              fillRule="evenodd"
              fill={color1}
            />
          </g>
        </g>
        <clipPath id="z">
          <path
            transform="rotate(-24.08 162.94 192.66)"
            fill="#FFF"
            d="M24.35 40h277.44v305.55H24.35z"
          />
        </clipPath>
        <g clipPath="url(#z)">
          <path fill="none" d="M-25.88 109.83l253.3-113.2L352.1 275.6 98.79 388.8z" />
          <path
            d="M40.02 257.44c-31.21-69.9.2-152 70.1-183.22 69.91-31.22 152.01.2 183.23 70.1 31.22 69.9-.2 152-70.1 183.23-69.91 31.21-152.01-.2-183.23-70.1zm120.23-53.68a7.06 7.06 0 1112.88-5.75 7.06 7.06 0 01-12.88 5.75z"
            fillRule="evenodd"
            fill="#050409"
          />
          <path
            d="M44.32 255.52c-30.16-67.53.19-146.85 67.73-177 67.53-30.17 146.85.18 177 67.72 30.16 67.54-.18 146.85-67.72 177.01-67.54 30.16-146.86-.19-177.01-67.73zm115.93-51.76a7.06 7.06 0 1112.88-5.75 7.06 7.06 0 01-12.88 5.75z"
            fillRule="evenodd"
            fill="#0B0916"
            vectorEffect="non-scaling-stroke"
            strokeWidth="2"
            stroke="#050409"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="A" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M61.5 247.85c-25.93-58.05.16-126.24 58.21-152.16 58.06-25.93 126.25.16 152.17 58.22 25.93 58.06-.16 126.24-58.22 152.17C155.6 332 87.42 305.9 61.49 247.86z" />
          </mask>
          <path
            d="M61.5 247.85c-25.93-58.05.16-126.24 58.21-152.16 58.06-25.93 126.25.16 152.17 58.22 25.93 58.06-.16 126.24-58.22 152.17C155.6 332 87.42 305.9 61.49 247.86z"
            fill="none"
          />
          <path
            d="M61.5 247.85c-25.93-58.05.16-126.24 58.21-152.16 58.06-25.93 126.25.16 152.17 58.22 25.93 58.06-.16 126.24-58.22 152.17C155.6 332 87.42 305.9 61.49 247.86z"
            fill="none"
            mask="url(#A)"
            vectorEffect="non-scaling-stroke"
            stroke="#050409"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="B" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M52.18 252.01c-28.22-63.2.18-137.41 63.38-165.63s137.41.18 165.63 63.37c28.22 63.2-.18 137.42-63.37 165.63-63.2 28.23-137.42-.17-165.64-63.37z" />
          </mask>
          <path
            d="M52.18 252.01c-28.22-63.2.18-137.41 63.38-165.63s137.41.18 165.63 63.37c28.22 63.2-.18 137.42-63.37 165.63-63.2 28.23-137.42-.17-165.64-63.37z"
            fill="none"
          />
          <path
            d="M52.18 252.01c-28.22-63.2.18-137.41 63.38-165.63s137.41.18 165.63 63.37c28.22 63.2-.18 137.42-63.37 165.63-63.2 28.23-137.42-.17-165.64-63.37z"
            fill="none"
            mask="url(#B)"
            vectorEffect="non-scaling-stroke"
            stroke="#050409"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="C" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M79.74 239.7c-21.43-47.98.13-104.34 48.12-125.77 47.99-21.42 104.35.14 125.77 48.13 21.43 47.98-.13 104.34-48.12 125.77-47.98 21.43-104.34-.14-125.77-48.12z" />
          </mask>
          <path
            d="M79.74 239.7c-21.43-47.98.13-104.34 48.12-125.77 47.99-21.42 104.35.14 125.77 48.13 21.43 47.98-.13 104.34-48.12 125.77-47.98 21.43-104.34-.14-125.77-48.12z"
            fill="none"
          />
          <path
            d="M79.74 239.7c-21.43-47.98.13-104.34 48.12-125.77 47.99-21.42 104.35.14 125.77 48.13 21.43 47.98-.13 104.34-48.12 125.77-47.98 21.43-104.34-.14-125.77-48.12z"
            fill="none"
            mask="url(#C)"
            vectorEffect="non-scaling-stroke"
            stroke="#050409"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="D" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M70.42 243.87c-23.72-53.13.15-115.53 53.28-139.25 53.13-23.73 115.53.15 139.25 53.28 23.73 53.13-.15 115.52-53.28 139.25-53.13 23.72-115.52-.15-139.25-53.28z" />
          </mask>
          <path
            d="M70.42 243.87c-23.72-53.13.15-115.53 53.28-139.25 53.13-23.73 115.53.15 139.25 53.28 23.73 53.13-.15 115.52-53.28 139.25-53.13 23.72-115.52-.15-139.25-53.28z"
            fill="none"
          />
          <path
            d="M70.42 243.87c-23.72-53.13.15-115.53 53.28-139.25 53.13-23.73 115.53.15 139.25 53.28 23.73 53.13-.15 115.52-53.28 139.25-53.13 23.72-115.52-.15-139.25-53.28z"
            fill="none"
            mask="url(#D)"
            vectorEffect="non-scaling-stroke"
            stroke="#050409"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="E" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M100.14 230.6a72.92 72.92 0 0136.83-96.27 72.92 72.92 0 0196.27 36.83 72.92 72.92 0 01-36.84 96.27 72.92 72.92 0 01-96.27-36.83z" />
          </mask>
          <path
            d="M100.14 230.6a72.92 72.92 0 0136.83-96.27 72.92 72.92 0 0196.27 36.83 72.92 72.92 0 01-36.84 96.27 72.92 72.92 0 01-96.27-36.83z"
            fill="none"
          />
          <path
            d="M100.14 230.6a72.92 72.92 0 0136.83-96.27 72.92 72.92 0 0196.27 36.83 72.92 72.92 0 01-36.84 96.27 72.92 72.92 0 01-96.27-36.83z"
            fill="none"
            mask="url(#E)"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="F" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M108.93 226.67a63.28 63.28 0 0131.97-83.55 63.28 63.28 0 0183.55 31.97 63.29 63.29 0 01-31.97 83.55 63.28 63.28 0 01-83.55-31.97z" />
          </mask>
          <path
            d="M108.93 226.67a63.28 63.28 0 0131.97-83.55 63.28 63.28 0 0183.55 31.97 63.29 63.29 0 01-31.97 83.55 63.28 63.28 0 01-83.55-31.97z"
            fill="none"
          />
          <path
            d="M108.93 226.67a63.28 63.28 0 0131.97-83.55 63.28 63.28 0 0183.55 31.97 63.29 63.29 0 01-31.97 83.55 63.28 63.28 0 01-83.55-31.97z"
            fill="none"
            mask="url(#F)"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <mask id="G" x="-200%" y="-200%" width="400%" height="400%">
            <rect x="-200%" y="-200%" width="400%" height="400%" fill="#fff" />
            <path d="M90.01 235.12a84.01 84.01 0 0142.44-110.91 84.01 84.01 0 01110.91 42.43 84.01 84.01 0 01-42.44 110.92c-42.31 18.9-92.01-.12-110.9-42.44z" />
          </mask>
          <path
            d="M90.01 235.12a84.01 84.01 0 0142.44-110.91 84.01 84.01 0 01110.91 42.43 84.01 84.01 0 01-42.44 110.92c-42.31 18.9-92.01-.12-110.9-42.44z"
            fill="none"
          />
          <path
            d="M90.01 235.12a84.01 84.01 0 0142.44-110.91 84.01 84.01 0 01110.91 42.43 84.01 84.01 0 01-42.44 110.92c-42.31 18.9-92.01-.12-110.9-42.44z"
            fill="none"
            mask="url(#G)"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            stroke-opacity="100"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
          <path
            d="M155.65 205.81l-58.88 26.3A76.6 76.6 0 00197.9 270.8a76.6 76.6 0 0038.7-101.14l-58.88 26.3a12.1 12.1 0 01-22.08 9.85z"
            fill="#D7D7D7"
          />
          <path
            d="M177.73 195.95l58.88-26.29a76.6 76.6 0 00-101.14-38.7 76.6 76.6 0 00-38.7 101.14l58.88-26.29a12.1 12.1 0 0122.08-9.86z"
            fill="#FFF"
          />
          <path
            d="M94.07 233.3a79.57 79.57 0 0140.19-105.04 79.57 79.57 0 01105.05 40.2 79.57 79.57 0 01-40.2 105.04 79.57 79.57 0 01-105.04-40.2z"
            fill="none"
          />
          <clipPath id="H">
            <path
              d="M94.07 233.3a79.57 79.57 0 0140.19-105.04 79.57 79.57 0 01105.05 40.2 79.57 79.57 0 01-40.2 105.04 79.57 79.57 0 01-105.04-40.2z"
              fill="#FFF"
            />
          </clipPath>
          <g clipPath="url(#H)">
            <mask id="I">
              <path
                d="M94.07 233.3a79.57 79.57 0 0140.19-105.04 79.57 79.57 0 01105.05 40.2 79.57 79.57 0 01-40.2 105.04 79.57 79.57 0 01-105.04-40.2z"
                fill="#fff"
              />
            </mask>
            <path
              d="M94.07 233.3a79.57 79.57 0 0140.19-105.04 79.57 79.57 0 01105.05 40.2 79.57 79.57 0 01-40.2 105.04 79.57 79.57 0 01-105.04-40.2z"
              fill="none"
            />
            <linearGradient id="J" x1="0" y1=".5" x2="1" y2=".5">
              <stop offset="0%" stop-color={color2} />
              <stop offset="99.17%" stop-color={color1} />
            </linearGradient>
            <path
              d="M94.07 233.3a79.57 79.57 0 0140.19-105.04 79.57 79.57 0 01105.05 40.2 79.57 79.57 0 01-40.2 105.04 79.57 79.57 0 01-105.04-40.2z"
              fill="none"
              mask="url(#I)"
              vectorEffect="non-scaling-stroke"
              strokeWidth="10"
              stroke="url(#J)"
              strokeLinecap="square"
              strokeMiterlimit="3"
            />
          </g>
          <clipPath id="K">
            <path
              transform="rotate(-24.08 149 161.46)"
              fill="#FFF"
              d="M120.17 152.15h57.91v18.81h-57.91z"
            />
          </clipPath>
          <g clipPath="url(#K)">
            <path
              d="M132.85 169.59l-.24-.55 1.42-.96.33.75q.29.6.73.78.44.17.95-.06.5-.22.65-.68.15-.45-.1-1l-2.52-5.66 1.6-.72 2.52 5.67q.51 1.13.1 2.26-.4 1.12-1.56 1.64-1.21.54-2.28.13-1.06-.4-1.6-1.6zm13.34-2.96l-1.47.65q-.15-.2-.32-.59-.05.48-.4.86-.34.39-.83.6-1 .45-1.87.07-.86-.38-1.3-1.34l-1.6-3.6 1.54-.69 1.47 3.29q.22.5.63.7.41.2.92-.02.5-.22.65-.66.15-.44-.08-.94l-1.47-3.31 1.53-.69 2.09 4.66q.24.55.51 1zm2.55-7.97l2.01-.9-1.2 3.42 3.79 2.25-1.89.85-2.47-1.5-.34.95.68 1.5-1.54.69-3.74-8.37 1.54-.69 2.14 4.8 1.02-3zm4.5-2l2.54 5.69-1.54.68-2.54-5.69 1.54-.68zm-2.56-1.1q-.17-.39-.02-.8.15-.4.54-.58.4-.18.8-.02.4.15.58.56.17.38.02.78-.16.4-.55.58-.4.17-.8.02-.4-.16-.57-.54zm6.2-2.22q-.32.14-.47.42-.16.29.03.72l.28.62 1.28-.57.6 1.32-1.3.57 1.96 4.37-1.55.7-1.95-4.38-.95.42-.59-1.31.95-.43-.29-.64q-.43-.96-.13-1.8.3-.82 1.26-1.24.5-.23.81-.23l.58 1.3q-.22.03-.51.16zm7.66-1.73l-.03 9.53-1.63.73.02-3.56-4.64-3.9 1.72-.76 3 2.65-.08-3.96 1.64-.73z"
              fillRule="evenodd"
              fill={color1}
            />
          </g>
        </g>
        <path d="M525.89 9.73L495.6 342.27l-332.54-30.28 30.28-332.54L525.89 9.73z" fill="none" />
        <linearGradient
          id="L"
          x1="0"
          y1=".5"
          x2="1"
          y2=".5"
          gradientTransform="matrix(251.268 21.01 -22.686 271.314 231.33 7.88)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="1.67%" stop-color={color1} />
          <stop offset="100%" stop-color={color2} />
        </linearGradient>
        <path
          d="M398.14 186.92l-10.96-.92-3.57-4.07a90.03 90.03 0 0026.68-56.82 90.43 90.43 0 00-82.59-97.66 90.43 90.43 0 00-97.66 82.58 90.43 90.43 0 0082.59 97.66 90.03 90.03 0 0060.47-16.86l3.42 4.2-.92 10.95 63.54 74.98 22.38-18.93-63.38-75.11zm-83.2-6.96a62.52 62.52 0 01-57.17-67.6 62.53 62.53 0 0167.61-57.18 62.53 62.53 0 0157.18 67.6 62.53 62.53 0 01-67.61 57.18z"
          fill="url(#L)"
        />
      </g>
    </svg>
  );
}

export default SearchIllustration;
