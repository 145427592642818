import React from 'react';
import { Grid, Paper, Button, Theme } from '@material-ui/core';
import LogoText from '../../logos/components/LogoText';
import { localisation } from '../../../i18n/LanguageService';
import styles from './LoginPage.module.css';
import { AppState } from '../../../redux/reducer';
import { connect } from 'react-redux';
import { isCordova } from '../../../utils/isCordova';
import * as queryString from 'query-string';
import { store } from '../../../redux/store';
import { userSignedIn } from '../../../redux/actions/authentication.action';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import background from '../../../assets/svg/login-background.svg';
import backgroundDesktop from '../../../assets/svg/login-background-desktop.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
declare var cordova;

export interface LoginPageProps {
  theme: Theme;
  loginUrl: string;
}

export interface LoginPageState {}

const mapStateToProps = (state: AppState) => {
  return {
    loginUrl: state.settings.loginUrl,
  };
};

function LoginPage(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  function renderLoginButton() {
    let loginUrl = props.loginUrl;
    if (isCordova()) {
      if (props.loginUrl.includes('?')) {
        loginUrl += '&hashBasedUrl=true';
      } else {
        loginUrl += '?hashBasedUrl=true';
      }
    }

    if (isCordova()) {
      return (
        <Button
          variant="contained"
          className={styles.button}
          color="primary"
          onClick={() => {
            const browserRef = cordova.InAppBrowser.open(loginUrl, '_blank', 'location=no');
            browserRef.addEventListener('loadstart', (event: any) => {
              if (/token/gm.test(event.url.toString())) {
                const token = queryString.parse(event.url.toString().split('?')[1]);
                store.dispatch(userSignedIn(token as any));
                store.dispatch(push('/joinSession'));
                browserRef.close();
              }
            });
          }}
        >
          {localisation.strings.buttonLogin}
        </Button>
      );
    }
    return (
      <a href={loginUrl}>
        <Button
          variant="contained"
          className={styles.button}
          color="inherit"
          style={{ background: `linear-gradient(to right, #8D2DE2, #4B01E0)` }}
        >
          {localisation.strings.buttonLogin}
        </Button>
      </a>
    );
  }

  return (
    <>
      <div
        className={styles.illustration}
        style={{
          background: `url(${matches ? backgroundDesktop : background})`,
          backgroundSize: matches ? '100% auto' : 'cover',
          opacity: 0.3,
        }}
      />
      <Grid container className={styles.container}>
        <Grid item xs={12} md={4}>
          <Paper className={styles.paper} style={{ backgroundColor: 'white' }}>
            <LogoText color="#050409" />

            {renderLoginButton()}
          </Paper>
        </Grid>
      </Grid>
      <div style={{ marginLeft: 16, padding: 16, fontSize: 13 }}>
        <Link to="/privacy" className={styles.link}>
          <span style={{ color: '#fff' }}>Privacy</span>
        </Link>
        <Link to="/legal" className={styles.link}>
          <span style={{ marginLeft: 16, color: '#fff' }}>Legal Notice</span>
        </Link>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(LoginPage);
