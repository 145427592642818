import React from 'react';
import styles from './LobbyPage.module.css';
import { connect } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import { Theme, withTheme, Paper, Grid, Typography } from '@material-ui/core';
import autobind from 'autobind-decorator';
import SessionList from '../components/SessionList';
import { Session } from '../../../models/Session';
import { store } from '../../../redux/store';
import { push } from 'connected-react-router';
import JoinSessionDialog from '../components/JoinSessionDialog';
import {
  joinSessionRequest,
  leaveSessionRequest,
  switchSessionRequest,
} from '../../../redux/actions/session.action';
import LeaveSessionDialog from '../components/LeaveSessionDialog';
import { DeviceSize } from '../../../redux/reducers/general.reducer';
import { localisation } from '../../../i18n/LanguageService';

export interface LobbyPageProps {
  theme: Theme;
  mySessions?: Session[];
  sessionIdToLeave?: string;
  deviceSize?: DeviceSize;
}

export interface LobbyPageState {
  isJoinSessionDialogOpen: boolean;
  isLeaveSessionDialogOpen: boolean;
  sessionIdToLeave?: string;
  key: number;
}

const mapStateToProps = (state: AppState) => {
  return {
    mySessions: state.session.sessionList || undefined,
    sessionIdToLeave: state.session.sessionIdToLeave || undefined,
    deviceSize: state.general.deviceSize,
  };
};

class LobbyPage extends React.Component<LobbyPageProps, LobbyPageState> {
  constructor(props: LobbyPageProps) {
    super(props);
    this.state = {
      isJoinSessionDialogOpen: false,
      isLeaveSessionDialogOpen: false,
      key: Math.random(),
    };
  }

  @autobind
  changeToSession(sessionId: string) {
    store.dispatch(switchSessionRequest(sessionId));
    store.dispatch(push(`/session`));
  }

  @autobind
  openSessionDialog(sessionId: string) {
    if (sessionId) {
      this.setState({ isLeaveSessionDialogOpen: true, sessionIdToLeave: sessionId });
    }
  }

  @autobind
  leaveSession(leave: boolean) {
    if (this.state.sessionIdToLeave && leave) {
      store.dispatch(leaveSessionRequest(this.state.sessionIdToLeave));
    } else {
      this.setState({ key: Math.random() });
    }
    this.setState({
      isLeaveSessionDialogOpen: false,
      sessionIdToLeave: undefined,
    });
  }

  @autobind
  onJoinSessionDialogClosed(sessionId?: string) {
    if (sessionId) {
      store.dispatch(joinSessionRequest(sessionId));
    }
    this.setState({ isJoinSessionDialogOpen: false, sessionIdToLeave: undefined });
  }

  @autobind
  renderSessionList() {
    if (this.props.deviceSize! <= DeviceSize.TABLET) {
      return (
        <div className={styles.lobby}>
          <SessionList
            key={this.state.key}
            sessions={this.props.mySessions!}
            onClick={this.changeToSession}
            onFabClick={() => this.setState({ isJoinSessionDialogOpen: true })}
            onLeave={this.openSessionDialog}
            swipe={true}
          />
        </div>
      );
    } else {
      return (
        <div
          className={styles.lobby}
          style={{ background: this.props.theme!.palette.background.paper }}
        >
          <Grid
            item
            className={styles.item}
            md={6}
            style={{ display: 'flex', flex: 1, padding: 32, width: '50%' }}
          >
            <Paper
              style={{
                background: this.props.theme!.palette.background.default,
                flex: 1,
                padding: 16,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
                color="primary"
                style={{ marginBottom: 32, marginLeft: 16, marginTop: 16 }}
              >
                {localisation.strings.appBarTitleLobby}
              </Typography>
              <SessionList
                swipe={true}
                key={this.state.key}
                sessions={this.props.mySessions!}
                onClick={this.changeToSession}
                onFabClick={() => this.setState({ isJoinSessionDialogOpen: true })}
                onLeave={this.openSessionDialog}
              />
            </Paper>
          </Grid>
        </div>
      );
    }
  }

  render() {
    return (
      <>
        {this.renderSessionList()}
        <JoinSessionDialog
          open={this.state.isJoinSessionDialogOpen}
          onClose={this.onJoinSessionDialogClosed}
        />
        <LeaveSessionDialog
          open={this.state.isLeaveSessionDialogOpen}
          onClose={this.leaveSession}
        />
      </>
    );
  }
}

export default withTheme(connect(mapStateToProps)(LobbyPage));
