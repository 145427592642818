import { take, put, all, fork } from 'redux-saga/effects';
import { initializeRequest } from '../../actions/general.action';
import {
  sendInitializeAppRequestToServer,
  establishServerConnection,
  writeThemeToLocalStorage,
} from './general.worker';
import { TokenDto } from '../../../interfaces/Token.dto';
import { USER_SIGNED_IN } from '../../constants/authentication.constants';
import { APP_INITIALIZE_REQUEST, APP_THEME_CHANGE } from '../../constants/general.constants';

function* watchUserSignedIn() {
  const action = yield take(USER_SIGNED_IN);
  const token: TokenDto = action.payload;
  yield establishServerConnection(token.token);
  yield put(initializeRequest());

  yield fork(watchUserSignedIn);
}

function* watchAppInitializeRequest() {
  yield take(APP_INITIALIZE_REQUEST);

  yield sendInitializeAppRequestToServer();

  yield fork(watchAppInitializeRequest);
}

function* watchThemeChange() {
  const action = yield take(APP_THEME_CHANGE);
  yield writeThemeToLocalStorage(action.payload);

  yield fork(watchThemeChange);
}

export default function* generalSaga() {
  yield all([fork(watchUserSignedIn), fork(watchAppInitializeRequest), fork(watchThemeChange)]);
}
