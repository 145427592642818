import { ActionType } from 'typesafe-actions';
import * as actions from './../actions/spotify.action';
import { combineReducers } from 'redux';
import { Device } from '../../models/Device';
import {
  SPOTIFY_FETCH_DEVICES_SUCCESS,
  SPOTIFY_SWITCH_DEVICE_SUCCESS,
  SPOTIFY_FETCH_DEVICES_REQUEST,
  SPOTIFY_FETCH_DEVICES_ERROR,
  SPOTIFY_DEVICES_UPDATE_ASYNC,
} from '../constants/spotify.constants';
import { LoadingState } from '../../models/LoadingState';

export interface SpotifyState {
  fetchDevicesLoadingState: LoadingState;
  devices: Device[];
  selectedDeviceId: string;
}

export type SpotifyAction = ActionType<typeof actions>;

export const spotify = combineReducers<SpotifyState, SpotifyAction>({
  fetchDevicesLoadingState: (state = LoadingState.NOT_STARTED, action) => {
    switch (action.type) {
      case SPOTIFY_FETCH_DEVICES_REQUEST: {
        return LoadingState.LOADING;
      }
      case SPOTIFY_FETCH_DEVICES_SUCCESS: {
        return LoadingState.SUCCESS;
      }
      case SPOTIFY_FETCH_DEVICES_ERROR: {
        return LoadingState.ERROR;
      }
      default: {
        return state;
      }
    }
  },
  devices: (state = null, action) => {
    switch (action.type) {
      case SPOTIFY_FETCH_DEVICES_SUCCESS: {
        return action.payload;
      }
      case SPOTIFY_DEVICES_UPDATE_ASYNC: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  selectedDeviceId: (state = null, action) => {
    switch (action.type) {
      case SPOTIFY_SWITCH_DEVICE_SUCCESS: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
} as any);
