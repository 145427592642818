import { singalRService } from '../../services/singalR.service';
import { store } from '../../store';

export function* reconnect() {
  const { signalR } = store.getState();

  setTimeout(async () => {
    await singalRService.reconnect();
  }, signalR.retryCount * signalR.retryCount * 1000);
}
