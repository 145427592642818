import { UserPlaylist } from '../../../models/UserPlaylist';
import { singalRService } from '../../services/singalR.service';
import {
  fetchUserPlaylistSuccess,
  userPlaylistAddTracksSuccess,
  userPlaylistRemoveTrackSuccess,
  fetchUserPlaylistError,
  userPlaylistAddTracksError,
  userPlaylistRemoveTrackError,
} from '../../actions/userPlaylist.action';
import { Track, FullTrack } from '../../../models/Track';
import { put } from 'redux-saga/effects';
import { HubError } from '../../../models/HubError';

export function* sendUserPlaylistRequestToServer(sessionId: string) {
  try {
    const userPlaylist: UserPlaylist = yield singalRService.invoke('GetUserPlaylist', sessionId);
    yield put(fetchUserPlaylistSuccess(userPlaylist));
  } catch (err) {
    yield put(fetchUserPlaylistError(new HubError(err)));
  }
}

export function* sendAddTracksRequestToServer(sessionId: string, tracks: Track[]) {
  try {
    const newTracks = yield singalRService.invoke('AddTracks', tracks.map(t => t.id), sessionId);
    yield put(userPlaylistAddTracksSuccess(newTracks));
  } catch (err) {
    yield put(userPlaylistAddTracksError(new HubError(err)));
  }
}

export function* sendRemoveTrackRequestToServer(sessionId: string, track: Track) {
  try {
    yield singalRService.invoke('RemoveTrack', track.id, sessionId);
    yield put(userPlaylistRemoveTrackSuccess(track.id));
  } catch (err) {
    yield put(userPlaylistRemoveTrackError(new HubError(err)));
  }
}

export function* sendUserPlaylistMovedOne(sessionId: string, trackId: string, moveRelation: number) {
  try {
    yield singalRService.invoke('MoveSingleTrack', sessionId, trackId, moveRelation);
  } catch (err) {
    console.log(err);
  }
}

export function* sendUserPlaylistMovedOneTopOrBottom(sessionId: string, trackId: string, toTop: boolean) {
  try {
    yield singalRService.invoke('MoveSingleTrackTopOrBottom', sessionId, trackId, toTop);
  } catch (err) {
    console.log(err);
  }
}

export function* sendUserPlaylistShuffe(sessionId: string) {
  try {
    yield singalRService.invoke('ShuffleUserPlaylist', sessionId);
  } catch (err) {
    console.log(err);
  }
}

export function* sendUserPlaylistEmptyPlaylist(sessionId: string) {
  try {
    yield singalRService.invoke('EmptyUserPlaylist', sessionId)
  } catch (err) {
    console.log(err);
  }
}
