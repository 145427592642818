import React from 'react';
import { withTheme, Theme, Typography } from '@material-ui/core';

export interface ChipProps {
  label: string;
  theme?: Theme;
}

export interface ChipState {}

class Chip extends React.Component<ChipProps, ChipState> {
  constructor(props: ChipProps) {
    super(props);
  }
  render() {
    return (
      <div
        style={{
          background: `linear-gradient(to right, ${this.props.theme!.palette.primary.main}, ${
            this.props.theme!.palette.primary.dark
          })`,
          color: this.props.theme!.palette.primary.contrastText,
          borderRadius: '10px',
          padding: '1px 8px 1px 8px',
          display: 'flex',
          fontSize: '13px',
          height: 21,
          marginRight: '8px',
          width: 'fit-content',
        }}
      >
        <Typography noWrap={true} variant="subtitle2" color="inherit">
          {this.props.label}
        </Typography>
      </div>
    );
  }
}

export default withTheme(Chip);
