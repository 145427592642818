import { all, fork, take, put } from 'redux-saga/effects';
import {
  sendSessionCreateRequestToServer,
  sendSessionLeaveRequestToServer,
  sendSessionJoinRequestToServer,
  writeCurrentSessionToLocalStorage,
  sendSessionSettingsUpdateRequestToServer,
} from './session.worker';
import { push } from 'connected-react-router';
import { Session } from '../../../models/Session';
import { switchSessionRequest } from '../../actions/session.action';
import { store } from '../../store';
import {
  SESSION_CREATE_REQUEST,
  SESSION_JOIN_REQUEST,
  SESSION_LEAVE_REQUEST,
  SESSION_CREATE_SUCCESS,
  SESSION_JOIN_SUCCESS,
  SESSION_LEAVE_SUCCESS,
  SESSION_SWITCH_SUCCESS,
  SESSION_CREATE_ERROR,
  SESSION_JOIN_ERROR,
  SESSION_LEAVE_ERROR,
  SESSION_SETTINGS_UPDATE_REQUEST,
} from '../../constants/session.constants';
import { enqueueSnackbar } from '../../actions/notification.action';
import { localisation } from '../../../i18n/LanguageService';

function* watchSessionCreateRequest() {
  const action = yield take(SESSION_CREATE_REQUEST);
  const name: string = action.payload;

  yield sendSessionCreateRequestToServer(name);

  yield fork(watchSessionCreateRequest);
}

function* watchSessionJoinRequest() {
  const action = yield take(SESSION_JOIN_REQUEST);
  const sessionId = action.payload;

  yield sendSessionJoinRequestToServer(sessionId);

  yield fork(watchSessionJoinRequest);
}

function* watchSessionLeaveRequest() {
  const action = yield take(SESSION_LEAVE_REQUEST);
  const sessionId: string = action.payload;

  const { session } = store.getState();

  yield sendSessionLeaveRequestToServer(sessionId);

  yield fork(watchSessionLeaveRequest);
}

function* watchSessionCreated() {
  const action = yield take(SESSION_CREATE_SUCCESS);
  const session: Session = action.payload;
  yield put(switchSessionRequest(session.sessionId));
  yield put(push(`/session`));

  yield fork(watchSessionCreated);
}

function* watchSessionJoined() {
  const action = yield take(SESSION_JOIN_SUCCESS);
  const session: Session = action.payload;
  yield put(switchSessionRequest(session.sessionId));
  yield put(push(`/session`));

  yield fork(watchSessionJoined);
}

function* watchSessionLeft() {
  const action = yield take(SESSION_LEAVE_SUCCESS);

  yield fork(watchSessionLeft);
}

function* watchSwitchSession() {
  const action = yield take(SESSION_SWITCH_SUCCESS);

  if (!action.payload || !(typeof action.payload === 'string')) {
    yield put(push(`/joinSession`));
  }

  yield writeCurrentSessionToLocalStorage(action.payload);
  yield fork(watchSwitchSession);
}

function* watchUpdateSessionSettingsRequest() {
  const action = yield take(SESSION_SETTINGS_UPDATE_REQUEST);
  yield sendSessionSettingsUpdateRequestToServer(action.payload.sessionId, action.payload.settings);
  yield fork(watchUpdateSessionSettingsRequest);
}

function* watchCreateError() {
  const action = yield take(SESSION_CREATE_ERROR);

  yield put(
    enqueueSnackbar({
      message: localisation.errors.session.create,
      options: {
        variant: 'error',
      },
    })
  );

  yield fork(watchCreateError);
}

function* watchJoinError() {
  const action = yield take(SESSION_JOIN_ERROR);

  yield put(
    enqueueSnackbar({
      message: localisation.errors.session.join,
      options: {
        variant: 'error',
      },
    })
  );

  yield fork(watchJoinError);
}

function* watchLeaveError() {
  const action = yield take(SESSION_LEAVE_ERROR);

  yield put(
    enqueueSnackbar({
      message: localisation.errors.session.leave,
      options: {
        variant: 'error',
      },
    })
  );

  yield fork(watchLeaveError);
}

export default function* sessionSaga() {
  yield all([
    fork(watchSessionCreateRequest),
    fork(watchSessionJoinRequest),
    fork(watchSessionLeaveRequest),
    fork(watchSessionCreated),
    fork(watchSessionJoined),
    fork(watchSessionLeft),
    fork(watchSwitchSession),
    fork(watchUpdateSessionSettingsRequest),
    fork(watchCreateError),
    fork(watchJoinError),
    fork(watchLeaveError),
  ]);
}
