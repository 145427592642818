import { ActionType } from 'typesafe-actions';
import * as actions from './../actions/general.action';
import { combineReducers } from 'redux';
import {
  APP_INITIALIZE_SUCCESS,
  APP_THEME_CHANGE,
  APP_INITIALIZE_ERROR,
  WINDOW_RESIZED,
} from '../constants/general.constants';
import { HubError } from '../../models/HubError';

export enum DeviceSize {
  PHONE,
  TABLET,
  DESKTOP,
  TV,
}

export interface GeneralState {
  initialized: boolean;
  initializedError: HubError | null;
  theme: string;
  notifications: any[];
  deviceSize: DeviceSize;
}

export type GeneralAction = ActionType<typeof actions>;

export const general = combineReducers<GeneralState, GeneralAction>({
  initialized: (state = false, action) => {
    switch (action.type) {
      case APP_INITIALIZE_SUCCESS:
        return true;
      case APP_INITIALIZE_ERROR:
        return false;
      default:
        return state;
    }
  },
  initializedError: (state = null, action) => {
    switch (action.type) {
      case APP_INITIALIZE_SUCCESS:
        return null;
      case APP_INITIALIZE_ERROR:
        return action.payload;
      default:
        return state;
    }
  },
  theme: (state = 'default', action) => {
    switch (action.type) {
      case APP_THEME_CHANGE:
        return action.payload;
      default:
        return state;
    }
  },
  notifications: (state = [], action) => {
    switch (action.type) {
      case 'ENQUEUE_SNACKBAR':
        return [...state, (action as any).notification];

      case 'REMOVE_SNACKBAR':
        return state.filter(notification => (notification as any).key !== (action as any).key);

      default:
        return state;
    }
  },
  deviceSize: (state = DeviceSize.PHONE, action) => {
    switch (action.type) {
      case WINDOW_RESIZED: {
        const width = action.payload.width;
        if (width <= 576) {
          return DeviceSize.PHONE;
        }        
        if (width <= 1024) {
          return DeviceSize.TABLET;
        }        
        return DeviceSize.DESKTOP;
      }
      default:
        return state;
    }
  },
} as any);
