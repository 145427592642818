import { authentication, AuthenticationState } from './reducers/authentication.reducer';
import { session, SessionState } from './reducers/sessions.reducer';
import { RouterState } from 'connected-react-router';
import { settings, AppSettingsState } from './reducers/settings.reducer';
import { GeneralState, general } from './reducers/general.reducer';
import { userPlaylist, UserPlaylistState } from './reducers/userPlaylist.reducer';
import { SearchState, search } from './reducers/search.reducer';
import { sessionUser, SessionUserState } from './reducers/sessionUser.reducer';
import { playlist, PlaylistState } from './reducers/playlist.reducer';
import { album, AlbumState } from './reducers/album.reducer';
import { SpotifyState, spotify } from './reducers/spotify.reducer';
import { notifications } from './reducers/notifications.reducer';
import { SignalRState, signalR } from './reducers/signalR.reducer';

export interface AppState {
  router: RouterState;
  authentication: AuthenticationState;
  session: SessionState;
  settings: AppSettingsState;
  general: GeneralState;
  userPlaylist: UserPlaylistState;
  search: SearchState;
  sessionUser: SessionUserState;
  album: AlbumState;
  playlist: PlaylistState;
  spotify: SpotifyState;
  signalR: SignalRState;
  notifications: any[];
}

// Add all reducers like so: https://github.com/gothinkster/react-redux-realworld-example-app/blob/master/src/reducer.js
export default {
  general,
  authentication,
  session,
  spotify,
  search,
  album,
  playlist,
  userPlaylist,
  sessionUser,
  settings,
  notifications,
  signalR,
};
