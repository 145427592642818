import React from 'react';
import {
  List,
  ListItem,
  Avatar,
  ListItemText,
  Theme,
  withTheme,
  Typography,
} from '@material-ui/core';
import { Track, FullTrack } from '../../../models/Track';
import styles from './TrackList.module.css';
import Chip from './Chip';

export interface TrackListProps {
  tracks: FullTrack[];
  onClick?(track: FullTrack): void;
  theme?: Theme;
}

export interface TrackListState {}

class TrackList extends React.Component<TrackListProps, TrackListState> {
  constructor(props: TrackListProps) {
    super(props);
  }

  private onClick(track: FullTrack) {
    if (this.props && this.props.onClick) {
      this.props.onClick(track);
    }
  }

  formatSeconds(secs: number) {
    const sec_num = secs / 1000;

    let minutes = Math.floor(sec_num / 60);
    let seconds = Math.floor(sec_num - minutes * 60);

    let minutesString: string = '00';
    let secondsString: string = '00';

    if (minutes < 10) {
      minutesString = '0' + minutes;
    } else {
      minutesString = minutes.toString();
    }
    if (seconds < 10) {
      secondsString = '0' + seconds;
    } else {
      secondsString = seconds.toString();
    }

    return minutesString + ':' + secondsString;
  }

  render() {
    return (
      <List
        className={styles.list}
        style={{ backgroundColor: this.props.theme!.palette.background.default }}
      >
        {this.props.tracks.map((t: FullTrack) => {
          const src = t.thumbnail;

          return (
            <ListItem key={t.id} onClick={() => this.onClick(t)}>
              <Avatar src={src} style={{ borderRadius: 0 }} />

              <div className={styles.listText}>
                <Typography variant="subtitle1" noWrap={true}>
                  {t.name}
                </Typography>
                <div className={styles.secondRow}>
                  <Chip label={(t as any).addedBy} />
                  <Typography
                    variant="subtitle2"
                    style={{ color: this.props.theme!.palette.text.secondary }}
                    noWrap={true}
                  >
                    {t.artist}
                  </Typography>
                </div>
              </div>

              <ListItemText
                secondary={this.formatSeconds(t.duration_ms)}
                className={styles.secondary}
              />
            </ListItem>
          );
        })}
      </List>
    );
  }
}

export default withTheme(TrackList);
