import React from 'react';
import { Theme, Typography, Button, TextField, withTheme } from '@material-ui/core';
import { AppState } from '../../../redux/reducer';
import { connect } from 'react-redux';
import styles from './LobbyEmptyPlaceholder.module.css';
import EmptyIllustration from '../../illustrations/Empty';
import { Link } from 'react-router-dom';
import { localisation } from '../../../i18n/LanguageService';
import autobind from 'autobind-decorator';
import QrCodeScannerDialog from './QrCodeScannerDialog';
import { joinSessionRequest } from '../../../redux/actions/session.action';

export interface LobbyEmptyPlaceholderProps {
  theme?: Theme;
  title: string;
  message: string;
  onNewSession?: () => void;
  onJoinSession?: (sessionId?: string) => void;
}

export interface LobbyEmptyPlaceholderState {
  sessionId?: string;
  sessionIdValid: boolean;
  sessionIdDirty: boolean;
  isQrCodeDialogOpen: boolean;
}

class LobbyEmptyPlaceholder extends React.Component<
  LobbyEmptyPlaceholderProps,
  LobbyEmptyPlaceholderState
> {
  constructor(props: LobbyEmptyPlaceholderProps) {
    super(props);
    this.state = {
      sessionId: undefined,
      sessionIdValid: false,
      sessionIdDirty: false,
      isQrCodeDialogOpen: false,
    };
  }

  @autobind
  handleSessionIdChange(evt: any) {
    this.setState({
      sessionId: evt.target.value,
      sessionIdValid: evt.target.value.length == 6,
    });
  }

  @autobind
  handleJoinSessionClick(evt: any) {
    if (this.props.onJoinSession) {
      if (this.state.sessionIdValid) {
        this.props.onJoinSession(this.state.sessionId);
      } else {
        this.setState({ sessionIdDirty: true });
      }
    }
  }

  @autobind
  scanQrCode() {
    this.setState({ isQrCodeDialogOpen: true });
  }

  @autobind
  joinSession(sessionId?: string) {
    if (this.props.onJoinSession && sessionId) {
      this.props.onJoinSession(sessionId);
    }
    this.setState({ isQrCodeDialogOpen: false });
  }

  render() {
    return (
      <>
        <div className={styles.emptyIcon}>
          <EmptyIllustration color={this.props.theme!.palette.primary.main} />
        </div>
        <div className={styles.content}>
          <Typography color="primary" variant="h6" className={styles.importantText}>
            {this.props.title}
          </Typography>
          <Typography color="textSecondary" variant="subtitle1" className={styles.importantText}>
            {this.props.message}
          </Typography>
          <TextField
            autoComplete="off"
            required
            error={!this.state.sessionIdValid && this.state.sessionIdDirty}
            id="Session-ID"
            label="Session-ID"
            className={styles.input}
            fullWidth
            onChange={this.handleSessionIdChange}
          />
          <div className={styles.buttons}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleJoinSessionClick}
              className={styles.button}
              size="large"
            >
              {localisation.strings.lobbyJoinSessionButton}
            </Button>
            <Button
              variant="outlined"
              color="default"
              onClick={this.scanQrCode}
              className={styles.button}
              size="large"
            >
              {localisation.strings.lobbyScanQRButton}
            </Button>
            <Button
              variant="outlined"
              color="default"
              onClick={this.props.onNewSession ? this.props.onNewSession : () => {}}
              className={styles.button}
              size="large"
            >
              {localisation.strings.lobbyCreateSessionButton}
            </Button>
          </div>
        </div>
        <QrCodeScannerDialog open={this.state.isQrCodeDialogOpen} onClose={this.joinSession} />
      </>
    );
  }
}

export default withTheme(LobbyEmptyPlaceholder);
