import { action } from 'typesafe-actions';
import { SearchResult } from '../../models/SearchResult';
import {
  SEARCH_FETCH_REQUEST,
  SEARCH_FETCH_SUCCESS,
  SEARCH_FETCH_ERROR,
} from '../constants/search.constants';
import { HubError } from '../../models/HubError';

export const fetchSearchRequest = (query: string) => action(SEARCH_FETCH_REQUEST, query);

export const fetchSearchSuccess = (result: SearchResult) => action(SEARCH_FETCH_SUCCESS, result);
export const fetchSearchError = (err: HubError) => action(SEARCH_FETCH_ERROR, err);
