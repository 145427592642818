import { action } from 'typesafe-actions';
import {
  SIGNALR_CONNECTION_LOST,
  SIGNALR_CONNECTION_ESTABLISHED,
  SIGNALR_CONNECTION_RECONNECTING,
  SIGNALR_CONNECTING_ERROR,
  SIGNALR_CONNECTION_RECONNECTING_SUCCESS,
} from '../constants/signalR.constants';

export const signalRConnectionEstablished = () => action(SIGNALR_CONNECTION_ESTABLISHED);
export const signalRConnectionLost = () => action(SIGNALR_CONNECTION_LOST);

export const signalRReconnecting = () => action(SIGNALR_CONNECTION_RECONNECTING);
export const signalRReconnectingSuccess = () => action(SIGNALR_CONNECTION_RECONNECTING_SUCCESS);
export const signalRConnectingError = (err: Error) => action(SIGNALR_CONNECTING_ERROR, err);
