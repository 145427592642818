import { Ii18nStrings, Ii18nErrors } from '../LanguageService';

export const stringsDe: Ii18nStrings = {
  buttonLogin: 'Login mit Spotify',
  labelUsername: 'Nutzername',
  labelPassword: 'Password',
  menuPlaylist: 'Playliste',
  menuSearch: 'Suche',
  menuManagement: 'Verwaltung',
  menuSettings: 'Einstellungen',
  lobbyEmptyTitle: 'Keine Session gefunden!',
  lobbyEmptyMessage: 'Do bist noch keiner Session beigetreten...',
  lobbyTitle: 'Erstellen oder Beitreten?',
  lobbyMessage: 'Trete der Session deiner Freunde bei oder erstelle eine eigene!',
  lobbyJoinSessionButton: 'Beitreten',
  lobbyCreateSessionButton: 'Session erstellen',
  lobbyScanQRButton: 'QR Code scannen',
  lobbySessionKey: 'Code',
  lobbyChooseSession: 'Wähle deine Session',
  lobbySwitchOrCreateSession: 'Wechsle in eine deiner Sessions oder erzeuge eine neue!',
  dashboardImportPlaylists: 'Playlists Importieren',
  importTracksAccept: 'OK',
  importTracksDecline: 'Abbrechen',
  importTracksTitle: 'Playlisten importieren',
  sessionManagePlayRate: 'Wiedergaberate',
  newSessionDialogTitle: 'Neue Session erstellen',
  newSessionDialogMessage:
    'Starte eine neue Session und lass die Party beginnen! Gib deiner Session einen coolen Namen:',
  newSessionDialogConfirm: 'OK',
  newSessionDialogCancel: 'Abbrechen',
  leaveSessionDialogTitle: 'Session verlassen?',
  leaveSessionDialogMessage: 'Möchtest du die Session wirklich verlassen?',
  leaveSessionDialogConfirm: 'Verlassen',
  leaveSessionDialogCancel: 'Bleiben',
  bottomNavHome: 'Home',
  bottomNavUserPlaylist: 'Playlist',
  bottomNavDistribution: 'Verteilung',
  bottomNavSessionSettings: 'Einstellungen',
  appDrawerHome: 'Home',
  appDrawerSessions: 'Sessions',
  appDrawerSettings: 'Einstellungen',
  appDrawerLogout: 'Abmelden',
  appBarTitleHome: 'Home',
  appBarTitleLobby: 'Sessions',
  appBarTitleUserPlaylist: 'Meine Playlist',
  appBarTitleUserDistribution: 'Verteilung',
  appBarTitleUserSettings: 'Einstellungen',
  appBarTitleSessionSettings: 'Session Einstellungen',
  searchResultTracks: 'Tracks',
  searchResultAlbums: 'Alben',
  searchResultPlaylists: 'Playlisten',
  searchResultMyPlaylists: 'Meine Playlisten',
  searchInstructionsTitle: 'Füge Tracks hinzu',
  searchInstructionsMessage: 'Suche nach Tracks und füge diese zu deiner Playlist hinzu',
  searchNotFoundTitle: 'Nichts gefunden',
  searchNotFoundMessage: 'Deine Suche ergab keine Ergebnisse.',
  search: 'Suche...',
  sessionListNotStarted: 'Nicht gestartet',
  darkTheme: 'Dunkel',
  defaultTheme: 'Hell',
  searchAddTracks: 'Hinzufügen',
  searchCancel: 'Abbrechen',
  appLoadingMessage: 'Lade App...',
  userPlaylistLoadingMessage: 'Lade Playlist...',
  playlistEmptyTitle: 'Leere Playlist',
  playlistEmptyMessage: 'Füge Tracks zu deiner Playlist hinzu um in deiner Session mizuwirken!',
  playlistAddTracks: 'Tracks hinzufügen',
  playlistBlocked: 'Blockiert',
  playlistPlayedRecently: 'vor kurzem gespielt',
  dashboardPlaceholderTitleOwner: 'Starte die Party',
  dashboardPlaceholderMessageOwner:
    'Lasse eine neue Playlist generieren, um die Party zu starten! Achte darauf, dass Songs in deiner Playlist vorhanden sind.',
  dashboardPlaceholderTitle: 'Die Party fängt gleich an...',
  dashboardPlaceholderMessage:
    'Warte bis dein Gastgeber die Party startet! In der Zwischenzeit kanns du Songs zu deiner Playlist hinzufügen.',
  sessionSettingsExcludeGenrePlaceholder: 'Genres blockieren',
  sessionSettingsExcludeGenreTitle: 'Genres blockieren',
  sessionSettingsExcludeGenreMessage:
    'Blockiere Genres die du auf deiner Party nicht hören möchtest! Lieder die bereits in der Queue sind werden nicht gelöscht.',
  sessionSettingsJukeboxModeTitle: 'Jukebox Modus (coming soon!)',
  sessionSettingsJukeboxModeMessage:
    'Ändere die Art und Weise wie die Jukebox die nächsten Songs auswählt.',
  playlistTrackMoveTop: 'An den Anfang',
  playlistTrackMoveBottom: 'Ans Ende',
  playlistTrackMoveUp: 'Einen nach oben',
  playlistTrackMoveDown: 'Einen nach unten',
  playlistTrackRemove: 'Entfernen',
  speedDialActionAdd: 'Hinzufügen',
  speedDialActionShuffle: 'Mischen',
  speedDialActionEmptyPlaylist: 'Playlist leeren',
  userPlaylistShuffleDialogTitle: 'Playlist mischen?',
  userPlaylistShuffleDialogMessage: 'Möchtest du deine Playlist wirklich mischen?',
  userPlaylistShuffleDialogConfirm: 'Mischen',
  userPlaylistShuffleDialogCancel: 'Abbruch',
  userPlaylistEmptyPlaylistDialogTitle: 'Playlist leeren?',
  userPlaylistEmptyPlaylistDialogMessage: 'Möchtest du deine Playlist wirklich leeren?',
  userPlaylistEmptyPlaylistDialogConfirm: 'Leeren',
  userPlaylistEmptyPlaylistDialogCancel: 'Abbruch',
  addDialogDeselectAll: 'Alle abwählen',
  addDialogSelectAll: 'Alle wählen'
};

export const errorsDe: Ii18nErrors = {
  session: {
    create: 'Erstellen der Session fehlgeschlagen',
    join: 'Beitreten der Session fehlgeschlagen',
    leave: 'Verlassen der Session fehlgeschlagen',
  },
  spotify: {
    pause: {
      default: 'Pausieren fehlgeschlagen',
    },
    play: {
      deviceNotFound: 'Gerät nicht gefunden',
      notEnoughSongs: 'Nicht genügend Songs, füge weitere hinzu!',
      default: 'Abspielen fehlgeschlagen',
    },
  },
  search: {
    fetch: 'Anfrage konnte nicht ausgeführt werden',
  },
};
