import React from 'react';
import autobind from 'autobind-decorator';
import { Dialog, IconButton, AppBar, Grid, Toolbar } from '@material-ui/core';
import { localisation } from '../../../i18n/LanguageService';
import styles from './JoinSessionDialog.module.css';
import ArrowBack from '@material-ui/icons/ArrowBack';
import LobbyEmptyPlaceholder from './LobbyEmptyPlaceholder';
import NewSessionDialog from './NewSessionDialog';
import { store } from '../../../redux/store';
import { createSessionRequest, joinSessionRequest } from '../../../redux/actions/session.action';
import { Session } from '../../../models/Session';
import { AppState } from '../../../redux/reducer';
import { connect } from 'react-redux';
import { currentSessionSelector } from '../../../redux/selectors/session/currentSession.selector';
import { DeviceSize } from '../../../redux/reducers/general.reducer';

export interface JoinSessionDialogProps {
  open: boolean;
  currentSession?: Session;
  onClose: (sessionId?: string) => void;
  deviceSize?: DeviceSize;
}

export interface JoinSessionDialogState {
  sessionId: string;
  newSessionDialogOpen: boolean;
}

const mapStateToProps = (state: AppState) => {
  return {
    currentSession: currentSessionSelector(state),
    deviceSize: state.general.deviceSize,
  };
};

class JoinSessionDialog extends React.Component<JoinSessionDialogProps, JoinSessionDialogState> {
  constructor(props: JoinSessionDialogProps) {
    super(props);
    this.state = { sessionId: '', newSessionDialogOpen: false };
  }

  @autobind
  handleClose() {
    if (this.state.sessionId) {
      this.props.onClose(this.state.sessionId);
    } else {
      this.props.onClose();
    }
  }

  @autobind
  createNewSession(session?: Partial<Session>) {
    if (session && session.name) {
      store.dispatch(createSessionRequest(session.name));
    }
    this.setState({ newSessionDialogOpen: false });
  }

  @autobind
  openNewSessionDialog() {
    this.setState({ newSessionDialogOpen: true });
  }

  @autobind
  joinSession(sessionId?: string) {
    if (sessionId) {
      store.dispatch(joinSessionRequest(sessionId));
    }
  }

  @autobind
  onBack() {
    this.setState({ newSessionDialogOpen: false });
    this.props.onClose();
  }

  render() {
    return (
      <Dialog
        fullScreen={this.props.deviceSize! <= DeviceSize.TABLET}
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <AppBar position="static" className={styles.appbar} color="transparent" elevation={0}>
          <Toolbar>
            <IconButton color="inherit" aria-label="Menu" onClick={this.onBack}>
              <ArrowBack />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Grid item className={styles.item} xs={12}>
          <LobbyEmptyPlaceholder
            title={localisation.strings.lobbyTitle}
            message={localisation.strings.lobbyMessage}
            onJoinSession={this.joinSession}
            onNewSession={this.openNewSessionDialog}
          />
        </Grid>
        <NewSessionDialog open={this.state.newSessionDialogOpen} onClose={this.createNewSession} />
      </Dialog>
    );
  }
}

export default connect(mapStateToProps)(JoinSessionDialog);
