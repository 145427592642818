export class HubError extends Error {
  public readonly category: number;
  public readonly code: string;
  public readonly description: string;

  constructor(err: Error) {
    const message = err.message;
    const regexResult = /HubException\:\s*({.*})/gm.exec(message);
    if (regexResult) {
      const json = regexResult[1];

      const obj = JSON.parse(json);
      super(`${obj.category} - ${obj.code} - ${obj.description}`);

      this.category = obj.category;
      this.code = obj.code;
      this.description = obj.description;
    } else {
      super(
        `${0} - ${'FAILED_TO_PARSE_ERROR'} - ${`Could not parse error to HubError ${message}`}`
      );
      this.category = 0;
      this.code = 'FAILED_TO_PARSE_ERROR';
      this.description = `Could not parse error to HubError ${message}`;
      console.error(`Could not parse error to HubError ${message}`);
    }
  }
}
