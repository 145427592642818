import React from 'react';

import autobind from 'autobind-decorator';
import { Tabs, Tab, AppBar } from '@material-ui/core';
import PrivacyPloicyPage from './PrivacyPolicyPage';
import LegalNoticePage from './LegalNoticePage';

export interface LegalTabsPageProps {}

export interface LegalTabsPageState {
  value: number;
}

class LegalTabsPage extends React.Component<LegalTabsPageProps, LegalTabsPageState> {
  constructor(props: LegalTabsPageProps) {
    super(props);
    this.state = { value: 0 };
  }

  @autobind
  handleChange(event, value) {
    this.setState({ value });
  }

  render() {
    const value = this.state.value;
    return (
      <>
        <AppBar position="sticky">
          <Tabs value={value} onChange={this.handleChange}>
            <Tab label="Privacy Policy" />
            <Tab label="Legal Notice" />
          </Tabs>
        </AppBar>
        {value === 0 && <PrivacyPloicyPage />}
        {value === 1 && <LegalNoticePage />}
      </>
    );
  }
}

export default LegalTabsPage;
