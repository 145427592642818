import React from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Dialog,
  TextField,
  withTheme,
  Theme,
  withMobileDialog,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import { Session } from '../../../models/Session';
import { localisation } from '../../../i18n/LanguageService';
import styles from './NewSessionDialog.module.css';
import autobind from 'autobind-decorator';
import HavingFunIllustration from '../../illustrations/HavingFun';
import { DeviceSize } from '../../../redux/reducers/general.reducer';
import { AppState } from '../../../redux/reducer';
import { connect } from 'react-redux';
import ArrowBack from '@material-ui/icons/ArrowBack';

export interface NewSessionDialogProps {
  open: boolean;
  theme?: Theme;
  onClose: (session?: Partial<Session>) => void;
  deviceSize?: DeviceSize;
}

export interface NewSessionDialogState {
  sessionName?: string;
}

const mapStateToProps = (state: AppState) => {
  return {
    deviceSize: state.general.deviceSize,
  };
};

class NewSessionDialog extends React.Component<NewSessionDialogProps, NewSessionDialogState> {
  constructor(props: NewSessionDialogProps) {
    super(props);
    this.state = { sessionName: '' };
  }

  @autobind
  handleCloseConfirm() {
    this.props.onClose({ name: this.state.sessionName });
  }

  @autobind
  handleCloseCancel() {
    this.props.onClose();
  }

  @autobind
  handleSessionNameChange(evt: any) {
    this.setState({ sessionName: evt.target.value });
  }

  @autobind
  onBack() {
    this.props.onClose();
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        fullScreen={this.props.deviceSize! <= DeviceSize.TABLET}
        onClose={this.handleCloseCancel}
        aria-labelledby="form-dialog-title"
      >
        {this.props.deviceSize! > DeviceSize.TABLET && (
          <AppBar position="static" className={styles.appbar} color="transparent" elevation={0}>
            <Toolbar>
              <IconButton color="inherit" aria-label="Menu" onClick={this.onBack}>
                <ArrowBack />
              </IconButton>
            </Toolbar>
          </AppBar>
        )}

        <div className={styles.illustration}>
          <HavingFunIllustration color={this.props.theme!.palette.primary.main} />
        </div>

        <DialogContent>
          <Typography color="primary" variant="h6" className={styles.importantText}>
            {localisation.strings.newSessionDialogTitle}
          </Typography>
          <DialogContentText className={styles.importantText}>
            {localisation.strings.newSessionDialogMessage}
          </DialogContentText>
          <TextField
            autoFocus
            autoComplete="off"
            className={styles.input}
            id="name"
            label="Name"
            fullWidth
            value={this.state.sessionName}
            onChange={this.handleSessionNameChange}
          />
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button onClick={this.handleCloseCancel} color="primary">
            {localisation.strings.newSessionDialogCancel}
          </Button>
          <Button onClick={this.handleCloseConfirm} color="primary">
            {localisation.strings.newSessionDialogConfirm}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connect(mapStateToProps)(withTheme(NewSessionDialog));
