import React from 'react';
import * as QRCode from 'qrcode.react';
import styles from './QrCodePage.module.css';
import SyncIllustration from '../../illustrations/Sync';
import { withTheme, Theme, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import { Session } from '../../../models/Session';
import { currentSessionSelector } from '../../../redux/selectors/session/currentSession.selector';
import { string } from 'prop-types';

export interface QrCodePageProps {
  theme?: Theme;
  frontendUrl?: string;
  currentSession?: Session;
  appInitialized: boolean;
}

export interface QrCodePageState {}

const mapStateToProps = (state: AppState) => {
  return {
    frontendUrl: state.settings.frontendUrl,
    currentSession: currentSessionSelector(state),
    appInitialized: state.general.initialized,
  };
};

class QrCodePage extends React.Component<QrCodePageProps, QrCodePageState> {
  constructor(props: QrCodePageProps) {
    super(props);
  }

  formatSessionId(sessionId: string) {
    return `${sessionId.substr(0, 3)} ${sessionId.substr(3, 6)}`;
  }

  render() {
    return (
      <div className={styles.qrcodePage}>
        <div className={styles.illustrationWrapper}>
          <div className={styles.illustrationFlex}>
            <div className={styles.illustration}>
              <SyncIllustration color={this.props.theme!.palette.primary.main} />
            </div>
          </div>
        </div>
        <div
          className={styles.qrWrapper}
          style={{ backgroundColor: this.props.theme!.palette.background.paper }}
        >
          <QRCode
            value={`${this.props.frontendUrl}/joinSession?id=${
              this.props.currentSession!.sessionId
            }`}
          />
        </div>

        <div className={styles.sessionInfo}>
          <Typography color="primary" variant="subtitle2">
            Session
          </Typography>
          <Typography color="primary" variant="h3">
            {this.props.currentSession
              ? this.formatSessionId(this.props.currentSession.sessionId)
              : ''}
          </Typography>
        </div>
      </div>
    );
  }
}

export default withTheme(connect(mapStateToProps)(QrCodePage));
