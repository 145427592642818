import React from 'react';
import styles from './QrCodeScannerDialog.module.css';
import autobind from 'autobind-decorator';
import { Dialog, DialogActions, Button } from '@material-ui/core';
import { localisation } from '../../../i18n/LanguageService';
import QrReader from 'react-qr-reader';

export interface QrCodeScannerDialogProps {
  open: boolean;

  onClose: (sessionId?: string) => void;
}

export interface QrCodeScannerDialogState {}

class QrCodeScannerDialog extends React.Component<
  QrCodeScannerDialogProps,
  QrCodeScannerDialogState
> {
  constructor(props: QrCodeScannerDialogProps) {
    super(props);
  }

  @autobind
  handleCloseCancel() {
    this.props.onClose();
  }

  @autobind
  handleError(err) {}

  @autobind
  handleScan(data: string) {
    if (data) {
      const split = data.split('?id=');

      const sessionId = split[split.length - 1];

      if (sessionId) {
        if (this.props.onClose) {
          this.props.onClose(sessionId);
        }
      }
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleCloseCancel}
        aria-labelledby="form-dialog-title"
      >
        <div className={styles.qrQrapper}>
          <QrReader
            delay={300}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: '100%' }}
          />
        </div>
        <DialogActions className={styles.actions}>
          <Button onClick={this.handleCloseCancel} color="primary">
            {localisation.strings.newSessionDialogCancel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default QrCodeScannerDialog;
