import { ActionType } from 'typesafe-actions';
import * as actions from '../actions/general.action';

export type GeneralAction = ActionType<typeof actions>;

export const notifications = (state = [], action) => {
  switch (action.type) {
    case 'ENQUEUE_SNACKBAR':
      return [...state, (action as any).notification];

    case 'REMOVE_SNACKBAR':
      return state.filter(notification => (notification as any).key !== (action as any).key);

    default:
      return state;
  }
};
