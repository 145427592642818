import React from 'react';
import {
  Theme,
  withTheme,
  Typography,
  Avatar,
  ListItemText,
  MenuList,
  MenuItem,
  ListItemIcon,
  ClickAwayListener,
  Popper,
  Grow,
  Paper,
  ListItemAvatar,
} from '@material-ui/core';
import { Track, FullTrack } from '../../../models/Track';
import autobind from 'autobind-decorator';
import { Item } from '../../general/components/SwipeableList/SwipeableList';
import Chip from '../../dashboard/components/Chip';
import styles from './UserTrackList.module.css';
import { AppState } from '../../../redux/reducer';
import { currentSessionSelector } from '../../../redux/selectors/session/currentSession.selector';
import { Session } from '../../../models/Session';
import { connect } from 'react-redux';
import { localisation } from '../../../i18n/LanguageService';
import FlipMove from 'react-flip-move';
import { isIOS } from 'react-device-detect';

import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import ArrowUp from '@material-ui/icons/ArrowUpward';
import ArrowDown from '@material-ui/icons/ArrowDownward';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete'
import { UserPlaylist } from '../../../models/UserPlaylist';
import { FixedSizeList as List } from 'react-window';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';

import ListItem from '@material-ui/core/ListItem';
import MuiListItemText from '@material-ui/styles';

export interface UserTrackListProps {
  playlist?: UserPlaylist;
  onClick?(track: FullTrack): void;
  removeable?: boolean;
  onRemove?(tack: FullTrack): void;
  theme?: Theme;
  currentSession?: Session;
  onMoveOneUp?(trackId: string): void;
  onMoveOneDown?(trackId: string): void;
  onMoveOneTop?(trackId: string): void;
  onMoveOneBottom?(trackId: string): void;
}

export interface UserTrackListState {
  contextMenuPosition: { x: number; y: number };
  isContextMenuOpen: boolean;
  contextMenuTrack?: FullTrack;
  anchor?: any;
  tracks?: FullTrack[];
  height?: number;
  width?: number;
}

const mapStateToProps = (state: AppState) => {
  return {
    currentSession: currentSessionSelector(state),
  };
};

class UserTrackList extends React.Component<UserTrackListProps, UserTrackListState> {
  divElement: any;

  constructor(props: UserTrackListProps) {
    super(props);
    this.state = {
      contextMenuPosition: { x: 0, y: 0 },
      isContextMenuOpen: false,
      tracks: this.props.playlist ? this.props.playlist.tracks : [],
      width: 0,
      height: 0,
    };
  }

  componentDidMount() {
    const height = this.divElement.clientHeight;
    const width = this.divElement.clientWidth;
    this.setState({ height, width });
  }

  formatSeconds(secs: number) {
    const secNum = secs / 1000;

    const minutes = Math.floor(secNum / 60);
    const seconds = Math.floor(secNum - minutes * 60);

    let minutesString: string = '00';
    let secondsString: string = '00';

    if (minutes < 10) {
      minutesString = '0' + minutes;
    } else {
      minutesString = minutes.toString();
    }
    if (seconds < 10) {
      secondsString = '0' + seconds;
    } else {
      secondsString = seconds.toString();
    }

    return minutesString + ':' + secondsString;
  }

  static getDerivedStateFromProps(
    nextProps: UserTrackListProps,
    prevState: UserTrackListState
  ) {
    return Object.assign(prevState, {
      tracks: nextProps.playlist ? nextProps.playlist.tracks : [],
    });
  }

  @autobind
  onDelete() {
    const track = this.state.contextMenuTrack;
    if (this.props.onRemove && track) {
      this.props.onRemove(track);
    }

    this.setState({ isContextMenuOpen: false });
  }

  @autobind
  fitsGenres(track: FullTrack) {
    const session = this.props.currentSession;

    if (session && session.settings && session.settings.genreBlacklist) {
      const genres = session.settings.genreBlacklist;

      if (track.genres && track.genres.length > 0) {
        let match = false;
        track.genres.forEach(genreA => {
          genres.forEach(genreB => {
            if ((genreA as any).includes(genreB)) {
              match = true;
              return;
            }
          });
        });
        return match;
      }
    }
    return false;
  }

  @autobind
  wasRecentlyPlayed(track: FullTrack) {
    const session = this.props.currentSession;

    if (session && session.lastPlayedTracks) {
      let match = false;
      session.lastPlayedTracks.forEach(trackId => {
        if (trackId === track.id) {
          match = true;
          return;
        }
      })

      return match;
    }

    return false;
  }

  @autobind
  onContextMenu(evt: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>, track: FullTrack) {
    evt.preventDefault();
    if (evt.currentTarget) {
      const rect = evt.currentTarget.getBoundingClientRect();
      const getBoundingClientRect = () => rect;

      this.setState({
        isContextMenuOpen: true,
        anchor: {
          clientWidth: getBoundingClientRect().width,
          clientHeight: getBoundingClientRect().height,
          getBoundingClientRect,
        },
        contextMenuTrack: track,
      });
    }
  }

  @autobind
  moveOneUp() {
    if (this.props.onMoveOneUp) {
      const track = this.state.contextMenuTrack;
      if (this.state.tracks && track) {
        const index = this.state.tracks.indexOf(track);

        if (index > 0) {
          this.props.onMoveOneUp(track.id);
        }
      }
    }

    this.setState({ isContextMenuOpen: false });
  }

  @autobind
  moveOneDown() {
    if (this.props.onMoveOneDown) {
      const track = this.state.contextMenuTrack;
      if (this.state.tracks && track) {
        const index = this.state.tracks.indexOf(track);

        if (index < this.state.tracks.length - 1) {
          this.props.onMoveOneDown(track.id);
        }
      }
    }

    this.setState({ isContextMenuOpen: false });
  }

  @autobind
  moveToTop() {
    if (this.props.onMoveOneTop) {
      const track = this.state.contextMenuTrack;
      if (this.state.tracks && track) {
        const index = this.state.tracks.indexOf(track);

        if (index != 0) {
          this.props.onMoveOneTop(track.id);
        }
      }
    }

    this.setState({ isContextMenuOpen: false });
  }

  @autobind
  moveToBottom() {
    if (this.props.onMoveOneBottom) {
      const track = this.state.contextMenuTrack;
      if (this.state.tracks && track) {
        const index = this.state.tracks.indexOf(track);

        if (index != this.state.tracks.length - 1) {
          this.props.onMoveOneBottom(track.id);
        }
      }
    }

    this.setState({ isContextMenuOpen: false });
  }

  render() {
    if (this.state.tracks) {
      const items = this.state.tracks.map(track => {
        return new Item(
          track.id,
          track.name,
          `${track.artist} - ${track.album}`,
          track.thumbnail,
          this.formatSeconds(track.duration_ms)
        );
      });

      const Row = ({ index, style }) => {
        const track = this.state.tracks![index];
        return (
          <ListItem key={track.id} style={style}>
            <ListItemAvatar>
              <Avatar src={track.thumbnail} style={{ borderRadius: 0 }} />
            </ListItemAvatar>

            <ListItemText
              primaryTypographyProps={{ noWrap: true, display: 'block' }}
              secondaryTypographyProps={{ noWrap: true, display: 'block' }}
              style={{ paddingRight: '20px', userSelect: 'none' }}
              primary={track.name}
              secondary={
                <div className={styles.secondRow}>
                  {this.fitsGenres(track) ? (
                    <Chip label={localisation.strings.playlistBlocked} />
                  ) : this.wasRecentlyPlayed(track) ? (
                    <Chip label={localisation.strings.playlistPlayedRecently} />
                  ) : ""}

                  <Typography
                    variant="subtitle2"
                    style={{ color: this.props.theme!.palette.text.secondary }}
                  >
                    {track.artist}
                  </Typography>
                </div>
              }
            />

            <ListItemText
              secondary={this.formatSeconds(track.duration_ms)}
              className={styles.secondary}
            />

            <IconButton edge="end" onClick={e => this.onContextMenu(e, track)} style={{ cursor: 'pointer' }}>
              <MoreVertIcon style={{ color: this.props.theme!.palette.text.primary }} />
            </IconButton>
          </ListItem>
        );
      };

      return (
        <div
          ref={divElement => {
            this.divElement = divElement;
          }}
          style={{ display: 'block', width: '100%', height: '100%' }}
        >
          <List
            height={this.state.height}
            itemCount={this.state.tracks.length}
            itemSize={74}
            width={this.state.width}
            itemKey={index => this.state.tracks![index].id}
          >
            {Row}
          </List>

          <Popper
            open={this.state.isContextMenuOpen}
            anchorEl={this.state.anchor}
            transition
            placement="bottom-end"
            // This is a hack for fixing apparently not displaying of context menu in Safari on iOS. After investigation it seems that the menu is shown but it's
            // positioned outside of the visible area. By disabling portal it works on iOS but does not in Chrome on Windows anymore (or if a wide screen is used).
            // I could not track down the reason for either displaying problem and I'm desperate. So that's how I deal with it now. Feel free to fix it properly, but make
            // sure to test on iOS.
            disablePortal={isIOS}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper style={{ zIndex: 1000 }}>
                  <ClickAwayListener
                    onClickAway={() => this.setState({ isContextMenuOpen: false })}
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                  >
                    <MenuList
                      style={{
                        color: this.props.theme!.palette.text.primary,
                      }}
                    >
                      <MenuItem onClick={this.onDelete}>
                        <ListItemIcon>
                          <DeleteIcon
                            style={{ color: this.props.theme!.palette.text.primary }}
                          />
                        </ListItemIcon>
                        <ListItemText inset primary={localisation.strings.playlistTrackRemove} />
                      </MenuItem>
                      <MenuItem onClick={this.moveOneUp}>
                        <ListItemIcon>
                          <KeyboardArrowUp
                            style={{ color: this.props.theme!.palette.text.primary }}
                          />
                        </ListItemIcon>
                        <ListItemText inset primary={localisation.strings.playlistTrackMoveUp} />
                      </MenuItem>
                      <MenuItem onClick={this.moveOneDown}>
                        <ListItemIcon>
                          <KeyboardArrowDown
                            style={{ color: this.props.theme!.palette.text.primary }}
                          />
                        </ListItemIcon>
                        <ListItemText inset primary={localisation.strings.playlistTrackMoveDown} />
                      </MenuItem>
                      <MenuItem onClick={this.moveToTop}>
                        <ListItemIcon>
                          <ArrowUp style={{ color: this.props.theme!.palette.text.primary }} />
                        </ListItemIcon>
                        <ListItemText inset primary={localisation.strings.playlistTrackMoveTop} />
                      </MenuItem>
                      <MenuItem onClick={this.moveToBottom}>
                        <ListItemIcon>
                          <ArrowDown style={{ color: this.props.theme!.palette.text.primary }} />
                        </ListItemIcon>
                        <ListItemText inset primary={localisation.strings.playlistTrackMoveBottom} />
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div >
      );
    }
    return <></>;
  }
}

export default connect(mapStateToProps)(withTheme(UserTrackList));
