import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  withTheme,
  Theme,
} from '@material-ui/core';
import { localisation } from '../../../i18n/LanguageService';
import styles from './ShuffleUserPlaylistDialog.module.css';
import autobind from 'autobind-decorator';
import { Session } from 'inspector';
import RandomIllustration from '../../illustrations/RandomIllustration';

export interface ShuffleUserPlaylistDialogProps {
  open: boolean;
  theme?: Theme;
  onClose: (leave: boolean) => void;
}

export interface ShuffleUserPlaylistDialogState {}

class ShuffleUserPlaylistDialog extends React.Component<ShuffleUserPlaylistDialogProps, ShuffleUserPlaylistDialogState> {
  constructor(props: ShuffleUserPlaylistDialogProps) {
    super(props);
  }

  @autobind
  handleCloseConfirm() {
    this.props.onClose(true);
  }

  @autobind
  handleCloseCancel() {
    this.props.onClose(false);
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleCloseCancel}
        aria-labelledby="form-dialog-title"
      >
        <RandomIllustration color={this.props.theme!.palette.primary.main}  className={styles.randomIllustration}/>
        <DialogTitle id="form-dialog-title">
          {localisation.strings.userPlaylistShuffleDialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{localisation.strings.userPlaylistShuffleDialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button onClick={this.handleCloseCancel} color="primary">
            {localisation.strings.userPlaylistShuffleDialogCancel}
          </Button>
          <Button onClick={this.handleCloseConfirm} color="primary">
            {localisation.strings.userPlaylistShuffleDialogConfirm}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTheme(ShuffleUserPlaylistDialog);
