import { action } from 'typesafe-actions';
import { UserPlaylist } from '../../models/UserPlaylist';
import { Track, FullTrack } from '../../models/Track';
import {
  USERPLAYLIST_FETCH_REQUEST,
  USERPLAYLIST_FETCH_SUCCESS,
  USERPLAYLIST_ADD_TRACKS_REQUEST,
  USERPLAYLIST_ADD_TRACKS_SUCCESS,
  USERPLAYLIST_REMOVE_TRACK_REQUEST,
  USERPLAYLIST_REMOVE_TRACK_SUCCESS,
  USERPLAYLIST_FETCH_ERROR,
  USERPLAYLIST_REMOVE_TRACK_ERROR,
  USERPLAYLIST_ADD_TRACKS_ERROR,
  USERPLAYLIST_CHANGED_PLAYLIST,
  USERPLAYLIST_SONG_MOVED_ONE,
  USERPLAYLIST_SONG_MOVED_TOP_BOTTOM,
  USERPLAYLIST_SHUFFLE,
  USERPLAYLIST_EMPTY_PLAYLIST
} from '../constants/playlist.constants';
import { HubError } from '../../models/HubError';

export const fetchUserPlaylistRequest = () => action(USERPLAYLIST_FETCH_REQUEST);
export const fetchUserPlaylistSuccess = (playlist: UserPlaylist) =>
  action(USERPLAYLIST_FETCH_SUCCESS, playlist);
export const fetchUserPlaylistError = (err: HubError) => action(USERPLAYLIST_FETCH_ERROR, err);

export const userPlaylistAddTracksRequest = (tracks: Track[]) =>
  action(USERPLAYLIST_ADD_TRACKS_REQUEST, tracks);
export const userPlaylistAddTracksSuccess = (tracks: FullTrack[]) =>
  action(USERPLAYLIST_ADD_TRACKS_SUCCESS, tracks);
export const userPlaylistAddTracksError = (err: HubError) =>
  action(USERPLAYLIST_ADD_TRACKS_ERROR, err);

export const userPlaylistRemoveTrackRequest = (track: Track) =>
  action(USERPLAYLIST_REMOVE_TRACK_REQUEST, track);
export const userPlaylistRemoveTrackSuccess = (trackId: string) =>
  action(USERPLAYLIST_REMOVE_TRACK_SUCCESS, trackId);
export const userPlaylistRemoveTrackError = (err: HubError) =>
  action(USERPLAYLIST_REMOVE_TRACK_ERROR, err);

export const userPlaylistChangedPlaylist = (userIds: string[]) =>
  action(USERPLAYLIST_CHANGED_PLAYLIST, userIds);

export const userPlaylistSongMovedOne = (trackId: string, up: boolean) =>
  action(USERPLAYLIST_SONG_MOVED_ONE, { trackId, up });
export const userPlaylistSongMovedTopOrBottom = (trackId: string, top: boolean) =>
  action(USERPLAYLIST_SONG_MOVED_TOP_BOTTOM, { trackId, top });

export const userPlaylistShuffle = () =>
  action(USERPLAYLIST_SHUFFLE);
export const userPlaylistEmptyPlaylist = () =>
  action(USERPLAYLIST_EMPTY_PLAYLIST);
