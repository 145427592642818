import React from 'react';
import styles from './TrackDetailDialog.module.css';
import { FullTrack } from '../../../models/Track';
import { Typography, Dialog, LinearProgress } from '@material-ui/core';
import autobind from 'autobind-decorator';
import MemoryIcon from '@material-ui/icons/Memory';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import Mood from '@material-ui/icons/Mood';
import FlashOn from '@material-ui/icons/FlashOn';
import Headset from '@material-ui/icons/Headset';
import MusicNote from '@material-ui/icons/MusicNote';
import Chat from '@material-ui/icons/Chat';
import Chip from './Chip';

export interface TrackDetailDialogProps {
  track?: FullTrack;
  open: boolean;
  onClose?(): void;
}

export interface TrackDetailDialogState {}

class TrackDetailDialog extends React.Component<TrackDetailDialogProps, TrackDetailDialogState> {
  constructor(props: TrackDetailDialogProps) {
    super(props);
  }

  @autobind
  handleClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        {this.props.track ? (
          <div className={styles.scrollContainer}>
            <div className={styles.thumbnailWrapper}>
              <img src={this.props.track!.thumbnail} className={styles.thumbnail} />
            </div>
            <div style={{ padding: '8px' }}>
              <Typography
                color="primary"
                style={{ textAlign: 'center' }}
                variant="h6"
                noWrap={true}
              >
                {this.props.track!.name}
              </Typography>

              <Typography
                color="textSecondary"
                style={{ textAlign: 'center' }}
                variant="subtitle2"
                noWrap={true}
              >
                {this.props.track!.artist}
              </Typography>
              <div className={styles.genres}>
                {this.props.track.genres &&
                  this.props.track.genres.map(genre => <Chip key={genre} label={genre} />)}
              </div>
              <div style={{ clear: 'both' }} />
              <div className={styles.grid}>
                <DirectionsRun color="primary" />
                <Typography
                  color="textPrimary"
                  style={{ marginRight: 16 }}
                  variant="subtitle1"
                  noWrap={true}
                >
                  Tempo:
                </Typography>
                <Typography
                  color="primary"
                  style={{ marginRight: 16 }}
                  variant="subtitle1"
                  noWrap={true}
                >
                  {Math.round(this.props.track.tempo)} BPM
                </Typography>

                <MemoryIcon color="primary" />
                <Typography
                  color="textPrimary"
                  style={{ marginRight: 16 }}
                  variant="subtitle1"
                  noWrap={true}
                >
                  Danceability:
                </Typography>
                <LinearProgress variant="determinate" value={this.props.track.danceability * 100} />

                <FlashOn color="primary" />
                <Typography
                  color="textPrimary"
                  style={{ marginRight: 16 }}
                  variant="subtitle1"
                  noWrap={true}
                >
                  Energy:
                </Typography>
                <LinearProgress variant="determinate" value={this.props.track.energy * 100} />

                <Headset color="primary" />
                <Typography
                  color="textPrimary"
                  style={{ marginRight: 16 }}
                  variant="subtitle1"
                  noWrap={true}
                >
                  Acousticness:
                </Typography>
                <LinearProgress variant="determinate" value={this.props.track.acousticness * 100} />

                <MusicNote color="primary" />
                <Typography
                  color="textPrimary"
                  style={{ marginRight: 16 }}
                  variant="subtitle1"
                  noWrap={true}
                >
                  Instrumentalness:
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={this.props.track.instrumentalness * 100}
                />

                <Chat color="primary" />
                <Typography
                  color="textPrimary"
                  style={{ marginRight: 16 }}
                  variant="subtitle1"
                  noWrap={true}
                >
                  Speechiness:
                </Typography>
                <LinearProgress variant="determinate" value={this.props.track.speechiness * 100} />

                <Mood color="primary" />
                <Typography
                  color="textPrimary"
                  style={{ marginRight: 16 }}
                  variant="subtitle1"
                  noWrap={true}
                >
                  Happiness:
                </Typography>
                <LinearProgress variant="determinate" value={this.props.track.valence * 100} />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Dialog>
    );
  }
}

export default TrackDetailDialog;
