import React from 'react';

export interface SelectIllustrationProps {
  color: string;
}

export interface SelectIllustrationState {}

function SelectIllustration(props: SelectIllustrationProps) {
  return (
    <svg id="b9a38b5d-9c66-4a75-a477-70b37749fa7e" data-name="Layer 1" viewBox="0 0 666.24 728.82">
      <defs>
        <linearGradient
          id="0b4bbb50-2c74-4271-bf06-9944ea4886f0"
          x1="600"
          y1="814.41"
          x2="600"
          y2="85.59"
          gradientTransform="translate(-266.87 -85.6)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="gray" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
          <stop offset="1" stopColor="gray" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="3343e46b-8287-40fd-bf41-51a7a19771a9"
          x1="333.12"
          y1="540.89"
          x2="333.12"
          y2="294.6"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          xlinkHref="#0b4bbb50-2c74-4271-bf06-9944ea4886f0"
        />
        <linearGradient
          id="53153d7b-989b-4b45-8d94-5525de3b761a"
          x1="176.82"
          y1="463.53"
          x2="176.82"
          y2="349.86"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          xlinkHref="#0b4bbb50-2c74-4271-bf06-9944ea4886f0"
        />
      </defs>
      <title>select</title>
      <rect
        x="131.23"
        width="403.77"
        height="728.81"
        rx="15.34"
        ry="15.34"
        fill="url(#0b4bbb50-2c74-4271-bf06-9944ea4886f0)"
      />
      <rect x="136.69" y="9.85" width="392.86" height="709.12" rx="13.64" ry="13.64" fill="#fff" />
      <path
        d="M698.7,113.22a31.85,31.85,0,0,1-31.39,27.09H531.61a31.85,31.85,0,0,1-31.39-27.09H428.35a14.92,14.92,0,0,0-14.92,14.92V771.86a14.92,14.92,0,0,0,14.92,14.92H771.65a14.92,14.92,0,0,0,14.92-14.92V128.14a14.92,14.92,0,0,0-14.92-14.92Z"
        transform="translate(-266.88 -85.59)"
        fill={props.color}
        opacity="0.2"
      />
      <rect x="289.9" y="36.12" width="87.55" height="5.47" rx="2" ry="2" fill="#dbdbdb" />
      <circle cx="396.05" cy="38.31" r="3.28" fill="#dbdbdb" />
      <circle cx="203.47" cy="164.78" r="40.07" fill="#fff" />
      <rect x="282.37" y="134.72" width="71.38" height="12.52" fill="#fff" />
      <rect x="282.37" y="158.51" width="196.62" height="12.52" fill="#fff" />
      <rect x="282.37" y="182.31" width="196.62" height="12.52" fill="#fff" />
      <circle cx="203.47" cy="283.75" r="40.07" fill="#fff" />
      <rect x="282.37" y="253.69" width="71.38" height="12.52" fill="#fff" />
      <rect x="282.37" y="277.49" width="196.62" height="12.52" fill="#fff" />
      <rect x="282.37" y="301.28" width="196.62" height="12.52" fill="#fff" />
      <circle cx="203.47" cy="402.72" r="40.07" fill="#fff" />
      <rect x="282.37" y="372.66" width="71.38" height="12.52" fill="#fff" />
      <rect x="282.37" y="396.46" width="196.62" height="12.52" fill="#fff" />
      <rect x="282.37" y="420.25" width="196.62" height="12.52" fill="#fff" />
      <circle cx="203.47" cy="521.69" r="40.07" fill="#fff" />
      <rect x="282.37" y="491.63" width="71.38" height="12.52" fill="#fff" />
      <rect x="282.37" y="515.43" width="196.62" height="12.52" fill="#fff" />
      <rect x="282.37" y="539.22" width="196.62" height="12.52" fill="#fff" />
      <circle cx="203.47" cy="640.66" r="40.07" fill="#fff" />
      <rect x="282.37" y="610.61" width="71.38" height="12.52" fill="#fff" />
      <rect x="282.37" y="634.4" width="196.62" height="12.52" fill="#fff" />
      <rect x="282.37" y="658.19" width="196.62" height="12.52" fill="#fff" />
      <rect
        y="294.6"
        width="666.24"
        height="246.29"
        fill="url(#3343e46b-8287-40fd-bf41-51a7a19771a9)"
      />
      <rect x="4.74" y="299.34" width="656.77" height="227.34" fill="#fff" />
      <circle cx="176.82" cy="406.7" r="56.84" fill="url(#53153d7b-989b-4b45-8d94-5525de3b761a)" />
      <circle cx="176.82" cy="406.7" r="50.52" fill={props.color} />
      <g opacity="0.2">
        <rect x="276.28" y="368.8" width="89.99" height="15.79" fill={props.color} />
        <rect x="276.28" y="398.8" width="247.87" height="15.79" fill={props.color} />
        <rect x="276.28" y="428.8" width="247.87" height="15.79" fill={props.color} />
      </g>
      <polygon
        points="209.98 386.44 198.88 377.97 169.64 416.29 153.32 403.83 144.85 414.93 166.73 431.63 166.79 431.55 172.32 435.77 209.98 386.44"
        fill="#fff"
      />
    </svg>
  );
}

export default SelectIllustration;
