import React from 'react';

export interface SyncIllustrationProps {
  color: string;
}

export interface SyncIllustrationState {}

function SyncIllustration(props: SyncIllustrationProps) {
  return (
    <svg id="855033fa-f203-4bd6-a750-76a03b91c714" data-name="Layer 1" viewBox="0 0 931 758.59">
      <defs>
        <linearGradient
          id="36a1da1a-b5ed-4992-81c7-0d29a6333ac7"
          x1="1093.68"
          y1="551.39"
          x2="1027.95"
          y2="426.89"
          gradientTransform="translate(226.08 -367.44) rotate(27.83)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="gray" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
          <stop offset="1" stopColor="gray" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="d287a4da-cdc2-42a1-98a9-ed232bbab7aa"
          x1="1097.68"
          y1="790.82"
          x2="1031.95"
          y2="666.32"
          xlinkHref="#36a1da1a-b5ed-4992-81c7-0d29a6333ac7"
        />
        <linearGradient
          id="044d3984-15d8-4f7b-a10a-036e6011c716"
          x1="1113.9"
          y1="704.46"
          x2="1012.94"
          y2="513.25"
          gradientTransform="translate(1491.06 -395.94) rotate(90)"
          xlinkHref="#36a1da1a-b5ed-4992-81c7-0d29a6333ac7"
        />
        <linearGradient
          id="4dd7781c-4432-49c7-b285-1afbf72647b5"
          x1="1068.95"
          y1="637.04"
          x2="1068.95"
          y2="578.4"
          gradientTransform="translate(93.1 -453.94) rotate(27.96)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopOpacity="0.12" />
          <stop offset="0.55" stopOpacity="0.09" />
          <stop offset="1" stopOpacity="0.02" />
        </linearGradient>
        <linearGradient
          id="f39856b3-7cd5-4a0c-afbe-fd75d54c2ee5"
          x1="300.72"
          y1="713.57"
          x2="555.76"
          y2="111.34"
          gradientTransform="translate(-7.22 0.36)"
          xlinkHref="#36a1da1a-b5ed-4992-81c7-0d29a6333ac7"
        />
        <linearGradient
          id="6d589827-8376-478c-a688-9c335b1e94db"
          x1="542.83"
          y1="594.95"
          x2="542.83"
          y2="489.85"
          gradientTransform="translate(-435.63 82.11) rotate(-26.56)"
          xlinkHref="#4dd7781c-4432-49c7-b285-1afbf72647b5"
        />
      </defs>
      <title>sync4</title>
      <g opacity="0.3">
        <path
          d="M794.2,124.14q2.64,1.43,5.25,2.91"
          transform="translate(-134.5 -85.81)"
          fill="none"
          stroke="#2196f3"
          strokeMiterlimit="10"
          strokeWidth="4"
        />
        <path
          d="M809.67,133C915.39,197.33,986,313.62,986,446.4a367.17,367.17,0,0,1-11.5,91.44"
          transform="translate(-134.5 -85.81)"
          fill="none"
          stroke="#2196f3"
          strokeMiterlimit="10"
          strokeWidth="4"
          strokeDasharray="11.85 11.85"
        />
        <path
          d="M973,543.57q-.79,2.89-1.63,5.77"
          transform="translate(-134.5 -85.81)"
          fill="none"
          stroke="#2196f3"
          strokeMiterlimit="10"
          strokeWidth="4"
        />
      </g>
      <path
        d="M253,446.4C253,287.12,354.61,151.57,496.54,101"
        transform="translate(-134.5 -85.81)"
        fill="none"
        stroke="#2196f3"
        strokeMiterlimit="10"
        strokeWidth="4"
        strokeDasharray="12"
        opacity="0.21"
      />
      <path
        d="M794.2,768.67A364.87,364.87,0,0,1,619.5,812.9c-119.53,0-225.7-57.22-292.6-145.77"
        transform="translate(-134.5 -85.81)"
        fill="none"
        stroke="#2196f3"
        strokeMiterlimit="10"
        strokeWidth="4"
        strokeDasharray="12"
        opacity="0.5"
      />
      <g opacity="0.21">
        <path
          d="M856.7,515.52q-.84,2.88-1.75,5.74"
          transform="translate(-134.5 -85.81)"
          fill="none"
          stroke="#2196f3"
          strokeMiterlimit="10"
          strokeWidth="4"
        />
        <path
          d="M851.06,532.56c-35,93.93-125.45,160.84-231.56,160.84-136.41,0-247-110.59-247-247s110.59-247,247-247A245.92,245.92,0,0,1,769.28,250"
          transform="translate(-134.5 -85.81)"
          fill="none"
          stroke="#2196f3"
          strokeMiterlimit="10"
          strokeWidth="4"
          strokeDasharray="11.95 11.95"
        />
        <path
          d="M774,253.66q2.34,1.88,4.63,3.81"
          transform="translate(-134.5 -85.81)"
          fill="none"
          stroke="#2196f3"
          strokeMiterlimit="10"
          strokeWidth="4"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M598.52,308q3-.44,6-.76"
          transform="translate(-134.5 -85.81)"
          fill="none"
          stroke="#2196f3"
          strokeMiterlimit="10"
          strokeWidth="4"
        />
        <path
          d="M616,306.45q1.75,0,3.5,0A140,140,0,0,1,741.76,514.66"
          transform="translate(-134.5 -85.81)"
          fill="none"
          stroke="#2196f3"
          strokeMiterlimit="10"
          strokeWidth="4"
          strokeDasharray="11.56 11.56"
        />
        <path
          d="M738.84,519.64q-1.57,2.56-3.25,5"
          transform="translate(-134.5 -85.81)"
          fill="none"
          stroke="#2196f3"
          strokeMiterlimit="10"
          strokeWidth="4"
        />
      </g>
      <path
        d="M965.33,630.8,864.55,577.59l52.57-86.09a3.12,3.12,0,0,1,4.12-1.13l84.14,44.43a3.12,3.12,0,0,1,1.39,4Z"
        transform="translate(-134.5 -85.81)"
        fill="url(#36a1da1a-b5ed-4992-81c7-0d29a6333ac7)"
      />
      <path
        d="M963.66,626.17l-94.34-49.81,49.12-80.44a3.12,3.12,0,0,1,4.12-1.13L1001,536.21a3.12,3.12,0,0,1,1.39,4Z"
        transform="translate(-134.5 -85.81)"
        fill="#f2f2f2"
      />
      <path
        d="M898.79,756.81,798,703.61l-41.44,92a3.12,3.12,0,0,0,1.39,4L842.11,844a3.12,3.12,0,0,0,4.12-1.13Z"
        transform="translate(-134.5 -85.81)"
        fill="url(#d287a4da-cdc2-42a1-98a9-ed232bbab7aa)"
      />
      <path
        d="M894,758l-94.34-49.81L761,794.15a3.12,3.12,0,0,0,1.39,4l78.44,41.41a3.12,3.12,0,0,0,4.12-1.13Z"
        transform="translate(-134.5 -85.81)"
        fill="#f2f2f2"
      />
      <rect
        x="801.59"
        y="586.87"
        width="161.24"
        height="161.24"
        rx="2.33"
        ry="2.33"
        transform="translate(-254.46 1050.18) rotate(-62.17)"
        fill="url(#044d3984-15d8-4f7b-a10a-036e6011c716)"
      />
      <rect
        x="804.01"
        y="589.29"
        width="156.39"
        height="156.39"
        rx="2.33"
        ry="2.33"
        transform="translate(279.21 -420.49) rotate(27.83)"
        fill="#f2f2f2"
      />
      <rect
        x="963.21"
        y="660.24"
        width="9.7"
        height="24.25"
        rx="1.5"
        ry="1.5"
        transform="translate(291.42 -460) rotate(27.83)"
        fill="#f2f2f2"
      />
      <rect
        x="811.29"
        y="596.56"
        width="141.84"
        height="141.84"
        rx="2.33"
        ry="2.33"
        transform="translate(279.21 -420.49) rotate(27.83)"
        fill={props.color}
      />
      <polygon
        points="790.82 581.84 784.64 567.99 742.07 586.83 733.98 568.97 719.63 575.34 731.53 601.11 731.53 601.11 734.38 607.7 790.82 581.84"
        fill="url(#4dd7781c-4432-49c7-b285-1afbf72647b5)"
      />
      <polygon
        points="790.15 581.23 784.46 568.84 741.67 588.51 733.29 570.29 720.9 575.98 732.14 600.38 732.14 600.38 735.06 606.55 790.15 581.23"
        fill="#fff"
      />
      <rect
        x="259.82"
        y="121.83"
        width="322.42"
        height="581.96"
        rx="12.25"
        ry="12.25"
        transform="translate(-262.15 111.06) rotate(-22.95)"
        fill="url(#f39856b3-7cd5-4a0c-afbe-fd75d54c2ee5)"
      />
      <rect
        x="264.17"
        y="129.7"
        width="313.7"
        height="566.24"
        rx="13.64"
        ry="13.64"
        transform="translate(-262.15 111.06) rotate(-22.95)"
        fill="#fff"
      />
      <path
        d="M388.72,134.45a25.43,25.43,0,0,1-14.65,29.69L274.3,206.4a25.43,25.43,0,0,1-31.52-10.14l-52.85,22.38a11.91,11.91,0,0,0-6.33,15.62L384.06,707.57a11.91,11.91,0,0,0,15.62,6.33L652.11,607a11.91,11.91,0,0,0,6.33-15.62L458,118.05a11.91,11.91,0,0,0-15.62-6.33Z"
        transform="translate(-134.5 -85.81)"
        fill={props.color}
      />
      <rect
        x="285.1"
        y="171.08"
        width="69.91"
        height="4.37"
        rx="2"
        ry="2"
        transform="translate(-176.73 52.72) rotate(-22.95)"
        fill="#dbdbdb"
      />
      <circle
        cx="365.74"
        cy="153.44"
        r="2.62"
        transform="translate(-165.38 68.97) rotate(-22.95)"
        fill="#dbdbdb"
      />
      <polygon
        points="329.26 266.13 302.62 260.73 285.83 342.46 251.34 335.69 245.73 363.26 295.72 372.7 295.72 372.7 308.29 375.39 329.26 266.13"
        fill="url(#6d589827-8376-478c-a688-9c335b1e94db)"
      />
      <polygon
        points="327.68 266.46 303.68 261.89 287.87 344.8 252.56 338.07 247.98 362.07 295.27 371.05 295.27 371.05 307.33 373.21 327.68 266.46"
        fill="#fff"
      />
      <circle cx="917" cy="24.6" r="14" fill="#80deea" opacity="0.3" />
      <circle cx="772.5" cy="343.19" r="14" fill="#80deea" opacity="0.3" />
      <circle cx="14" cy="81.6" r="14" fill="#80deea" opacity="0.5" />
      <circle cx="28" cy="38.6" r="14" fill="#80deea" opacity="0.3" />
      <g opacity="0.2">
        <path
          d="M292.3,213.4l99.78-42.25a25.43,25.43,0,0,0,14.65-29.69l51-21.61-.76-1.79a11.91,11.91,0,0,0-15.62-6.33l-53.65,22.72a25.43,25.43,0,0,1-14.65,29.69L273.3,206.4a25.08,25.08,0,0,1-8.41,1.94A25.42,25.42,0,0,0,292.3,213.4Z"
          transform="translate(-134.5 -85.81)"
          fill="#fff"
        />
        <path
          d="M201.61,241.25a11.91,11.91,0,0,1,6.33-15.62l45.55-19.29a25.32,25.32,0,0,1-11.71-10.09l-52.85,22.38a11.91,11.91,0,0,0-6.33,15.62L383.06,707.57a11.91,11.91,0,0,0,15.62,6.33l2.63-1.11Z"
          transform="translate(-134.5 -85.81)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SyncIllustration;
