import React from 'react';
import './DashboardPage.module.css';
import { Typography, withTheme, Theme, Button, Select } from '@material-ui/core';

import styles from './DashboardPage.module.css';
import TrackList from '../components/TrackList';
import { store } from '../../../redux/store';
import {
  spoitfyPlayRequest,
  spotifySwitchDeviceRequest,
  spotifyFetchDevicesRequest,
} from '../../../redux/actions/spotify.action';
import { connect } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import { Track, FullTrack } from '../../../models/Track';
import { Session } from '../../../models/Session';
import autobind from 'autobind-decorator';
import HavingFunIllustration from '../../illustrations/HavingFun';
import { currentSessionSelector } from '../../../redux/selectors/session/currentSession.selector';
import { isOwnerSelector } from '../../../redux/selectors/session/isOwner.selector';
import QueueIllustration from '../../illustrations/Queue';
import { localisation } from '../../../i18n/LanguageService';
import AlbumCoverIllustration from '../../illustrations/AlbumCover';
import ProgressBar from '../components/Player';
import { Device } from '../../../models/Device';
import { LoadingState } from '../../../models/LoadingState';
import { enqueueSnackbar } from '../../../redux/actions/notification.action';
import Chip from '../components/Chip';
import { DeviceSize } from '../../../redux/reducers/general.reducer';
import TrackDetailDialog from '../components/TrackDetailDialog';
import Player2 from '../components/Player2';

export interface DashboardPageProps {
  theme?: Theme;
  fetchDevicesLoadingState: LoadingState;
  initalized: boolean;
  isOwner?: boolean;
  currentSession?: Session;
  selectedDeviceId: string;
  devices: Device[];
  deviceSize: DeviceSize;
}

export interface DashboardPageState {
  now: Date;
  isTrackDetailDialogOpen: boolean;
  currentlyViewedTrack?: FullTrack;
}

const mapStateToProps = (state: AppState) => {
  return {
    initalized: state.general.initialized,

    fetchDevicesLoadingState: state.spotify.fetchDevicesLoadingState,

    sessions: state.session.sessionList,

    isOwner: isOwnerSelector(state),
    currentSession: currentSessionSelector(state),
    selectedDeviceId: state.spotify.selectedDeviceId,
    devices: state.spotify.devices || [],
    deviceSize: state.general.deviceSize,
  };
};

class DashboardPage extends React.Component<DashboardPageProps, DashboardPageState> {
  private interval: any;

  constructor(props: DashboardPageProps) {
    super(props);
    this.state = {
      now: new Date(),
      isTrackDetailDialogOpen: false,
      currentlyViewedTrack: undefined,
    };
  }

  @autobind
  componentDidMount() {
    store.dispatch(spotifyFetchDevicesRequest());
    this.interval = setInterval(() => {
      this.setState({ now: new Date() });
    }, 100);
  }

  @autobind
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  @autobind
  handleSelectedDeviceIdChange(evt: any) {
    store.dispatch(spotifySwitchDeviceRequest(evt.target!.value));
  }

  @autobind
  renderTrackList() {
    const session = this.props.currentSession;
    if (session && session.trackQueue) {
      const trackList = session.trackQueue.slice(1);
      return (
        <TrackList
          tracks={trackList}
          onClick={track =>
            this.setState({ isTrackDetailDialogOpen: true, currentlyViewedTrack: track })
          }
        />
      );
    }
  }

  @autobind
  renderTitleInformation() {
    const session = this.props.currentSession;

    if (session && session.trackQueue && session.trackQueue.length > 0) {
      let track: Track;
      track = session.trackQueue[0];
      return (
        <>
          <Typography variant="h6" color="inherit" noWrap={true} style={{ fontWeight: 'bold' }}>
            {track ? track.name : ''}
          </Typography>
          <Typography variant="subtitle1" color="inherit" noWrap={true}>
            {track ? track.artist : ''} - {track ? track.album : ''}
          </Typography>
        </>
      );
    }
  }

  @autobind
  renderTumbnail() {
    const session = this.props.currentSession;

    let track: Track | undefined = undefined;

    if (session && session.trackQueue && session.trackQueue.length > 0) {
      track = session.trackQueue[0];
    }

    if (track) {
      return (
        <img
          src={track.thumbnail}
          style={{ width: '100%', transform: 'rotate(-8deg)', padding: 8 }}
        />
      );
    } else {
      return (
        <img
          className={styles.thumbnail}
          src="https://recordsale.de/assets/record_placeholder-f3f829566497dc26b0abfae50ddeb5c7bc48fe1c58dc1c7fe62a26d64988b9c9.svg"
        />
      );
    }
  }

  @autobind
  onStart() {
    store.dispatch(spoitfyPlayRequest());
  }

  @autobind
  renderDefaultPage() {
    const background = (this.props.theme as any).dashboardBackground;
    const decoration = (this.props.theme as any).dashboardDecoration;
    const session = this.props.currentSession;
    let track;
    if (session && session.trackQueue && session.trackQueue.length > 0) {
      track = session.trackQueue[0];
    }

    return (
      <div className={styles.dashboard}>
        <div className={styles.top}>
          <div className={styles.topLeft}>
            <div>{this.renderTumbnail()}</div>
            <div style={{ marginTop: 32 }}>{this.renderTitleInformation()}</div>
            <Chip label={(track as any).addedBy}></Chip>
            <div className={styles.actions}>
              {this.props.isOwner && (
                <Select
                  native
                  style={{
                    width: 110,
                    color: this.props.theme!.palette.primary.main,
                    fontSize: 12,
                    fontWeight: 600,
                    marginTop: 16,
                  }}
                  autoWidth={false}
                  value={this.props.selectedDeviceId || undefined}
                  onChange={this.handleSelectedDeviceIdChange}
                  inputProps={{
                    name: 'device',
                    id: 'device-id',
                  }}
                >
                  {this.props.devices.map(device => {
                    return (
                      <option key={device.id} value={device.id}>
                        {device.name}
                      </option>
                    );
                  })}
                </Select>
              )}
            </div>
          </div>
          <div>
            <Player2></Player2>
          </div>
        </div>
        {this.renderTrackList()}
        <TrackDetailDialog
          open={this.state.isTrackDetailDialogOpen}
          track={this.state.currentlyViewedTrack}
          onClose={() => this.setState({ isTrackDetailDialogOpen: false })}
        />
      </div>
    );
  }

  @autobind
  renderPlaceholder() {
    if (this.props.isOwner) {
      return (
        <div className={styles.containerEmpty}>
          <HavingFunIllustration color={this.props.theme!.palette.primary.main} />
          <Typography color="primary" variant="h5" className={styles.importantText}>
            {localisation.strings.dashboardPlaceholderTitleOwner}
          </Typography>
          <Typography color="textSecondary" variant="subtitle1" className={styles.importantText}>
            {localisation.strings.dashboardPlaceholderMessageOwner}
          </Typography>

          <Button
            onClick={this.onStart}
            variant="contained"
            color="primary"
            className={styles.button}
            size="large"
          >
            Start
          </Button>
        </div>
      );
    }
    return (
      <div className={styles.containerEmpty}>
        <QueueIllustration color={this.props.theme!.palette.primary.main} />
        <div>
          <Typography color="primary" variant="h5" gutterBottom className={styles.importantText}>
            {localisation.strings.dashboardPlaceholderTitle}
          </Typography>
          <Typography color="textSecondary" variant="subtitle1" className={styles.importantText}>
            {localisation.strings.dashboardPlaceholderMessage}
          </Typography>
        </div>
      </div>
    );
  }

  render() {
    const session = this.props.currentSession;

    if (!session || !session.trackQueue || session.trackQueue.length <= 0) {
      return <div className={styles.dashboard}>{this.renderPlaceholder()}</div>;
    }
    return this.renderDefaultPage();
  }
}

export default withTheme(connect(mapStateToProps)(DashboardPage));
