import { createSelector } from 'reselect';
import { AppState } from '../../reducer';

const getCurrentSessionId = (state: AppState) => state.session.currentSession;
const getSessionMap = (state: AppState) => state.session.sessionMap;
const getUser = (state: AppState) => state.authentication.user;

export const isOwnerSelector = createSelector(
  [getCurrentSessionId, getSessionMap, getUser],
  (currentSessionId, sessionMap, user) => {
    if (sessionMap && currentSessionId && user && sessionMap[currentSessionId]) {
      return sessionMap[currentSessionId].owner === user.jukify_user_id;
    }
    return false;
  }
);
