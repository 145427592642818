import { action } from 'typesafe-actions';
import {
  APP_INITIALIZE_REQUEST,
  APP_INITIALIZE_SUCCESS,
  APP_THEME_CHANGE,
  APP_INITIALIZE_ERROR,
  WINDOW_RESIZED,
} from '../constants/general.constants';
import { HubError } from '../../models/HubError';
import { DeviceSize } from '../reducers/general.reducer';

export const initializeRequest = () => action(APP_INITIALIZE_REQUEST);
export const initializeSuccess = () => action(APP_INITIALIZE_SUCCESS);
export const initializeError = (err: HubError) => action(APP_INITIALIZE_ERROR, err);

export const changeTheme = (themeName: string) => action(APP_THEME_CHANGE, themeName);

export const windowResized = (width: number, height: number) =>
  action(WINDOW_RESIZED, { width, height });
