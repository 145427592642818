import * as actions from './../actions/session.action';
import { ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';

import { Session } from '../../models/Session';
import {
  SESSION_CHANGED,
  SESSION_CREATE_SUCCESS,
  SESSIONS_CHANGED,
  SESSION_LEAVE_SUCCESS,
  SESSION_SWITCH_SUCCESS,
  SESSION_LEAVE_REQUEST,
} from '../constants/session.constants';

export type SessionState = {
  sessionList: Session[];
  sessionMap: { [key: string]: Session };
  currentSession: string | null;
  sessionIdToLeave: string | null;
};

// Union of all authentication action types
export type SessionAction = ActionType<typeof actions>;

export const session = combineReducers<SessionState, SessionAction>({
  sessionList: (state = [], action) => {
    switch (action.type) {
      case SESSION_CHANGED: {
        const session = action.payload;

        if (state) {
          let index = -1;
          state.forEach((element, i) => {
            if (element.sessionId === session.sessionId) {
              index = i;
            }
          });
          if (index >= 0) {
            const array = [...state];
            array[index] = session;
            return array;
          }
          const array = [...state];
          array.push(session);
          return array;
        }

        return [action.payload];
      }
      case SESSION_CREATE_SUCCESS: {
        const session = action.payload;
        const array = [...state];
        array.push(session);
        return array;
      }
      case SESSIONS_CHANGED: {
        return action.payload;
      }

      case SESSION_LEAVE_SUCCESS: {
        const sessionId = action.payload;
        if (state) {
          let index = -1;
          state.forEach((element, i) => {
            if (element.sessionId === sessionId) {
              index = i;
            }
          });
          const array = [...state];
          array.splice(index, 1);

          return array;
        }
      }
      default:
        return state;
    }
  },
  currentSession: (state = null, action) => {
    switch (action.type) {
      case SESSION_SWITCH_SUCCESS: {
        return action.payload;
      }
      default:
        return state;
    }
  },
  sessionMap: (state = {}, action) => {
    switch (action.type) {
      case SESSION_CHANGED: {
        const session = action.payload;
        const newState = Object.assign({}, state);

        newState[session.sessionId] = session;

        return newState;
      }
      case SESSION_LEAVE_REQUEST: {
        const sessionId = action.payload;
        const newState = Object.assign({}, state);

        delete newState[sessionId];

        return newState;
      }
      case SESSIONS_CHANGED: {
        const newState = {};
        action.payload.forEach(session => {
          newState[session.sessionId] = session;
        });
        return newState;
      }
      case SESSION_CREATE_SUCCESS: {
        const session = action.payload;
        const newState = Object.assign({}, state);
        newState[session.sessionId] = session;
        return newState;
      }
      default: {
        return state;
      }
    }
  },
  sessionIdToLeave: (state = null, action) => {
    switch (action.type) {
      case SESSION_LEAVE_REQUEST: {
        const sessionId = action.payload;
        return sessionId;
      }
      case SESSION_LEAVE_SUCCESS: {
        const sessionId = action.payload;
        return sessionId;
      }
      default:
        return state;
    }
  },
});
