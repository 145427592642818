import React from 'react';
import styles from './AddAlbumDialog.module.css';
import { Dialog, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import autobind from 'autobind-decorator';
import { Track } from '../../../models/Track';
import { connect } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import { FullAlbum } from '../../../models/Album';
import { store } from '../../../redux/store';
import { fetchAlbumRequest } from '../../../redux/actions/album.action';
import SelectableTrackList from './SelectableTrackList';
import { Playlist, FullPlaylist } from '../../../models/Playlist';
import { fetchPlaylistRequest } from '../../../redux/actions/playlist.action';
import { localisation } from '../../../i18n/LanguageService';

export interface AddPlaylistDialogProps {
  open: boolean;
  playlistId?: string;
  onClose: (tracks?: Track[]) => void;
  playlists: { [playlistId: string]: FullPlaylist };
}

export interface AddPlaylistDialogState { 
  selectDeselectAllText: string;
}

const mapStateToProps = (state: AppState) => {
  return {
    playlists: state.playlist.playlistById,
  };
};

class AddPlaylistDialog extends React.Component<AddPlaylistDialogProps, AddPlaylistDialogState> {
  private selectedTracks: Track[] = [];
  private selectableTrackList: React.RefObject<SelectableTrackList> = React.createRef<SelectableTrackList>();

  constructor(props: AddPlaylistDialogProps) {
    super(props);

    this.state = {
      selectDeselectAllText: localisation.strings.addDialogDeselectAll
    };
  }

  static getDerivedStateFromProps(
    nextProps: AddPlaylistDialogProps,
    prevState: AddPlaylistDialogState
  ) {
    if (nextProps.playlistId && !nextProps.playlists[nextProps.playlistId]) {
      store.dispatch(fetchPlaylistRequest(nextProps.playlistId));
    }
    return prevState;
  }

  @autobind
  handleCloseConfirm() {
    if (this.selectedTracks.length > 0) this.props.onClose(this.selectedTracks);
    else this.props.onClose();
  }

  @autobind
  handleCloseCancel() {
    this.props.onClose();
  }

  @autobind
  handleSelectedTracksChange(trackIds: Track[]) {
    this.selectedTracks = trackIds

    if (this.selectedTracks.length > 0) {
      this.setState({ selectDeselectAllText: localisation.strings.addDialogDeselectAll });
    } else {
      this.setState({ selectDeselectAllText: localisation.strings.addDialogSelectAll })
    }
  }

  @autobind
  handleSelectDeselectAllClick() {
    if (this.selectedTracks.length > 0) {
      this.selectableTrackList.current!.deselectAll();
    } else {
      this.selectableTrackList.current!.selectAll();
    }
  }

  @autobind
  renderDialogContent() {
    if (this.props.playlistId && this.props.playlists[this.props.playlistId]) {
      const playlist = this.props.playlists[this.props.playlistId];
      return (
        <>
          <div className={styles.scrollContainer}>
            <div className={styles.thumbnailWrapper}>
              <img src={playlist.thumbnail} className={styles.thumbnail} />
            </div>
            <div style={{ padding: '8px' }}>
              <Typography
                color="primary"
                style={{ textAlign: 'center' }}
                variant="h6"
                noWrap={true}
              >
                {playlist.name}
              </Typography>
            </div>
            <SelectableTrackList
              tracks={playlist.tracks}
              onSelectedTracksChange={this.handleSelectedTracksChange}
              ref={this.selectableTrackList}
            />
          </div>
          <DialogActions className={styles.actions}>
            <Button 
              className={styles.selectDeselectButton}
              onClick={this.handleSelectDeselectAllClick}
              color="primary"
              >
              {this.state.selectDeselectAllText}
            </Button>
            <Button onClick={this.handleCloseCancel} color="primary">
              {localisation.strings.searchCancel}
            </Button>
            <Button variant="contained" onClick={this.handleCloseConfirm} color="primary">
              {localisation.strings.searchAddTracks}
            </Button>
          </DialogActions>
        </>
      );
    }
    return <></>;
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleCloseCancel}
        aria-labelledby="form-dialog-title"
      >
        {this.renderDialogContent()}
      </Dialog>
    );
  }
}

export default connect(mapStateToProps)(AddPlaylistDialog);
