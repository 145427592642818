import { Ii18nStrings, Ii18nErrors } from '../LanguageService';

export const stringsEn: Ii18nStrings = {
  buttonLogin: 'Sign in with Spotify',
  labelUsername: 'Username',
  labelPassword: 'Password',
  menuPlaylist: 'Playlist',
  menuSearch: 'Search',
  menuManagement: 'Management',
  menuSettings: 'Settings',
  lobbyEmptyTitle: 'No Sessions found!',
  lobbyEmptyMessage: 'You have not joined a session, yet...',
  lobbySessionKey: 'Session key',
  lobbyTitle: 'Create or join a session',
  lobbyMessage: 'Join the session of your friends or create a new one!',
  lobbyJoinSessionButton: 'Join',
  lobbyCreateSessionButton: 'Create Session',
  lobbyScanQRButton: 'Scan QR Code',
  lobbyChooseSession: 'Choose your session',
  lobbySwitchOrCreateSession: 'Switch to one of your sessions or create a new one!',
  dashboardImportPlaylists: 'Import Playlists',
  importTracksAccept: 'OK',
  importTracksDecline: 'Cancel',
  importTracksTitle: 'Import Playlists',
  sessionManagePlayRate: 'Play rate',
  newSessionDialogTitle: 'Create a new session',
  newSessionDialogMessage:
    'Create a new session to start playing music! Please give your session a cool name:',
  newSessionDialogConfirm: 'OK',
  newSessionDialogCancel: 'Cancel',
  leaveSessionDialogTitle: 'Leave Session?',
  leaveSessionDialogMessage: 'Are you sure you want to leave the session?',
  leaveSessionDialogConfirm: 'Leave',
  leaveSessionDialogCancel: 'Stay',
  bottomNavHome: 'Home',
  bottomNavUserPlaylist: 'Playlist',
  bottomNavDistribution: 'Distribution',
  bottomNavSessionSettings: 'Configuration',
  appDrawerHome: 'Home',
  appDrawerSessions: 'Sessions',
  appDrawerSettings: 'Settings',
  appDrawerLogout: 'Logout',
  appBarTitleHome: 'Home',
  appBarTitleLobby: 'Sessions',
  appBarTitleUserPlaylist: 'My Playlist',
  appBarTitleUserDistribution: 'Distribution',
  appBarTitleUserSettings: 'Settings',
  appBarTitleSessionSettings: 'Session Settings',
  searchResultTracks: 'Tracks',
  searchResultAlbums: 'Albums',
  searchResultPlaylists: 'Playlists',
  searchResultMyPlaylists: 'My Playlists',
  searchInstructionsTitle: 'Add Tracks',
  searchInstructionsMessage: 'Search for tracks and add them to your playlist',
  searchNotFoundTitle: 'Not found',
  searchNotFoundMessage: 'Your search did not return any results',
  search: 'Search...',
  sessionListNotStarted: 'Not started',
  darkTheme: 'Dark',
  defaultTheme: 'Light',
  searchAddTracks: 'Add Tracks',
  searchCancel: 'Cancel',
  appLoadingMessage: 'loading app...',
  userPlaylistLoadingMessage: 'fetching playlist...',
  playlistEmptyTitle: 'Empty Playlist',
  playlistEmptyMessage: 'Add tracks to your playlist to contribute to the session!',
  playlistAddTracks: 'Add tracks',
  playlistBlocked: 'blocked',
  playlistPlayedRecently: 'recently played',
  dashboardPlaceholderTitleOwner: 'Start the party',
  dashboardPlaceholderMessageOwner:
    'Generate a new playlist to start the party! Ensure that there are tracks in your playlist.',
  dashboardPlaceholderTitle: 'The Party fängt is about to start...',
  dashboardPlaceholderMessage:
    'Wait for the host to start the party! In the meantime, go ahead and add some tracks to your playlist.',
  sessionSettingsExcludeGenrePlaceholder: 'Exclude genres',
  sessionSettingsExcludeGenreTitle: 'Exclude genres',
  sessionSettingsExcludeGenreMessage:
    'Exclude genres you dont want to hear at your party! Songs that are queued already are not removed.',
  sessionSettingsJukeboxModeTitle: 'Jukebox Mode (coming soon!)',
  sessionSettingsJukeboxModeMessage: 'Change the way the jukebox is selecting the next tracks.',
  playlistTrackMoveTop: 'Move to top',
  playlistTrackMoveBottom: 'Move to bottom',
  playlistTrackMoveUp: 'Move up',
  playlistTrackMoveDown: 'Move down',
  playlistTrackRemove: 'Remove',
  speedDialActionAdd: 'Add',
  speedDialActionShuffle: 'Shuffle',
  speedDialActionEmptyPlaylist: 'Empty playlist',
  userPlaylistShuffleDialogTitle: 'Shuffle playlist?',
  userPlaylistShuffleDialogMessage: 'Do you really want to shuffle your playlist?',
  userPlaylistShuffleDialogConfirm: 'Shuffle',
  userPlaylistShuffleDialogCancel: 'Cancel',
  userPlaylistEmptyPlaylistDialogTitle: 'Empty playlist?',
  userPlaylistEmptyPlaylistDialogMessage: 'Do you really want to empty your playlist?',
  userPlaylistEmptyPlaylistDialogConfirm: 'Empty',
  userPlaylistEmptyPlaylistDialogCancel: 'Cancel',
  addDialogDeselectAll: 'Deselect all',
  addDialogSelectAll: 'Select all'
};

export const errorsEn: Ii18nErrors = {
  session: {
    create: 'Could not create session',
    join: 'Could not join session',
    leave: 'Could not leave session',
  },
  spotify: {
    pause: {
      default: 'Could not play session',
    },
    play: {
      deviceNotFound: 'Unable to play: Device not found',
      notEnoughSongs: 'Not enough songs, add some more songs!',
      default: 'Could not play session',
    },
  },
  search: {
    fetch: 'Could not execute the query',
  },
};
