import React, { Component } from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import ThemedContent from './ThemedContent';
import * as Sentry from '@sentry/browser';

export class App extends Component {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    return (
      <Provider store={store}>
        <ThemedContent />
      </Provider>
    );
  }
}

export default App;
