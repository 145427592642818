import { ActionType } from 'typesafe-actions';
import * as actions from './../actions/signalR.action';
import { combineReducers } from 'redux';
import {} from '../constants/spotify.constants';
import {
  SIGNALR_CONNECTION_ESTABLISHED,
  SIGNALR_CONNECTION_LOST,
  SIGNALR_CONNECTION_RECONNECTING,
  SIGNALR_CONNECTING_ERROR,
} from '../constants/signalR.constants';

export enum ConnectionState {
  DISCONNECTED,
  CONNECTED,
  RECONNECTING,
}

export interface SignalRState {
  connectionState: ConnectionState;
  retryCount: number;
}

export type SignalRAction = ActionType<typeof actions>;

export const signalR = combineReducers<SignalRState, SignalRAction>({
  connectionState: (state = ConnectionState.DISCONNECTED, action) => {
    switch (action.type) {
      case SIGNALR_CONNECTION_ESTABLISHED: {
        return ConnectionState.CONNECTED;
      }
      case SIGNALR_CONNECTION_LOST: {
        return ConnectionState.DISCONNECTED;
      }
      case SIGNALR_CONNECTING_ERROR: {
        return ConnectionState.DISCONNECTED;
      }
      case SIGNALR_CONNECTION_RECONNECTING: {
        return ConnectionState.RECONNECTING;
      }
      default: {
        return state;
      }
    }
  },
  retryCount: (state = 0, action) => {
    switch (action.type) {
      case SIGNALR_CONNECTION_ESTABLISHED: {
        return 0;
      }
      case SIGNALR_CONNECTION_LOST: {
        return 0;
      }
      case SIGNALR_CONNECTION_RECONNECTING: {
        return state + 1;
      }
      default: {
        return state;
      }
    }
  },
});
