import React from 'react';
import styles from './DistributionPage.module.css';
import DistributionListItem from '../components/DistibutionListItem';
import { connect } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import { SessionUser } from '../../../models/SessionUser';
import { RouteComponentProps } from 'react-router';

import autobind from 'autobind-decorator';
import { store } from '../../../redux/store';
import {
  sessionUserUpdatePercentageSuccess,
  sessionUserUpdatePercentageRequest,
} from '../../../redux/actions/sessionUsers.action';
import PieChart from 'react-minimal-pie-chart';
import { withTheme, Theme } from '@material-ui/core';
import * as hexToHsl from 'hex-to-hsl';
import * as rgbaConvert from 'rgba-convert';
import { Session } from '../../../models/Session';
import { User } from '../../../models/User';
import { currentSessionSelector } from '../../../redux/selectors/session/currentSession.selector';
import { isOwnerSelector } from '../../../redux/selectors/session/isOwner.selector';

export interface DistributionPageProps {
  sessionUsers: { [key: string]: SessionUser[] };
  isOwner?: boolean;
  appInitialized: boolean;
  theme?: Theme;
  currentSession?: Session;
}

export interface DistributionPageState {
  sessionUsers: { [key: string]: SessionUser[] };
}

const mapStateToProps = (state: AppState) => {
  return {
    sessionUsers: state.sessionUser.sessionUsers || undefined,
    appInitialized: state.general.initialized,
    isOwner: isOwnerSelector(state),
    currentSession: currentSessionSelector(state),
  };
};

class DistributionPage extends React.Component<DistributionPageProps, DistributionPageState> {
  constructor(props: DistributionPageProps) {
    super(props);
    this.state = { sessionUsers: this.props.sessionUsers };
  }

  static getDerivedStateFromProps(
    nextProps: DistributionPageProps,
    prevState: DistributionPageState
  ) {
    return Object.assign(prevState, { sessionUsers: nextProps.sessionUsers });
  }

  @autobind
  onPercentageChange(percentage: number, userId: string, sessionId: string) {
    const { sessionUsers } = Object.assign({}, this.state);

    const users = sessionUsers[sessionId].filter(user => user.userId === userId);
    if (users.length > 0) {
      const user = users[0];

      const sessionUsersNotToChange = sessionUsers[sessionId].filter(
        user => user.userId !== userId
      );

      let difference = percentage - user.percentage;

      if (difference > 0) {
        // New percentage is larger -> Percentage needs to be taken from others.
        var percentagesSum = this.sumPercentages(sessionUsersNotToChange);
        let alreadySubtracted = 0;

        sessionUsersNotToChange.forEach(sessionUser => {
          if (sessionUser.userId === user.userId) return;
          let partToBeSubtracted = difference * (sessionUser.percentage / percentagesSum);
          alreadySubtracted += partToBeSubtracted;
          sessionUser.percentage -= partToBeSubtracted;
        });

        sessionUsersNotToChange[sessionUsersNotToChange.length - 1].percentage -=
          difference - alreadySubtracted;
      } else if (difference < 0) {
        difference = Math.abs(difference);
        // New percentage is smaller -> Remaining percentage can be given to others.
        var partToBeAdded = difference / sessionUsersNotToChange.length;
        let alreadAdded = 0;

        sessionUsers[sessionId].forEach(sessionUser => {
          if (sessionUser.userId === user.userId) return;
          alreadAdded += partToBeAdded;
          sessionUser.percentage += partToBeAdded;
        });

        sessionUsersNotToChange[sessionUsersNotToChange.length - 1].percentage +=
          difference - alreadAdded;
      }

      user.percentage = percentage;
      this.setState({ sessionUsers });
    }
  }

  @autobind
  sumPercentages(sessionUsers: SessionUser[]) {
    let sum = 0;
    sessionUsers.forEach(e => {
      sum += e.percentage;
    });
    return sum;
  }

  @autobind
  onDragEnd(percentage: number, userId: string, sessionId: string) {
    store.dispatch(sessionUserUpdatePercentageRequest({ percentage, userId, sessionId }));
  }

  @autobind
  calcChartData() {
    const data: any[] = [];
    if (this.props.currentSession) {
      const sessionId = this.props.currentSession.sessionId;
      const numOfSessionUsers = this.state.sessionUsers[sessionId]
        ? this.state.sessionUsers[sessionId].length
        : 0;

      if (numOfSessionUsers <= 0) {
        return [];
      }

      const rgba = rgbaConvert.obj(this.props.theme!.palette.primary.main);
      const rgb = rgbaConvert.obj(`rgb(${rgba.r}, ${rgba.g}, ${rgba.b})`);

      const color: number[] = hexToHsl(rgbaConvert.hex(rgb));

      const colorStep = color[2] / numOfSessionUsers;

      this.state.sessionUsers[sessionId].forEach((e, i) => {
        data.push({
          value: e.percentage * 100,
          color: `hsl(${color[0]},${color[1] + i * colorStep}%,${color[2] + i * colorStep}%)`,
        });
      });
    }
    return data;
  }

  @autobind
  renderList() {
    if (this.props.currentSession) {
      const sessionId = this.props.currentSession!.sessionId;
      const numOfSessionUsers = this.state.sessionUsers[sessionId]
        ? this.state.sessionUsers[sessionId].length
        : 0;
      if (sessionId && this.props.appInitialized && this.props.sessionUsers[sessionId]) {
        return (
          <div className={styles.list}>
            {this.state.sessionUsers[sessionId].map(sessionUser => (
              <DistributionListItem
                key={sessionUser.userId}
                image={sessionUser.userImageUrl}
                displayName={sessionUser.displayName}
                percentage={sessionUser.percentage}
                sliderDisabled={numOfSessionUsers <= 1 || !this.props.isOwner}
                onPercentageChange={percentage =>
                  this.onPercentageChange(percentage, sessionUser.userId, sessionId)
                }
                onDragEnd={() =>
                  this.onDragEnd(sessionUser.percentage, sessionUser.userId, sessionId)
                }
              />
            ))}
          </div>
        );
      }
    }
  }

  render() {
    return (
      <div className={styles.distribution}>
        <div className={styles.chart}>
          <PieChart lineWidth={25} data={this.calcChartData()} />
        </div>
        {this.renderList()}
      </div>
    );
  }
}

export default withTheme(connect(mapStateToProps)(DistributionPage));
