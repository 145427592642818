import { singalRService } from '../../services/singalR.service';
import { put } from 'redux-saga/effects';
import {
  sessionUserUpdatePercentageSuccess,
  sessionUserUpdatePercentageError,
} from '../../actions/sessionUsers.action';
import { HubError } from '../../../models/HubError';

export function* sendUpdatePercentageRequestToServer(
  sessionId: string,
  userId: string,
  percentage: number
) {
  try {
    yield singalRService.invoke('SetDistribution', sessionId, userId, percentage);
    yield put(sessionUserUpdatePercentageSuccess());
  } catch (err) {
    yield put(sessionUserUpdatePercentageError(new HubError(err)));
  }
}
