import { ActionType } from 'typesafe-actions';
import * as actions from './../actions/sessionUsers.action';
import { combineReducers } from 'redux';
import { SessionUser } from '../../models/SessionUser';
import {
  SESSIONUSERS_CHANGED,
  SESSIONUSER_UPDATE_PERCENTAGE_SUCCESS,
} from '../constants/sessionUser.constants';

export interface SessionUserState {
  sessionUsers: { [sessionId: string]: SessionUser[] };
}

export type SessionUserAction = ActionType<typeof actions>;

export const sessionUser = combineReducers<SessionUserState, SessionUserAction>({
  sessionUsers: (state = {}, action) => {
    switch (action.type) {
      case SESSIONUSERS_CHANGED: {
        const map = {};
        action.payload.forEach(sUser => {
          map[sUser.sessionId] = sUser.sessionUsers;
        });
        return map;
      }

      default:
        return state;
    }
  },
});
