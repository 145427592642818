import { all, fork, take, put } from 'redux-saga/effects';

import {
  SIGNALR_CONNECTING_ERROR,
  SIGNALR_CONNECTION_RECONNECTING_SUCCESS,
  SIGNALR_CONNECTION_LOST,
  SIGNALR_CONNECTION_RECONNECTING,
} from '../../constants/signalR.constants';
import { enqueueSnackbar } from '../../actions/notification.action';
import { signalRReconnecting } from '../../actions/signalR.action';
import { reconnect } from './signalR.worker';
import { isOwnerSelector } from '../../selectors/session/isOwner.selector';
import { initializeRequest } from '../../actions/general.action';

function* watchSignalRConnectionError() {
  const action = yield take(SIGNALR_CONNECTING_ERROR);
  const err: Error = action.payload;

  console.log(err);

  yield put(signalRReconnecting());

  yield fork(watchSignalRConnectionError);
}

function* watchSignalRReconnectingSuccess() {
  const action = yield take(SIGNALR_CONNECTION_RECONNECTING_SUCCESS);

  yield put(initializeRequest());

  yield put(
    enqueueSnackbar({
      message: 'Reconnected',
      options: {
        variant: 'success',
      },
    })
  );

  yield fork(watchSignalRReconnectingSuccess);
}

function* watchSignalRConnectionLost() {
  yield take(SIGNALR_CONNECTION_LOST);

  yield put(signalRReconnecting());

  yield put(
    enqueueSnackbar({
      message: 'Connection lost. Reconnecting...',
      options: {
        variant: 'warning',
      },
      preventDuplicate: true,
    })
  );

  yield fork(watchSignalRConnectionLost);
}

function* watchSignalRReconnect() {
  yield take(SIGNALR_CONNECTION_RECONNECTING);
  yield reconnect();

  yield fork(watchSignalRReconnect);
}

export default function* signalRSaga() {
  yield all([
    fork(watchSignalRReconnectingSuccess),
    fork(watchSignalRConnectionError),
    fork(watchSignalRConnectionLost),
    fork(watchSignalRReconnect),
  ]);
}
