import React from 'react';
import { Avatar, ListItemText, withTheme, Theme } from '@material-ui/core';
import autobind from 'autobind-decorator';
import styles from './SwipeableListItem.module.css';
import Delete from '@material-ui/icons/Delete';
import { isCordova } from '../../../../../utils/isCordova';

export interface SwipeableListItemProps {
  onClick?(): void;
  onSwipe?(): void;
  onContextMenu?(evt: React.MouseEvent<HTMLDivElement>): void;
  theme?: Theme;
  style?: any;
}

export interface SwipeableListItemState {}

class SwipeableListItem extends React.Component<SwipeableListItemProps, SwipeableListItemState> {
  private listElement: HTMLDivElement | null;
  private wrapper: HTMLDivElement | null;
  private background: HTMLDivElement | null;

  public dragStartX: number = 0;

  public left: number = 0;
  public dragged = false;

  public startTime;
  public fpsInterval = 1000 / 60;

  constructor(props: SwipeableListItemProps) {
    super(props);

    this.listElement = null;
    this.wrapper = null;
    this.background = null;

    if (isCordova()) {
      this.fpsInterval = 1000 / 30;
    }
  }

  componentDidMount() {
    addEventListener('mouseup', this.dragEndMouse);
    addEventListener('touchend', this.dragEndTouch);
  }

  @autobind
  onDragStartMouse(evt) {
    this.onDragStart(evt.clientX);
    window.addEventListener('mousemove', this.onMouseMove);
  }

  @autobind
  onDragStartTouch(evt) {
    const touch = evt.targetTouches[0];
    this.onDragStart(touch.clientX);
    window.addEventListener('touchmove', this.onTouchMove);
  }

  @autobind
  onDragStart(clientX) {
    this.dragged = true;
    this.dragStartX = clientX;
    if (this.listElement) {
      this.listElement.className = styles.listitem;
    }
    this.startTime = Date.now();
    requestAnimationFrame(this.updatePosition);
  }

  @autobind
  dragEndMouse(evt: MouseEvent) {
    window.removeEventListener('mousemove', this.onMouseMove);
    this.onDragEnd();
  }

  @autobind
  onDragEnd() {
    if (this.dragged && this.listElement) {
      this.dragged = false;

      const threshold = 0.3;
      this.listElement.className = styles.bouncingListItem;
      if (this.left < this.listElement.offsetWidth * threshold * -1) {
        this.left = -this.listElement.offsetWidth * 2;
        if (this.wrapper) {
          this.wrapper.style.maxHeight = '0';
        }
        setTimeout(() => this.onSwiped(), 500);
      } else {
        this.left = 0;
      }

      this.listElement.style.transform = `translateX(${this.left}px)`;
    }
  }

  @autobind
  dragEndTouch(evt: TouchEvent) {
    window.removeEventListener('touchmove', this.onTouchMove);
    this.onDragEnd();
  }

  @autobind
  onMouseMove(evt: MouseEvent) {
    const left = evt.clientX - this.dragStartX;
    if (left < 0) {
      this.left = left;
    }
  }

  @autobind
  onTouchMove(evt: TouchEvent) {
    const touch = evt.targetTouches[0];
    const left = touch.clientX - this.dragStartX;
    if (left < 0) {
      this.left = left;
    }
  }

  @autobind
  updatePosition() {
    if (this.dragged) requestAnimationFrame(this.updatePosition);
    const now = Date.now();
    const elapsed = now - this.startTime;
    if (this.dragged && elapsed > this.fpsInterval && this.listElement) {
      this.listElement!.style.transform = `translateX(${this.left}px)`;
      const absLeft = Math.abs(this.left);
      if (absLeft < 100) {
        this.background!.style.opacity = (absLeft / 100).toString();
      } else {
        this.background!.style.opacity = '1';
      }

      this.startTime = Date.now();
    }
  }
  @autobind
  onClicked() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  @autobind
  onSwiped() {
    if (this.props.onSwipe) {
      this.props.onSwipe();
    }
  }

  @autobind
  onContextMenu(evt: React.MouseEvent<HTMLDivElement>) {
    evt.persist();
    evt.preventDefault();
    if (this.props.onContextMenu) {
      this.props.onContextMenu(evt);
    }
  }

  render() {
    return (
      <div style={this.props.style as any}>
        <div
          className={styles.wrapper}
          ref={div => (this.wrapper = div)}
          onContextMenu={this.onContextMenu}
        >
          <div
            ref={div => (this.background = div)}
            className={styles.background}
            style={{
              backgroundColor: this.props.theme!.palette.primary.main,
            }}
          >
            <Delete />
          </div>
          <div
            onClick={this.onClicked}
            ref={div => (this.listElement = div)}
            onMouseDown={this.onDragStartMouse}
            onTouchStart={this.onDragStartTouch}
            className={styles.listitem}
            style={{
              backgroundColor: this.props.theme!.palette.background.default,
            }}
          >
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default withTheme(SwipeableListItem);
