import { fork, all, put, take } from 'redux-saga/effects';

import {
  sendPlayToServer,
  sendPauseToServer,
  fetchDeviceIdsFromServer,
  handlePlayError,
} from './spotify.woker';
import { store } from '../../store';
import {
  spoitfyPauseRequest as pauseAction,
  spoitfyPlayRequest as playAction,
  spotifyPauseSuccess,
  spoitfyPauseRequest,
  spotifySwitchDeviceSuccess,
  spotifyFetchDevicesRequest,
  spotifySwitchDeviceRequest,
} from '../../actions/spotify.action';
import { switchSessionSuccess } from '../../actions/session.action';
import { currentSessionSelector } from '../../selectors/session/currentSession.selector';
import { isOwnerSelector } from '../../selectors/session/isOwner.selector';
import { APP_INITIALIZE_SUCCESS } from '../../constants/general.constants';
import {
  SPOTIFY_PLAY_REQUEST,
  SPOTIFY_PAUSE_REQUEST,
  SPOTIFY_TOGGLE_PAUSED_REQUEST,
  SPOTIFY_FETCH_DEVICES_REQUEST,
  SPOTIFY_FETCH_DEVICES_SUCCESS,
  SPOTIFY_SWITCH_DEVICE_REQUEST,
  SPOTIFY_PLAY_ERROR,
  SPOTIFY_PAUSE_ERROR,
  SPOTIFY_DEVICES_UPDATE_ASYNC,
} from '../../constants/spotify.constants';
import { SESSION_SWITCH_REQUEST } from '../../constants/session.constants';
import { SIGNOUT } from '../../constants/authentication.constants';
import { enqueueSnackbar } from '../../actions/notification.action';
import { localisation } from '../../../i18n/LanguageService';
import { HubError } from '../../../models/HubError';

function* watchAppInitialized() {
  yield take(APP_INITIALIZE_SUCCESS);

  yield put(spotifyFetchDevicesRequest());

  yield fork(watchAppInitialized);
}

function* watchPauseRequest() {
  yield take(SPOTIFY_PAUSE_REQUEST);
  const state = store.getState();
  const currentSession = currentSessionSelector(state);
  if (currentSession) {
    yield sendPauseToServer(currentSession.sessionId);
  }

  yield fork(watchPauseRequest);
}

function* watchPlayRequest() {
  yield take(SPOTIFY_PLAY_REQUEST);
  const state = store.getState();
  const { spotify } = state;
  const currentSession = currentSessionSelector(state);
  const deviceId = spotify.selectedDeviceId;
  if (currentSession) {
    yield sendPlayToServer(currentSession.sessionId, deviceId || '');
  }

  yield fork(watchPlayRequest);
}

function* watchToggleRequest() {
  yield take(SPOTIFY_TOGGLE_PAUSED_REQUEST);
  const state = store.getState();
  const currentSession = currentSessionSelector(state);
  if (currentSession && currentSession.paused) {
    yield put(playAction());
  } else {
    yield put(pauseAction());
  }

  yield fork(watchToggleRequest);
}

function* watchGetDevicesRequest() {
  yield take(SPOTIFY_FETCH_DEVICES_REQUEST);
  const state = store.getState();
  const currentSession = currentSessionSelector(state);
  if (currentSession) {
    yield fetchDeviceIdsFromServer(currentSession.sessionId);
  }

  yield fork(watchGetDevicesRequest);
}

function* watchUpdateDevices() {
  yield take(SPOTIFY_FETCH_DEVICES_SUCCESS);
  const { spotify } = store.getState();
  if (!spotify.selectedDeviceId && spotify.devices.length > 0) {
    const activeDevices = spotify.devices.filter(d => d.isActive);
    if (activeDevices.length > 0) {
      yield put(spotifySwitchDeviceRequest(activeDevices[0].id));
    }
  } else if (!spotify.selectedDeviceId && spotify.devices.length > 0) {
    yield put(spotifySwitchDeviceRequest(spotify.devices[0].id));
  }

  yield fork(watchUpdateDevices);
}

function* watchdevicesUpdatedAsync() {
  const action = yield take(SPOTIFY_DEVICES_UPDATE_ASYNC);
  const devices = action.payload;
  if (devices.length > 0) {
    const activeDevices = devices.filter(d => d.isActive);
    if (activeDevices.length > 0) {
      yield put(spotifySwitchDeviceRequest(activeDevices[0].id));
    }
  } else if (devices.length > 0) {
    yield put(spotifySwitchDeviceRequest(devices[0].id));
  }

  yield fork(watchdevicesUpdatedAsync);
}

function* watchSwitchDevicesRequest() {
  const action = yield take(SPOTIFY_SWITCH_DEVICE_REQUEST);
  const state = store.getState();
  const currentSession = currentSessionSelector(state);
  if (currentSession && !currentSession.paused) {
    // TODO send updated device id to sercer
  }

  yield put(spotifySwitchDeviceSuccess(action.payload));

  yield fork(watchSwitchDevicesRequest);
}

function* watchSignout() {
  const action = yield take(SIGNOUT);
  yield spoitfyPauseRequest();
  yield fork(watchSignout);
}

function* watchSwitchSessionRequest() {
  const action = yield take(SESSION_SWITCH_REQUEST);
  const currentSession = currentSessionSelector(store.getState());
  const isOwner = isOwnerSelector(store.getState());

  // Issue 118: Unwanted pause with duplicate login, via two devices for example.
  // Probably, the pausing is not needed really. It only makes the old session going on
  // until the new session is started.
  // if (isOwner) {
  //   if (currentSession && !currentSession.paused) {
  //     yield fork(sendPauseToServer, currentSession.sessionId);
  //   }
  // }

  yield put(switchSessionSuccess(action.payload));

  yield fork(watchSwitchSessionRequest);
}

function* watchPlayError() {
  const action = yield take(SPOTIFY_PLAY_ERROR);

  yield handlePlayError(action.payload);

  yield fork(watchPlayError);
}

function* watchPauseError() {
  const action = yield take(SPOTIFY_PAUSE_ERROR);

  yield put(
    enqueueSnackbar({
      message: localisation.errors.spotify.pause,
      options: {
        variant: 'error',
      },
      preventDuplicate: true,
    })
  );

  yield fork(watchPauseError);
}

export default function* spotifySaga() {
  yield all([
    fork(watchAppInitialized),
    fork(watchPauseRequest),
    fork(watchPlayRequest),
    fork(watchToggleRequest),
    fork(watchSignout),
    fork(watchSwitchSessionRequest),
    fork(watchGetDevicesRequest),
    fork(watchSwitchDevicesRequest),
    fork(watchUpdateDevices),
    fork(watchPlayError),
    fork(watchPauseError),
    fork(watchdevicesUpdatedAsync),
  ]);
}
